import React, { useRef, useState } from 'react'
import { Form, Image, Input, Select, Skeleton, Switch, Table, Tooltip } from 'antd';
import * as AntIcons from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Refresh from '../../App_Details_theme/Common_Components/Refresh';
import '../categories.css';
import Submitbutton from '../../App_Details_theme/Common_Components/Submitbutton';
import { categoryCreate, categoryDelete, categoryGetAll, categoryStatus, categoryUpdate } from '../../Redux/Action/categoryGetAll';
import { useEffect } from 'react';
import { CATEGORY, CATEGORYSTATUS, DELETECATEGORY, POSTCATEGORY, UPDATECATEGORY } from '../../Redux/ActionTypes/actionTypes';
import ActionMenu from '../../App_Details_theme/Common_Components/ActionMenu';
import moment from 'moment';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent';
import Cate from '../Cate';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const Category = () => {

    const formRef = useRef();
    const [form] = Form.useForm()
    let dispatch = useDispatch()
    const fileInputRef = useRef();

    const [defultValue, setDefultValue] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([])
    const [totalItems, setTotalItems] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [uploadFiles, setUploadFiles] = useState([])
    const [image, setImage] = useState(null)
    const [searchDataAll, setSearchDataAll] = useState('');
    const [selectedStatusData, setSelectedStatusData] = useState('0');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    let category = useSelector((state) => state.categoryReducer)
    const handleRefreshClick = () => {
        setIsFetching(true)
        setLoading(true)
        CategoryGetAll()
    }
    const CategoryGetAll = () => {
        let payload = {
            page: currentPage,
            limit: pageSize,
            search: searchDataAll,
            status: selectedStatusData
        }
        dispatch(categoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        CategoryGetAll()
    }, [currentPage, pageSize, selectedStatusData, searchDataAll])

    useEffect(() => {
        if (category.type === CATEGORY) {
            if (category.data.success && category.data.data?.length !== 0) {
                setTotalItems(category.data.data.totalItems)
                setCategoryData(category.data.data.items)
            }
            else {
                setCategoryData([])
            }
        }
        if (category.type === POSTCATEGORY) {
            if (category.data.success) {
                CategoryGetAll()
                form.resetFields()
                setImage("")
                setImageUrl("")
            }
        }
        if (category.type === UPDATECATEGORY) {
            if (category.data.success) {
                CategoryGetAll()
                setImage("")
                setImageUrl("")
                form.resetFields()
                setDefultValue(false)
            }
        }
        if (category.type === DELETECATEGORY) {
            if (category.data.success) {
                CategoryGetAll()
                form.resetFields()
                setImage("")
                setImageUrl("")
                setDefultValue(false)
            }
        }
        if (category.type === CATEGORYSTATUS) {
            if (category.data.success) {
                CategoryGetAll()
                form.resetFields()
            }
        }
    }, [category])

    const columns = [
        {
            title: (<span>Name</span>),
            dataIndex: 'name',
            width: "150px",
            key: 'name',
            render: (name) => (
                <span className='text-capitalize'>
                    {name || '-'}
                </span>
            ),
        },
        {
            title: (<span>Image</span>),
            dataIndex: 'image',
            width: "100px",
            key: 'image',
            render: (image) => (
                <span>
                    <Image width={35} height={35} src={`${REACT_APP_SOCKET}/uploads/category/${image}`} className='image-pdf-logo' />
                </span>
            ),
        },
        {
            title: (<span>Description</span>),
            dataIndex: 'description',
            width: "100px",
            key: 'description',
            render: (description) => (
                <div className='description-wrap text-capitalize'>
                    {description || '-'}
                </div>
            ),
        },

        {
            title: (<span>Status</span>),
            width: "150px",
            dataIndex: 'status',
            render: (status, record) => {
                return (
                    <Switch
                        size='small'
                        checked={status === 0 ? true : false}
                        onChange={(checked) => handleToggleChange(record._id, checked ? 1 : 0)}
                    />
                )

            },
        },
        {
            title: (<span>Updated Date</span>),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '350px',
            render: (text, record) => {
                const formattedDate = moment(record.updatedAt).format('DD MMM YYYY h:mm:ss A');
                const formattedDate2 = moment(record.registerdate).format('DD MMM YYYY h:mm:ss A');
                return <div className='d-flex justify-content-between align-items-center flex-wrap'><span>
                    <Tooltip title={
                        <>
                            <span><AntIcons.ClockCircleOutlined /> {formattedDate2}</span>
                        </>}>
                        {formattedDate}
                    </Tooltip>
                </span >
                    <ActionMenu data={record}
                        handleUpdate={handleUpdate} confirmDelete={handleDelete}
                    />
                </div>;
            },
        },
    ];

    const handleOnChange = async (e) => {

        let url = await toBase64(e.target.files[0])
        setImageUrl(url);

        const files = e.target.files;
        setUploadFiles([...files]);
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const handleFinish = (value) => {
        if (defultValue === false) {
            let bodyFormData = new FormData()

            if (uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    bodyFormData.append('image', file);
                });
            }
            let payloadData = new FormData();
            payloadData.append('image', bodyFormData.get('image'));
            payloadData.append('name', (value.name.toLowerCase()));
            payloadData.append('description', value.description || "");

            dispatch(categoryCreate(payloadData))
        } else {
            let payloadDataedit = new FormData();

            if (Array.isArray(uploadFiles) && uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    payloadDataedit.append('image', file);
                });
            }
            else {
                payloadDataedit.append('image', uploadFiles);
            }
            payloadDataedit.append('_id', id);
            payloadDataedit.append('name', (value.name.toLowerCase()));
            payloadDataedit.append('description', value.description || "");

            dispatch(categoryUpdate(payloadDataedit))

        }
    }

    const handleUpdate = (row) => {
        if (document.querySelector(".common-component-set")) {
            document.querySelector(".common-component-set").scrollTo({ top: 0, behavior: 'smooth' });
        }
        setIsFormValid(true)
        form.setFieldValue("name", row.name)
        form.setFieldValue("description", row.description)
        setUploadFiles(row.image);
        setImage(row.image);
        setDefultValue(true)
        setId(row._id)
    }

    const handleDelete = (row) => {
        dispatch(categoryDelete(row))
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setIsFormValid(false);
    };
    const onValuesChange = (_, values) => {
        const allRequiredFieldsFilled = ['name'].every(
            (key) => values[key]
        );

        setIsFormValid(allRequiredFieldsFilled);
    };

    const handleToggleChange = (_id, newStatus) => {
        let statusmain = null
        if (newStatus === 0) {
            statusmain = 1;
        } else {
            statusmain = 0;
        }
        let payload = {
            _id: _id, status: String(statusmain)
        }
        dispatch(categoryStatus(payload))

    }

    const handleChangesStatusAllData = (e) => {
        setCurrentPage(1)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchData = (e) => {
        clearTimeout(typingTimeout);
        setSearchLoading(true);
        setSearchDataAll(e);
        const timeout = setTimeout(async () => {
            setCurrentPage(1);
        }, 800);
        setTypingTimeout(timeout);
    };
   

    const skeletonItems = Array.from({ length: pageSize }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };

    useEffect(() => {
        Cate()
    }, [categoryData])

    return (
        <>
            <Helmet>
                <title>Category</title>
            </Helmet>
            <MainContentComponent>
                <div className='main-content-body overflow-y-scroll dynamic-scroll-all' id='Categories'>
                    <div className="project">
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handleFinish}
                            onFinishFailed={onFinishFailed}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            form={form}
                            ref={formRef}
                        >
                            <div className="right-header">
                                <div className="row gx-0">
                                    <div className="col-10 d-flex align-items-start">
                                        <h2 className='commonPageTitle'>{defultValue === true ? "Update Category" :"Create Category"}</h2>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                        <Refresh isFetching={isFetching} handleRefreshClick={handleRefreshClick} />
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="row">

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Name<span className="required">*</span></label>
                                        <Form.Item name="name" key="AppName" className='height-set-Description' htmlFor="sname" rules={[{ required: true, message: 'Enter a Name' }]}>
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups d-flex justify-content-center align-items-center col-12 col-sm-6 col-md-4 col-lg-2 appname cate-image custom-height ant-input-remove-marg">
                                        <div>
                                            <label htmlFor="sname" className='commonLabel'>Image<span className="required">*</span></label>
                                            <Form.Item name="image" key="status">
                                                <Input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Click to Upload"
                                                    id="csvFileInput"
                                                    onChange={handleOnChange}
                                                    ref={fileInputRef}
                                                    accept="image/png, image/jpeg,image/jpg"
                                                />
                                            </Form.Item>
                                        </div>
                                        {
                                            imageUrl ? (
                                                <div className='align-self-end ms-2'>
                                                    <img src={imageUrl} alt="Uploaded Preview" className='app-detail-icon' />
                                                </div>

                                            ) : (
                                                <>
                                                    {image ? (
                                                        <div className='align-self-end ms-2'>
                                                            <img src={`${REACT_APP_SOCKET}/uploads/category/${uploadFiles}`} alt="Google" className='app-detail-icon' />
                                                        </div>
                                                    ) : (
                                                        <div className='align-self-end ms-2'>
                                                            <img src={require('../../App_Details_theme/images/Frame.png')} className='app-detail-icon' alt="" />
                                                        </div>
                                                    )

                                                    }
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 user-search ant-input-remove-marg" >
                                        <label htmlFor="pname" className='commonLabel'>Description</label>
                                        <Form.Item name="description" key="AppName" className='height-set-Description' htmlFor="sname">
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Description"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-end button-color custom-height">
                                        <Submitbutton form={form}
                                            isFormValid={isFormValid} loadingUpdate={loadingUpdate} loadingSave={loadingSave} getAllDataPass={defultValue}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>

                    {/* <!-- Project Records --> */}
                    <div className="project-records project-record-page overflow-hidden">
                        <div className="header">
                            <Form>
                                <div className="row">
                                    <div className="col-lg-8 col-md-4 left">
                                        <h2 className='commonTableTitle'>Category</h2>
                                    </div>
                                    <div className="col-lg-4 col-md-8 right d-lg-flex d-md-flex justify-content-end">
                                        <div className="form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status">Status</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Selected Value"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={selectedStatusData}
                                                    onChange={(value) => {
                                                        setSelectedStatusData(value === undefined ? '0' : value);
                                                        handleChangesStatusAllData(value);
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    <Select.Option value="0">Active</Select.Option>
                                                    <Select.Option value="1">Inactive</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="form-groups ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status">Search</label>
                                            <Search
                                                allowClear
                                                onSearch={(value, event, { source }) => {
                                                    if (source === 'input') {
                                                        handleSearchData(value);
                                                    }
                                                }}
                                                onChange={(e) => handleSearchData(e.target.value)}
                                                placeholder="Search"
                                                value={searchDataAll}
                                                className='search-input-loader mySearch height-set-Description'
                                                loading={searchLoading} />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="body table-body lookUpTableDiv my-pagination tableSetCss my-table-sckeleton">
                            <Table
                                columns={columns}
                                rowKey={(record) => record._id}
                                dataSource={categoryData}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    onChange: (page) => setCurrentPage(page),
                                    onShowSizeChange: (current, size) => setPageSize(size),
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }}
                                components={customComponents}
                                className='body-row'
                                size="small"
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                    </div>
                </div>
            </MainContentComponent>
        </>
    )
}

export default Category;
