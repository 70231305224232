import './header.scss'
import * as AntIcons from '@ant-design/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { AUTHDATA } from '../Redux/ActionTypes/actionTypes';
import { useSelector } from 'react-redux';
import LogoFullName from '../App_Details_theme/images/main/ntm-full-logo.png'
import LogoN from '../App_Details_theme/images/main/only N.png'
const Header = () => {
  const pathData = useSelector((state) => state.pathReducer)
  const [logoFullState, setLogoFullState] = useState(false)
  // let array = [
  //   {
  //     "iconName": "PieChartOutlined",
  //     "route": "/home",
  //     "role": "0",
  //     "menuName": "Home",
  //     "mainMenu": [

  //     ]
  //   },
  //   {
  //     "iconName": "BarChartOutlined",
  //     "route": "/manual-order",
  //     "menuName": "Manual Order",
  //     "mainMenu": [

  //     ]
  //   },
  //   {
  //     "iconName": "RadarChartOutlined",
  //     "route": "/category",
  //     "menuName": "Category",
  //     "mainMenu": [

  //     ]
  //   },
  //   {
  //     "iconName": "HeatMapOutlined",
  //     "route": "/sub-category",
  //     "menuName": "Sub Category",
  //     "mainMenu": [

  //     ]
  //   },
  //   {
  //     "iconName": "SlidersOutlined",
  //     "menuName": "Orders",
  //     "route": "",
  //     "mainMenu": [
  //       {
  //         "route": "/pending-orders",
  //         "subMenu": "Pending Orders",
  //       },
  //       {
  //         "route": "/approved-orders",
  //         "subMenu": "Approved Orders",
  //       },
  //       {
  //         "route": "/dispatched-orders",
  //         "subMenu": "Dispatched Orders",
  //       }
  //     ]
  //   },
  //   {
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Products",
  //     "mainMenu": [
  //       {
  //         "route": "/add-product",
  //         "subMenu": "Add Product",
  //       },
  //       {
  //         "route": "/view-product",
  //         "subMenu": "View Product",
  //       }
  //     ]
  //   },
  //   {
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Users",
  //     "mainMenu": [
  //       {
  //         "route": "/active-users",
  //         "subMenu": "Active Users",
  //       },
  //       {
  //         "route": "/users-request",
  //         "subMenu": "Users Request",
  //       },
  //       {
  //         "route": "/blocked-users",
  //         "subMenu": "Blocked Users",
  //       }
  //     ]
  //   },
  //   {
  //     "iconName": "RubyOutlined",
  //     "route": "/slider",
  //     "menuName": "Slider",
  //     "mainMenu": [

  //     ]
  //   },
  //   {
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Report",
  //     "mainMenu": [
  //       {
  //         "route": "/today-report",
  //         "subMenu": "Today Report",
  //       },
  //       {
  //         "route": "/weekly-report",
  //         "subMenu": "Weekly Report",
  //       },
  //       {
  //         "route": "/monthly-report",
  //         "subMenu": "Monthly Report",
  //       },
  //       {
  //         "route": "/yearly-report",
  //         "subMenu": "Yearly Report",
  //       },
  //       {
  //         "route": "/date-to-date-report",
  //         "subMenu": "Date To Date Report",
  //       },
  //     ]
  //   }
  // ]

  // let array = [
  //   {
  //     "iconName": "PieChartOutlined",
  //     "route": "/home",
  //     "role": "0",
  //     "menuName": "Home",
  //     "Component": "Home"
  //   },
  //   {
  //     "iconName": "RadarChartOutlined",
  //     "role": "0",
  //     "route": "/category",
  //     "menuName": "Category",
  //     "Component": "Category"
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "HeatMapOutlined",
  //     "route": "/sub-category",
  //     "menuName": "Sub Category",
  //     "Component": "SubCategory"
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "SlidersOutlined",
  //     "menuName": "Orders",
  //     "route": "",
  //     "mainMenu": [
  //       {
  //         "role": "0",
  //         "route": "/pending-orders",
  //         "subMenu": "Pending Orders",
  //         "Component": "PendingOrders",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/approved-orders",
  //         "subMenu": "Approved Orders",
  //         "Component": "ApprovedOrders",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/dispatched-orders",
  //         "subMenu": "Dispatched Orders",
  //         "Component": "DispatchedOrders",
  //       }
  //     ]
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Products",
  //     "mainMenu": [
  //       {
  //         "role": "0",
  //         "route": "/add-product",
  //         "subMenu": "Add Product",
  //         "Component": "AddProduct",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/view-product",
  //         "subMenu": "View Product",
  //         "Component": "ViewProduct",
  //       }
  //     ]
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Users",
  //     "mainMenu": [
  //       {
  //         "role": "0",
  //         "route": "/active-users",
  //         "Component": "ActiveUsers",
  //         "subMenu": "Active Users"
  //       },
  //       {
  //         "role": "0",
  //         "route": "/users-request",
  //         "Component": "UsersRequest",
  //         "subMenu": "Users Request"
  //       },
  //       {
  //         "role": "0",
  //         "route": "/blocked-users",
  //         "Component": "BlockedUsers",
  //         "subMenu": "Blocked Users"
  //       }
  //     ]
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "RubyOutlined",
  //     "Component": "Slider",
  //     "route": "/slider",
  //     "menuName": "Slider"
  //   },
  //   {
  //     "role": "0",
  //     "iconName": "RubyOutlined",
  //     "route": "",
  //     "menuName": "Report",
  //     "mainMenu": [
  //       {
  //         "role": "0",
  //         "route": "/today-report",
  //         "Component": "TodayReport",
  //         "subMenu": "Today Report",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/weekly-report",
  //         "Component": "WeeklyReport",
  //         "subMenu": "Weekly Report",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/monthly-report",
  //         "Component": "MonthlyReport",
  //         "subMenu": "Monthly Report",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/yearly-report",
  //         "Component": "YearlyReport",
  //         "subMenu": "Yearly Report",
  //       },
  //       {
  //         "role": "0",
  //         "route": "/date-to-date-report",
  //         "Component": "DateToDateReport",
  //         "subMenu": "Date To Date Report",
  //       }
  //     ]
  //   }
  // ]
  let [allMenu, setAllMenu] = useState([])

  useEffect(() => {
    if (pathData.type === AUTHDATA) {
      if (pathData.data.routeData) {
        setAllMenu(pathData.data.routeData)
      }
      else {
        setAllMenu([])
      }
    }
  }, [pathData])

  const mouseFunction = (condition) => {
    if (!mobileSize) {
      if (!fullSidebarState) {
        if (condition === 'enter') {
          setLogoFullState(true)
          document.querySelector('#project-header .main-group').classList.add('full-sidebar')
        }
        else {
          setLogoFullState(false)
          document.querySelector('#project-header .main-group').classList.remove('full-sidebar')
        }
      }
    }
  }

  let [menuLastIndex, setMenuLastIndex] = useState("")
  const openSubMenu = (event, index) => {
    if (menuLastIndex !== index) {
      document.querySelectorAll('.header-first-li')?.forEach((element) => {
        element.classList.remove('main-menu-open')
      })
      document.querySelectorAll('.header-first-li .main-sub-menu')?.forEach((element) => {
        element.classList.add('d-none')
      })
      document.querySelectorAll('.header-first-li .main-menu-arrow')?.forEach((element) => {
        element.classList.remove('open-arrow')
      })
    }
    if (event.target.closest('.header-first-li').querySelector('.main-sub-menu').classList.contains('d-none')) {
      event.target.closest('.header-first-li').classList.add('main-menu-open')
      event.target.closest('.header-first-li').querySelector('.main-sub-menu').classList.remove('d-none')
      event.target.closest('.header-first-li').querySelector('.main-menu-arrow').classList.add('open-arrow')
    }
    else {
      event.target.closest('.header-first-li').classList.remove('main-menu-open')
      event.target.closest('.header-first-li').querySelector('.main-sub-menu').classList.add('d-none')
      event.target.closest('.header-first-li').querySelector('.main-menu-arrow').classList.remove('open-arrow')
    }
    setMenuLastIndex(index)
  }

  const closeAllDropdown = () => {
    let allOpenDropdown = document.querySelectorAll('.main-menu-open');
    if (allOpenDropdown) {
      allOpenDropdown.forEach((element) => {
        element.classList.remove('main-menu-open')
        element.querySelector('.main-sub-menu').classList.add('d-none')
        element.querySelector('.main-menu-arrow').classList.remove('open-arrow')
      })
    }
  }

  const [fullSidebarState, setFullSidebarState] = useState(true)

  const fullSidebarToggle = (stateChange) => {
    if (stateChange) {
      setLogoFullState(true)
      document.querySelector('.content-div').classList.add('row')
      document.getElementById('project-header').classList.add('default-fixed-sidebar')
      document.getElementById('right-section').classList.add('default-fixed-content')
    }
    else {
      setLogoFullState(false)
      document.querySelector('.content-div').classList.remove('row')
      document.getElementById('project-header').classList.remove('default-fixed-sidebar')
      document.getElementById('right-section').classList.remove('default-fixed-content')
    }
    setFullSidebarState(stateChange)
  }

  const [mobileSize, setMobileSize] = useState(false)

  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      if (entry.target.offsetWidth > 991) {
        setMobileSize(false)
      }
      else {
        setMobileSize(true)
      }
    }
  })

  useEffect(() => {
    resizeObserver.observe(document.getElementById('main-section'));
  }, [])

  const bgOverlayClose = () => {
    if (mobileSize) {
      document.querySelector('.bg-overlay').classList.remove('open')
      document.querySelector('.mobile-size').classList.add('display-none')
    }
  }

  return (
    <div className={`display-none default-fixed-sidebar ${mobileSize ? 'mobile-size' : 'not-mobile-size'} ${(fullSidebarState && !mobileSize) ? 'default-fixed-sidebar' : ''}`} id='project-header' onMouseEnter={() => mouseFunction('enter')} onMouseLeave={() => mouseFunction('leave')}>
      <div className='dummy-header'></div>
      <div className={`main-group ${mobileSize ? 'full-sidebar marginTop' : ''} ${(fullSidebarState && !mobileSize) ? 'full-sidebar' : ''}`}>
        <div className={`sidebar-toggle-div ${mobileSize ? 'd-none' : ''}`}>
          {fullSidebarState === false ?
            <div className='cursor-pointer round-icon' onClick={() => fullSidebarToggle(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="7.25" stroke="#999999" strokeWidth="1.5" />
              </svg>
            </div> :
            <div className='cursor-pointer round-icon' onClick={() => fullSidebarToggle(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <circle cx="8" cy="8" r="7.25" stroke="var(--theme-color)" strokeWidth="1.5" />
                <circle cx="8" cy="8" r="3" fill="var(--theme-color)" />
              </svg>
            </div>
          }
        </div>
        <div className="header-top">
          <div className="company-logo">
            <img className='full-name' src={(logoFullState || mobileSize) ? LogoFullName : LogoFullName} style={{ width: logoFullState ? '55px' : '120px' }} alt="" />
            {/* <img className='full-name' src={(logoFullState || mobileSize) ? LogoFullName : LogoN} style={{ width: logoFullState ? '120px' : '55px' }} alt="" /> */}
          </div>
        </div>
        <div className="line"></div>
        <div className="menu">
          <ul className='menu-ul'>
            {allMenu.map((iconSet, index) => {
              const IconComponent2 = AntIcons[iconSet.iconName]
              return (
                <Fragment key={index}>
                  {/* if route available */}
                  {
                    <li className="header-first-li" >
                      {
                        iconSet.route ?
                          <Fragment>
                            <NavLink to={`${iconSet.route}`} className='icon-div d-flex flex-column' >
                              <section className='d-flex align-items-center justify-content-between li-full-content' onClick={(e) => closeAllDropdown(e)}>
                                <div className='icon-right-side d-flex align-items-center justify-content-start'>
                                  <div className='icon-div-left d-flex align-items-center justify-content-start'>
                                    <span className='main-group-icon'>
                                      <IconComponent2 />
                                    </span>
                                    <span className='main-menu-name'>
                                      {iconSet.menuName}
                                    </span>
                                  </div>
                                  {iconSet.mainMenu
                                    &&
                                    <span className='main-menu-arrow'>
                                      <i className="ri-arrow-right-s-fill"></i>
                                    </span>
                                  }
                                </div>
                              </section>
                              <div className="main-sub-menu d-none">
                                <ul className='sub-ul'>
                                  {iconSet.mainMenu &&
                                    iconSet.mainMenu.map((nestedMenu) => {
                                      return (
                                        <Fragment key={nestedMenu.route}>
                                          <li className="cursor-pointer" /* onClick={(e) => { defaultOpen(e, rowData?.menuName) }} */>
                                            <NavLink to={`${nestedMenu.route}`} className='submenu-link d-flex align-items-center justify-content-between' onClick={() => {
                                              bgOverlayClose()
                                            }}>
                                              <div className='sub-menu-name'>
                                                {nestedMenu.subMenu}
                                              </div>
                                            </NavLink>
                                          </li>
                                        </Fragment>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </NavLink>
                          </Fragment> :
                          <Fragment>
                            {/* if route not available */}
                            <div className='icon-div d-flex flex-column' >
                              <section className='d-flex align-items-center justify-content-between li-full-content' onClick={(e) => openSubMenu(e, index)}>
                                <div className='icon-right-side d-flex align-items-center justify-content-start cursor-pointer'>
                                  <div className='icon-div-left d-flex align-items-center justify-content-start'>
                                    <span className='main-group-icon'>
                                      <IconComponent2 />
                                    </span>
                                    <span className='main-menu-name'>
                                      {iconSet.menuName}
                                    </span>
                                  </div>
                                  {iconSet.mainMenu
                                    &&
                                    <span className='main-menu-arrow'>
                                      <i className="ri-arrow-right-s-fill"></i>
                                    </span>
                                  }
                                </div>
                              </section>
                              <div className="main-sub-menu d-none">
                                <ul className='sub-ul'>
                                  {iconSet.mainMenu &&
                                    iconSet.mainMenu.map((nestedMenu) => {
                                      return (
                                        <Fragment key={nestedMenu.route}>
                                          <li className="cursor-pointer" /* onClick={(e) => { defaultOpen(e, rowData?.menuName) }} */>
                                            <NavLink to={`${nestedMenu.route}`} className='submenu-link d-flex align-items-center justify-content-between' onClick={() => {
                                              bgOverlayClose()
                                            }}>
                                              <div className='sub-menu-name'>
                                                {nestedMenu.subMenu}
                                              </div>
                                            </NavLink>
                                          </li>
                                        </Fragment>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                            </div>

                          </Fragment>
                      }
                    </li>
                  }
                </Fragment>
              )
            })}
          </ul>
        </div>
      </div>
    </div >
  )
}

export default Header