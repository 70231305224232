import axios from "axios";
import { GETROLE } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const getRole = (apiObj, setLoading, setSearchLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/role/role_getRole', apiObj,tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GETROLE,
                        payload: response?.data,
                    });
                }
                if(setLoading){
                    setLoading(false)
                }
                if(setSearchLoading){
                    setSearchLoading(false)
                }
            })
        }
        catch (error) {
            if(setLoading){
                setLoading(false)
            }
            if(setSearchLoading){
                setSearchLoading(false)
            }
            CustomMessage('success', error.message)
        }
    }
}
