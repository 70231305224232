import $ from "jquery";

const homeScroll = () => {
    var bodyHeight = $(".pages").height();
    var windowtop = $("#header-user").outerHeight();
    var breadcumbheight = $(".breadcumb").outerHeight();

    var maindashboardsize = bodyHeight - windowtop - breadcumbheight;
    $(".pages #home-page").css("height", maindashboardsize).css('overflow-y', 'auto');

    $(window).resize(function () {
        var bodyHeight = $(".pages").height();
        var windowtop = $("#header-user").outerHeight();
        var breadcumbheight = $(".breadcumb").outerHeight();

        var maindashboardsize = bodyHeight - windowtop - breadcumbheight;
        $(".pages #home-page").css("height", maindashboardsize).css('overflow-y', 'auto');
    })
}

export default homeScroll