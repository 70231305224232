import React, { Fragment, useMemo } from "react";
import "./Orders.css";
import * as AntIcons from "@ant-design/icons";
import MainContentComponent from "../../App_Details_theme/components/MainContentComponent";
import { Button, Dropdown, Form, Image, Input, Modal, Table } from "antd";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import autoTable from "jspdf-autotable";
import { useEffect } from "react";
import { useState } from "react";
import {
  userCancleOrder,
  usersOrder,
} from "../../Redux/Action/userOrdersGetAll";
import {
  USERCANCELORDER,
  USERORDER,
} from "../../Redux/ActionTypes/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import axios from "axios";
import { downloadSummary } from "../../App_Details_theme/functions/DownloadSummary";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const Orders = () => {
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userOrderData, setUserOrderData] = useState([]);
  const [form] = Form.useForm();
  let dispatch = useDispatch();
  let userOrder = useSelector((state) => state.usersOrderReducer);
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(true);

  const [searchLoading, setSearchLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const UserOrder = () => {
    let payload = {
      page: currentPage,
      limit: pageSize,
      search: "",
      status: "",
    };
    dispatch(usersOrder(payload, setLoading, setSearchLoading, setIsFetching));
  };

  useEffect(() => {
    UserOrder();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (userOrder.type === USERORDER) {
      if (userOrder.data.success && userOrder.data.data?.items?.length !== 0) {
        setUserOrderData(userOrder.data.data.items);
        setTotalItems(userOrder.data.data.totalItems);
      } else {
        setUserOrderData([]);
        setTotalItems(0);
      }
    }
    if (userOrder.type === USERCANCELORDER) {
      if (userOrder.data.success) {
        UserOrder();
        form.resetFields();
        setIsModalOpen(false);
      }
    }
  }, [userOrder]);

  const cancelOrder = (record) => {
    setOrderId(record?.record?.orderId);
    setIsModalOpen(true);
  };

  const formFinish = (e) => {
    let payload = {
      orderId: orderId,
      description: e?.description,
    };
    dispatch(userCancleOrder(payload));
  };

  const ActionMenuParameter = (record) => {
    let [datas, setData] = useState();
    const dataSet = (data) => {
      setData(data.record.status);
    };
    let items = [];
    if (datas === 1 || datas === 2) {
      items = [
        // {
        //   key: 'downloadInvoice',
        //   label: 'Download Invoice',
        //   // onClick: downloadInvoice
        //   onClick: () => getAllSumeryData(record)
        // },
        {
          key: 1,
          label: "Download Summery",
          onClick: () => getAllSumeryData(record, "download"),
        },
        {
          key: 2,
          label: "View Summery",
          onClick: () => getAllSumeryData(record, "view"),
        },
        {
          key: 3,
          label: "Cancel Order",
          onClick: () => cancelOrder(record),
        },
      ];
    } else {
      items = [
        // {
        //   key: 'downloadInvoice',
        //   label: 'Download Invoice',
        //   // onClick: downloadInvoice
        //   onClick: () => getAllSumeryData(record)
        // },
        {
          key: 2,
          label: "Download Summery",
          onClick: () => getAllSumeryData(record),
        },
      ];
    }

    // const items =

    //   [
    //     // {
    //     //   key: 'downloadInvoice',
    //     //   label: 'Download Invoice',
    //     //   // onClick: downloadInvoice
    //     //   onClick: () => getAllSumeryData(record)
    //     // },
    //     {
    //       key: 2,
    //       label: "Download Summery",
    //       onClick: () => getAllSumeryData(record)
    //     },
    //     {
    //       key: 1,
    //       label: 'Cancel Order',
    //       onClick: () => cancelOrder(record)
    //     },
    //   ];

    return (
      <Dropdown trigger={["click"]} menu={{ items }} placement="bottomRight">
        <span className="button-option" onClick={() => dataSet(record)}>
          <AntIcons.MoreOutlined />
        </span>
      </Dropdown>
    );
  };

  const getAllSumeryData = (record, e) => {
    const payload = {
      userId: record.record.userId,
      orderId: record.record.orderId,
    };
    axios
      .post(`${REACT_APP_SOCKET}/order/order_summery`, payload)
      .then((res) => {
        if (res.data.success) {
          const body = res.data.data?.at(0).data.flatMap((x) => {
            return x.orderdetails.productDetails.flatMap((z) => {
              return [
                [
                  x.product_data.productName,
                  x.orderdetails.measurement_data.find(
                    (w) => w._id === z.measurementId
                  ).label,
                  z.data.map((d) => Object.values(d).at(0).at(0)).join(" "),
                  z.qty,
                  "\t\t",
                ],
              ];
            });
          });
          body.map((x, i) => x.unshift(i + 1));

          downloadSummary(
            {
              orderDetails: res.data.data
                ?.at(0)
                .ordertrackings_data.at(0)
                .trackingDetails.find((x) => x.status === 1),
              orderNo: res.data.data.at(0).orderNo,
              authorData: res.data.authorData,
              userData: res.data.data.at(0).user_data.at(0),
              head: [
                ["No.", "Product", "Measure", "Color/Size", "Qty", "\t\t"],
              ],
              body: body,
            },
            e
          );
        } else {
        }
      });
  };

  const newArray = useMemo(() => {
    return (dataObject) => {
      return dataObject.map((x) => {
        const newDummy2 = x.orderdetails.productDetails.map((y) => y.data);

        const tableHeaderData = {
          categoryName: x.product_data.categoryName,
          subCategoryName: x.product_data.subCategoryName,
          productName: x.product_data.productName,
          barCode: x.product_data.barCode,
        };

        const headMain = newDummy2.map((y) => {
          return y.map((z) => {
            return Object.keys(z).at(0);
          });
        });

        const headMainValue = newDummy2.map((y) => {
          return y.map((z) => {
            return Object.values(z).at(0).join(", ");
          });
        });

        return {
          tableHeaderData: tableHeaderData,
          head: [headMain.at(0)],
          body: headMainValue,
        };
      });
    };
  }, []);

  const downloadSummery = (allSummeryData) => {
    const doc = new jsPDF();

    const logo = require("../../App_Details_theme/images/main/ntm-full-logo.png");

    doc.addImage(logo, "PNG", 15, 10, 40, 15);

    const addPageNumbers = () => {
      const totalPages = doc.internal.getNumberOfPages();
      const fontSize = 10; // Define the font size here
      for (let i = 1; i <= totalPages; i++) {
        // Go to page i
        doc.setPage(i);
        // Add page number with specified font size
        doc.setFontSize(fontSize);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 32,
          doc.internal.pageSize.height - 5
        );
      }
    };

    const addCompanyName = () => {
      const totalPages = doc.internal.getNumberOfPages();
      const fontSize = 10; // Define the font size here
      for (let i = 1; i <= totalPages; i++) {
        // Go to page i
        doc.setPage(i);
        // Add page number with specified font size
        doc.setFontSize(fontSize);
        doc.text(`( NTM Group )`, 15, doc.internal.pageSize.height - 5); // Adjust the x-coordinate here
      }
    };

    autoTable(doc, {
      body: [
        [
          {
            content:
              "Date: 2022-01-27 04:39:35 AM" + "\nInvoice Number: 123456fff",
            styles: {
              halign: "left",
            },
          },
        ],
      ],
      theme: "plain",
      margin: { left: 144 },
    });

    const Add =
      "1081,1082 Silver Bussiness Point , vip circle mota varachha , india , gujrat , surat - Digital Valley";

    autoTable(doc, {
      body: [
        [
          {
            content:
              "Billed to:" +
              "\nName : Dhruv" +
              "\nMobile No. : 9974933333" +
              "\nEmail : twinnet@gmail.com" +
              `\nAddress : ${Add}` +
              "\nPin Code : 395006" +
              "\nCity : Surat" +
              "\nState : Gujrat" +
              "\nGST No. : 20HFUFN434892H33",
            styles: {
              halign: "left",
            },
          },
          {
            content:
              "From:" +
              "\nName : NTM Group" +
              "\nMobile No. : 5495855545" +
              "\nEmail : twinnet@gmail.com" +
              `\nAddress : ${Add}` +
              "\nPin Code : 395006" +
              "\nCity : Surat" +
              "\nState : Gujrat" +
              "\nGST No. : 20HFUFN434892H33",
            styles: {
              halign: "left",
            },
          },
        ],
      ],
      theme: "plain",
      columnStyles: {
        0: {
          // First column
          cellWidth: 110, // Set the width of the first column
        },
      },
    });

    autoTable(doc, {
      body: [
        [
          {
            content: "Product Summery",
            styles: {
              halign: "center",
              fontSize: 14,
            },
          },
        ],
      ],
      theme: "plain",
    });

    const createTable = (dataObject) => {
      let yPos = 0;

      return dataObject.map((item) => {
        autoTable(doc, {
          body: [
            [
              {
                content: `${item.tableHeaderData.categoryName} / ${item.tableHeaderData.subCategoryName} / ${item.tableHeaderData.productName} / Barcode: ${item.tableHeaderData.barCode}`,
                styles: {
                  fontSize: 11,
                },
              },
            ],
          ],
          didDrawPage: function (data) {
            // Update yPos when a new page is drawn
            yPos = data.cursor.y;
          },
          theme: "plain",
        });

        autoTable(doc, {
          head: [[...item.head.at(0), "Status"]],
          body: item.body,
          startY: yPos,
          theme: "striped",
          headStyles: {
            fillColor: "#343a40",
          },
          columnStyles: {
            [item.head.at(0).length]: {
              cellWidth: 20,
            },
          },
          // didParseCell: function (data) {
          //   if (data.column.index === 2 || data.column.index === 4) { // Check if the current cell is in the "Quantity" column
          //     data.cell.styles.halign = 'center'; // Center-align the content horizontally
          //   }
          // },
          didDrawCell: function (data) {
            if (
              data.column.index === item.head.at(0).length &&
              data.row.section !== "head"
            ) {
              // Assuming you want to add the image in the first column of the fifth row
              var cellHeight = data.cell.height;
              doc.addImage(
                require("../../App_Details_theme/images/main/pngegg.png"),
                "PNG",
                data.cell.x + 1,
                data.cell.y + 1,
                cellHeight - 2,
                cellHeight - 2
              );
            }
          },
        });
      });
    };

    createTable(newArray(allSummeryData));

    autoTable(doc, {
      body: [
        [
          {
            content: "Authorized Signature",
            styles: {
              halign: "left",
            },
          },
          {
            content: "Note : \n\n\n\n\n\n",
            styles: {
              halign: "left",
              lineWidth: 0.3,
            },
          },
        ],
      ],
      theme: "plain",
      columnStyles: {
        0: {
          // First column
          cellWidth: 100, // Set the width of the first column
        },
      },
    });

    addPageNumbers();
    addCompanyName();

    return doc.save("summery");
  };

  const columnTable = [
    {
      title: <span>Order No</span>,
      dataIndex: "orderNo",
      width: "150px",
      key: "orderNo",
      render: (value) => <span>{value}</span>,
    },
    {
      title: <span>Track</span>,
      dataIndex: "track",
      width: "100px",
      key: "track",
      render: (value, record) => (
        <span>
          <Link
            to={{
              pathname: `${record.orderNo}`,
              search: `?${record.orderId}`,
            }}
          >
            Track
          </Link>
          {/* <Link to={`${record.orderNo}`} >
            Track
          </Link> */}
        </span>
      ),
      align: "center",
    },
    {
      title: <span>Status</span>,
      dataIndex: "status",
      width: "100px",
      key: "status",
      render: (value) => {
        return (
          <Fragment>
            <div className="d-flex justify-content-center">
              {value === 1 ? (
                <div className="orange-user d-flex">
                  <div className="orange-text-user text-capitalize">
                    Pending
                  </div>
                </div>
              ) : value === 2 ? (
                <div className="green-user d-flex">
                  <div className="green-text-user text-capitalize">
                    Accepted
                  </div>
                </div>
              ) : value === 3 ? (
                <div className="red-user d-flex">
                  <div className="red-text-user text-capitalize">Rejected</div>
                </div>
              ) : value === 4 ? (
                <div className="red-user d-flex">
                  <div className="red-text-user text-capitalize">Cancle</div>
                </div>
              ) : value === 5 ? (
                <div className="blue-user d-flex">
                  <div className="blue-text-user text-capitalize">
                    Dispatched
                  </div>
                </div>
              ) : null}
            </div>
          </Fragment>
        );
      },
      align: "center",
    },
    {
      title: <span>Courier Name</span>,
      dataIndex: "courierName",
      width: "150px",
      key: "courierName",
      render: (courierName) => (
        <span className="text-capitalize">{courierName || "-"}</span>
      ),
      align: "center",
    },
    {
      title: <span>Image</span>,
      dataIndex: "transformReceipt",
      width: "100px",
      key: "transformReceipt",
      render: (transformReceipt) => (
        <span>
          {transformReceipt === "" ? (
            <Image
              width={35}
              height={35}
              src={require("../../App_Details_theme/images/Frame.png")}
              className="image-pdf-logo"
            />
          ) : (
            <Image
              width={35}
              height={35}
              src={`${REACT_APP_SOCKET}/uploads/transformReceipt/${transformReceipt}`}
              className="image-pdf-logo"
            />
          )}
          {/* <Image width={35} height={35} src={`${REACT_APP_SOCKET}/uploads/transformReceipt/${transformReceipt}`} className='image-pdf-logo' /> */}
        </span>
      ),
      align: "center",
    },
    {
      title: <span>Placed Order Date</span>,
      dataIndex: "createdAt",
      width: "150px",
      key: "createdAt",
      render: (createdAt) => (
        <span>{dayjs(createdAt).format("DD-MM-YYYY hh:mm A")}</span>
      ),
      align: "center",
    },
    {
      title: <span>Last Updated Date</span>,
      dataIndex: "updatedAt",
      width: "150px",
      key: "updatedAt",
      render: (updatedAt) => (
        <span>{dayjs(updatedAt).format("DD-MM-YYYY hh:mm A")}</span>
      ),
      align: "center",
    },
    {
      title: <div>Estimated Delivery Date</div>,
      dataIndex: "estimateDeliveryDate",
      key: "estimateDeliveryDate",
      width: "160px",
      render: (estimateDeliveryDate, record) => {
        return (
          <Fragment>
            {estimateDeliveryDate === "" ? (
              "-"
            ) : (
              <span>{moment(estimateDeliveryDate).format("DD MMM YYYY")}</span>
            )}
          </Fragment>
        );
      },
      align: "center",
    },
    {
      title: "",
      dataIndex: "option",
      width: "60px",
      key: "option",
      render: (value, record) => <ActionMenuParameter record={record} />,
      align: "right",
    },
  ];
  function exportPdf() {
    var pdf = new jsPDF();
    pdf.text(20, 20, "Employee Details");
    pdf.autoTable({
      html: "#table",
      startY: 25,
      theme: "grid",
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 60 },
        2: { cellWidth: 40 },
        3: { cellWidth: 60 },
      },
      bodyStyles: { lineColor: [1, 1, 1] },
      styles: { minCellHeight: 10 },
    });
    window.open(URL.createObjectURL(pdf.output("blob")));
  }

  return (
    <Fragment>
      <Modal
        wrapClassName="pending-order"
        title="Cancel"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={formFinish} layout="vertical">
          <div className="form-group date-mod">
            <label className="commonLabel" htmlFor="Delay">
              Reason For Cancel Order<span className="required">*</span>
            </label>
            <Form.Item
              name="description"
              className="height-set-Description"
              rules={[
                { required: true, message: "Reason For Cancel Order Required" },
              ]}
            >
              <TextArea
                rows={4}
                name="description"
                placeholder="Reason For Cancel Order"
              />
            </Form.Item>
          </div>
          <div className="footer d-flex justify-content-end">
            <Form.Item>
              <Button
                name="buttonClickedSave"
                className="ms-1"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <MainContentComponent>
        <div id="order">
          <div className="order-title">Orders</div>

          <div className="my-pagination tableSetCss my-table-sckeleton">
            <Table
              size="small"
              columns={columnTable}
              dataSource={userOrderData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                showQuickJumper: true,
                onChange: (page) => setCurrentPage(page),
                onShowSizeChange: (current, size) => setPageSize(size),
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          </div>
        </div>
      </MainContentComponent>
    </Fragment>
  );
};

export default Orders;
