import { SyncOutlined } from '@ant-design/icons';
import React from 'react'
import { Link } from 'react-router-dom';

const Refresh = ({isFetching,handleRefreshClick}) => {
    return (
        <Link
            to="#"
            className={`refresh d-flex align-items-center ${isFetching ? 'disabled' : ''}`}
            onClick={(e) => {
                e.preventDefault();
                handleRefreshClick();
            }}
        >
            {isFetching ? (
                <>
                    <SyncOutlined spin size='small' className='isFetching' />
                    <div className='isFetching color'>Refresh</div>
                </>
            ) : (
                <>
                    <SyncOutlined size='small' className='notFetching' />
                    <div className='notFetching'>Refresh</div>
                </>
            )}
        </Link>
    )
}

export default Refresh;