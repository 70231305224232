import React, { useEffect } from 'react'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'
import { Steps } from 'antd'
import './TrackOrder.css'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { USERCTRACKORDER } from '../../Redux/ActionTypes/actionTypes'
import { useState } from 'react'
import { userTrackOrder } from '../../Redux/Action/userOrdersGetAll'
import dayjs from 'dayjs'

const TrackOrder = () => {
    const params = useParams()
    let userOrder = useSelector((state) => state.usersOrderReducer)
    const [userTrackData, setUserTrackData] = useState([])
    const { search } = useLocation()
    let dispatch = useDispatch()

    useEffect(() => {
        if (userOrder.type === USERCTRACKORDER) {
            if (userOrder.data.success && userOrder.data.data?.length !== 0) {
                setUserTrackData(userOrder.data.data)
            }
            else {
                setUserTrackData([])
            }
        }
    }, [userOrder])

    const TrackOrder = () => {
        let payload = {
            orderId: search?.slice(1)
        }
        dispatch(userTrackOrder(payload))
    }

    useEffect(() => {
        TrackOrder()
    }, [])


    const stepsData = userTrackData.map(item => {
        return {
            title: (
                <div>
                    {item?.status === 1 ? <span className='title'>Place Order By <span className='text-capitalize'>{item.name}</span></span> :
                        item?.status === 2 ? <span className='title'>Order Accepted By <span className='text-capitalize'>{item.name}</span></span> :
                            item?.status === 3 ? <span className='title'>Order Rejected By <span className='text-capitalize'>{item.name}</span></span> :
                                item?.status === 4 ? <span className='title'>Order Canceled By <span className='text-capitalize'>{item.name}</span></span> :
                                    item?.status === 5 ? <span className='title'>Order Dispatch By <span className='text-capitalize'>{item.name}</span></span> : null

                    }

                    <span className='date'>{dayjs(item.date).format('DD-MM-YYYY hh:mm A')}</span>
                </div>
            ),
            description: item.description,
        };
    });

    return (
        <MainContentComponent>
            <div id="track-orders">
                <div className='trackin-order-title'>
                    Tracking Order #{params.orderId}
                </div>

                <Steps
                    progressDot
                    size="small"
                    current={userTrackData?.length}
                    direction="vertical"
                    items={stepsData}
                />
            </div>

        </MainContentComponent>
    )
}

export default TrackOrder