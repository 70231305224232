import React, { Fragment, useEffect } from 'react'
import './maincontentcomponent.css'
import { mainDivScroll } from './mainDivScroll'
import BreadCumb from './BreadCumb'
const MainContentComponent = ({ children }) => {
    useEffect(() => {
        mainDivScroll()
    }, [])

    return (
        <Fragment>
            <BreadCumb />
            <div className="common-component-set">
                {children}
            </div>
        </Fragment>
    )
}

export default MainContentComponent