import axios from "axios";
import { MANAGEPROFILE, UPDATEPROFILE, UPDATEPROFILEIMAGE } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const getUserById = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/register_getById', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MANAGEPROFILE,
                        payload: response?.data,
                    });
                }
            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const updateProfile = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_update', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATEPROFILE,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.data.message)

                }
            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const updateProfileImage = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/profilePhoto_upload', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATEPROFILEIMAGE,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.data.message)
                }
            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

