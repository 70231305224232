import {
  DUMMY,
  FORGETPASS,
  FORGETPASSOTPVERIFY,
  LOGIN,
  LOGINOTP,
  REGISTER,
  REGISTEROTP
} from "../ActionTypes/actionTypes";
let defaultState = {};

export const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER: {
      return {
        data: {
          ...action.payload
        },
        type: REGISTER
      };
    }
    case REGISTEROTP: {
      return {
        data: {
          ...action.payload
        },
        type: REGISTEROTP
      };
    }
    case LOGIN: {
      return {
        data: {
          ...action.payload
        },
        type: LOGIN
      };
    }
    case LOGINOTP: {
      return {
        data: {
          ...action.payload
        },
        type: LOGINOTP
      };
    }
    
    case DUMMY: {
      return {
        data: state,
        type: DUMMY
      };
    }
    case FORGETPASS: {
      return {
        data: {
          ...action.payload
        },
        type: FORGETPASS
      };
    }
    case FORGETPASSOTPVERIFY: {
      return {
        data: {
          ...action.payload
        },
        type: FORGETPASSOTPVERIFY
      };
    }
    default:
      return defaultState;
  }
};
