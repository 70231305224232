import axios from "axios";
import { CATEGORY, CATEGORYSTATUS, DELETECATEGORY, DELETESUBCATEGORY, POSTCATEGORY, POSTSUBCATEGORY, SUBCATEGORY, UPDATECATEGORY, UPDATESUBCATEGORY, SUBCATEGORYSTATUS, SUBCATEGORYBYID } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";

const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

//category
export const categoryGetAll = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/category/category_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CATEGORY,
                        payload: response?.data,
                    });

                    if (setLoading) {
                        setLoading(false)
                    }
                    if (setSearchLoading) {
                        setSearchLoading(false)
                    }
                    if (setIsFetching) {
                        setIsFetching(false)
                    }

                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const categoryCreate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/category/category_post', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: POSTCATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const categoryDelete = (id) => {
    return (dispatch) => {
        try {
            axios.delete(REACT_APP_SOCKET + '/category/category_delete/' + id).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DELETECATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const categoryUpdate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/category/category_update', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATECATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const categoryStatus = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/category/category_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CATEGORYSTATUS,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)
                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

//sub-category
export const subCategoryGetAll = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/subCategory/subCategory_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBCATEGORY,
                        payload: response?.data,
                    });
                    if (setLoading) {
                        setLoading(false)
                    }
                    if (setSearchLoading) {
                        setSearchLoading(false)
                    }
                    if (setIsFetching) {
                        setIsFetching(false)
                    }
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const subCategoryCreate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/subCategory/subCategory_post', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: POSTSUBCATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const subCategoryDelete = (id) => {
    return (dispatch) => {
        try {
            axios.delete(REACT_APP_SOCKET + '/subCategory/subCategory_delete/' + id).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DELETESUBCATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const subCategoryUpdate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/subCategory/subCategory_update', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATESUBCATEGORY,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const subCategoryStatus = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/subCategory/subCategory_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBCATEGORYSTATUS,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)
                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const subCategoryGetById = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/subCategory/subCategory_getById', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBCATEGORYBYID,
                        payload: response?.data,
                    });
                    if (setLoading) {
                        setLoading(false)
                    }
                    if (setSearchLoading) {
                        setSearchLoading(false)
                    }
                    if (setIsFetching) {
                        setIsFetching(false)
                    }
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}