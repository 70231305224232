import React, { Fragment, useEffect, useRef, useState } from "react";
import CustomBreadcumb from "./CustomBreadcumb";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import * as AntIcons from "@ant-design/icons";
import "./SingleProduct.css";
import { Form, Select, Skeleton, Button, Input, Table } from "antd";
import ReactImageMagnify from "react-image-magnify";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import Submitbutton from "../../App_Details_theme/Common_Components/Submitbutton";
import SubmitButtonUser from "../SubmitButtonUser";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCartData } from "../Cart/CartAction";
import { serchSelect } from "../../Redux/Action/serchSelect";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const SingleProduct = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [allDataCategory, setAllDataCategory] = useState([]);
  const [breadCumpCategory, setBreadCumpCategory] = useState([]);
  const searchSelectRedux = useSelector((state) => state.searchSelectReducer)
  const [form] = Form.useForm();
  const [priceObj, setPriceObj] = useState({});
  const [measurementOption, setMeasurementOption] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const formRef = useRef();

  //Get Cart Data
  const [cartDisplayLoading, setCartDisplayLoading] = useState(false);
  const [cartAllData, setCartAllData] = useState([]);
  const [price, setPrice] = useState(0);

  const [priceLoading, setPriceLoading] = useState(false);
  const [indexWiseOption, setIndexWiseOption] = useState([]);
  const [measureOptionLoading, setMeasureOptionLoading] = useState(false);
  let [selectedMeasurementId, setSelectedMeasurementId] = useState("");
  const [varientOption, setVarientOption] = useState({});

  let [selectedVarient, setSelectedVarient] = useState({});

  const [otherVarientOptions, setOtherVarientOptions] = useState({});

  const token = useSelector((state) => state.pathReducer.data.token);

  let ck = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  //Selected Image
  const [selectedImage, setSelectedImage] = useState("");

  const [isFormValid, setIsFormValid] = useState(false);

  const onValuesChange = (_, values) => {
    const allRequiredFieldsFilled = ["Measurement"].every((key) => values[key]);
    // Update isFormValid
    setIsFormValid(allRequiredFieldsFilled);
  };

  const [btnLoading, setBtnLoading] = useState(false);



  useEffect(() => {
    if (searchSelectRedux.data) {
      form.resetFields()
      setIndexWiseOption([])
      setPrice("")
      dispatch(serchSelect(false))
    }
  }, [searchSelectRedux])

  const getAllProductData = () => {
    setLoading(true);
    const payload = {
      _id: params.getId,
    };
    axios
      .post(`${REACT_APP_SOCKET}/product/product_getById`, payload)
      .then((res) => {
        if (res.data.success && res.data.data.length !== 0) {
          setAllDataCategory(res.data.data);
          setBreadCumpCategory([
            res.data.data?.at(0).category_data?.at(0).name,
            res.data.data?.at(0).subcategory_data?.at(0).name,
            res.data.data?.at(0).productName,
          ]);

          //Image
          // if (res.data.data.at(0).multipleImages.length > 0) {
          //   setSelectedImage(res.data.data.at(0).multipleImages[0])
          // } else {
          setSelectedImage(res.data.data.at(0).coverImage);
          // }
          setLoading(false);
        } else {
          setBreadCumpCategory([]);
          setAllDataCategory([]);

          //Image
          setSelectedImage("");
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setVarientOption({});
    setOtherVarientOptions({});
    setSelectedVarient({});

    getAllProductData();
    getMeasurment();
    getAllCartData();
    // dispatch(GetAllCartData())
  }, [params]);

  //Product Add Cart
  const getMeasurment = () => {
    const payload = {
      productId: params.getId,
    };
    axios
      .post(`${REACT_APP_SOCKET}/product/measurement`, payload)
      .then((res) => {
        if (res.data.success) {
          setMeasurementOption(res.data.data);
        } else {
          setMeasurementOption([]);
        }
      });
  };

  const handleChangeMeasurment = (value) => {
    setPrice("");
    setOtherVarientOptions({});
    setSelectedVarient({});
    indexWiseOption?.forEach((x) => {
      form.setFieldValue(x, []);
    });
    selectedMeasurementId = value;
    setSelectedMeasurementId(selectedMeasurementId);
    setMeasureOptionLoading(true);
    const payload = {
      _id: params.getId,
      measurementId: value,
    };
    axios.post(`${REACT_APP_SOCKET}/product/varient`, payload).then((res) => {
      if (res.data.success) {
        setVarientOption(res.data.data);
        setIndexWiseOption(res.data.varientsKeys);
        setMeasureOptionLoading(false);
        if (res.data.data.length === 0) {
          getPriceProduct("blank");
        }
      } else {
        setVarientOption({});
        setIndexWiseOption([]);
        setMeasureOptionLoading(false);
        // getPriceProduct("blank");
      }
    });
  };


  const handleChangeVarient_0 = (value, ind, filterOpt) => {
    if (value[0]?.length === 0) {
      setPrice(undefined);
    }
    else {
      selectedVarient = {
        ...selectedVarient,
        [filterOpt]: value,
      };
      setSelectedVarient(selectedVarient);

      if (indexWiseOption.length === 1 || ind + 1 === indexWiseOption.length) {
        getPriceProduct()
      }
      else {
        const payload = {
          _id: params.getId,
          measurementId: selectedMeasurementId,
          index: ind,
          data: {
            [filterOpt]: value,
          },
        };

        axios
          .post(`${REACT_APP_SOCKET}/product/varientFilter`, payload)
          .then((res) => {
            if (res.data.success) {
              const data = res.data.data.map((x) => {
                return {
                  title: x[filterOpt],
                  label: x[filterOpt],
                  options: x[indexWiseOption[ind + 1]].map((y) => ({
                    label: y,
                    value: y,
                  })),
                };
              });
              // getPriceProduct()
              setOtherVarientOptions({
                ...otherVarientOptions,
                [indexWiseOption[ind + 1]]: data,
              });
            } else {
              // getPriceProduct()
              setOtherVarientOptions({
                ...otherVarientOptions,
              });
            }
          });
      }
    }

  };


  const separatedArray = (data) => {
    return Object.keys(data).map((key) => {
      const newObj = {};
      newObj[key] = data[key];
      return newObj;
    });
  };

  const apiCall = async (payloadArray, callIndex) => {
    console.log(priceObj[Object.values(payloadArray[callIndex][0])[0]], "payloadArray[callIndex]");
    const payload = {
      productId: params.getId,
      measurementId: selectedMeasurementId,
      orderDatas: payloadArray[callIndex],
      qty: quantity,
      price: priceObj[Object.values(payloadArray[callIndex][0])[0]],
    };
    await axios
      .post(`${REACT_APP_SOCKET}/order/addToCart`, payload, ck)
      .then((res) => {
        if (res.data.success) {
          if (callIndex < payloadArray.length - 1) {
            apiCall(payloadArray, callIndex + 1)
          }
          else {
            getAllCartData();
            dispatch(GetAllCartData());
          }
          CustomMessage("success", res.data.message);
          setBtnLoading(false);
          form.resetFields();
          setVarientOption({});
          setOtherVarientOptions({});
          setQuantity(1);
        } else {
          CustomMessage("error", res.data.message);
          setBtnLoading(false);
          form.resetFields();
          setVarientOption({});
          setOtherVarientOptions({});
          setQuantity(1);
        }
        setPrice("");

      });
  }


  const addProduct = () => {
    if (indexWiseOption?.length === 1 && indexWiseOption !== undefined) {
      let payloadArray = [];
      payloadArray = Object.values(selectedVarient)[0][0]?.map((Data) => {
        return [
          {
            [Object.keys(selectedVarient)[0]]: [Data]
          }
        ]
      })
      apiCall(payloadArray, 0)
    } else {
      const payload = {
        productId: params.getId,
        measurementId: selectedMeasurementId,
        orderDatas: separatedArray(selectedVarient),
        qty: quantity,
        price: price,
      };
      setBtnLoading(true);

      axios
        .post(`${REACT_APP_SOCKET}/order/addToCart`, payload, ck)
        .then((res) => {
          if (res.data.success) {
            CustomMessage("success", res.data.message);
            setBtnLoading(false);
            form.resetFields();
            setVarientOption({});
            setOtherVarientOptions({});
            setQuantity(1);
          } else {
            CustomMessage("error", res.data.message);
            setBtnLoading(false);
            form.resetFields();
            setVarientOption({});
            setOtherVarientOptions({});
            setQuantity(1);
          }
          setPrice("");
          getAllCartData();
          dispatch(GetAllCartData());
        });
    }
  };

  const getPriceProduct = (e) => {
    let newVarient = selectedVarient
    if (e === "blank") {
      newVarient = {}
    }

    let dataVar = []
    if (Object.keys(selectedVarient).length === 1) {
      dataVar = Object.values(selectedVarient)[0][0].map((item) => {
        // console.log(item, "item");
        return { [Object.keys(selectedVarient)[0]]: [item] }
      })
      dataVar.forEach(async (dataset) => {
        const payload = {
          _id: params.getId,
          measurementId: selectedMeasurementId,
          data: dataset,
        }
        await axios
          .post(`${REACT_APP_SOCKET}/product/product_price`, payload)
          .then((res) => {
            if (res.data.success) {
              // console.log(payload,"payload.measurementId",Object.values(dataset)[0][0]);
              setPriceObj((prev) => {
                let newObj = { ...prev };
                newObj[Object.values(dataset)[0][0]] = res.data.data[0].price;
                return newObj
              })

              setPrice(res.data.data?.at(0)?.price);
              setPriceLoading(false);
            } else {
              setPrice(0);
              setPriceLoading(false);
            }
          });
      })

      console.log(priceObj, "ooofoofofof")

    } else {
      setPriceLoading(true);
      const payload = {
        _id: params.getId,
        measurementId: selectedMeasurementId,
        data: newVarient,
      };
      axios
        .post(`${REACT_APP_SOCKET}/product/product_price`, payload)
        .then((res) => {
          if (res.data.success) {
            setPrice(res.data.data?.at(0)?.price);
            setPriceLoading(false);
          } else {
            setPrice(0);
            setPriceLoading(false);
          }
        });
    }

  };

  const columnCart = [
    {
      title: <span>Measurement Type</span>,
      dataIndex: "measurementId",
      width: "150px",
      key: "measurementId",
      render: (value, record) => {
        const text = record.measurement_data.find((x) => x._id === value).label;
        return <span className="text-capitalize">{text ?? "-"}</span>;
      },
    },
    {
      title: <span>Preference</span>,
      dataIndex: "data",
      width: "150px",
      key: "data",
      render: (value) => {
        return (
          <span>
            {value.length
              ? value.map((x, i) => {
                return (
                  <div key={i} className="table-size-menu text-capitalize">
                    <span className="dark">{Object.keys(x).at(0)} :</span>
                    <span className="light">
                      {Object.values(x).at(0).join(" ,")}
                    </span>
                  </div>
                );
              })
              : "-"}
          </span>
        );
      },
    },
    {
      title: <span>Quantity</span>,
      dataIndex: "qty",
      width: "80px",
      key: "qty",
      render: (value) => <span>{value}</span>,
    },
    {
      title: <span>Price</span>,
      dataIndex: "price",
      width: "100px",
      key: "price",
      render: (value) => <span>{value}</span>,
    },
    {
      title: <span>Action</span>,
      dataIndex: "action",
      width: "100px",
      key: "action",
      render: (value, record) => {
        return (
          <span>
            <AntIcons.DeleteOutlined
              onClick={() =>
                deleteSingleVarient(record.orderDetailId, record._id, record)
              }
              style={{ color: "red" }}
            />
          </span>
        );
      },
    },
  ];

  const deleteProduct = (record) => {
    const payload = {
      orderId: record.orderId,
      productId: record.productId,
    };
    axios
      .post(`${REACT_APP_SOCKET}/order/order_delete`, payload, tokenSetup())
      .then((res) => {
        if (res.data.success) {
          CustomMessage("success", res.data.message);
        } else {
          CustomMessage("error", res.data.message);
        }
        getAllCartData();
        dispatch(GetAllCartData());
      });
  };

  const deleteSingleVarient = (orderDetailId, productDetailId, record) => {
    if (cartAllData.length === 1) {
      deleteProduct(record);
    } else {
      const payload = {
        orderDetailId: orderDetailId,
        productDetailId: productDetailId,
      };
      axios
        .post(`${REACT_APP_SOCKET}/order/orderVarient_delete`, payload)
        .then((res) => {
          if (res.data.success) {
            CustomMessage("success", res.data.message);
          } else {
            CustomMessage("error", res.data.message);
          }
          getAllCartData();
          // dispatch(GetAllCartData());
        });
    }
  };


  const getAllCartData = () => {
    const payload = {
      productId: params.getId,
    };
    setCartDisplayLoading(true);
    axios
      .post(`${REACT_APP_SOCKET}/order/addToCart_getAll`, payload, tokenSetup())
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.length) {
            if (res.data.data?.at(0).orderdetails?.at(0)?.productDetails) {
              setCartAllData(
                res.data.data
                  ?.at(0)
                  .orderdetails.at(0)
                  .productDetails.map((z, i) => {
                    return {
                      ...z,
                      measurement_data: res.data.data?.at(0).measurement_data,
                      orderDetailId: res.data.data?.at(0)?.orderdetails?.at(0)
                        ?.orderDetailId,
                      orderId: res.data.data?.at(0).orderdetails.at(0).orderId,
                      productId: res.data.data?.at(0).orderdetails.at(0)
                        .productId,
                    };
                  })
              );
              // setCartAllData([res.data.data?.at(0).orderdetails.at(0).productDetails, { measurement_data: res.data.data?.at(0).measurement_data }]);
            } else {
              setCartAllData([]);
            }
          } else {
            setCartAllData([]);
          }
        } else {
          setCartAllData([]);
        }
        setCartDisplayLoading(false);
      });
  };

  return (
    <>
      <CustomBreadcumb name={breadCumpCategory} />
      {loading ? (
        <>
          <div id="product-main">
            <div className="row g-0">
              <div className="col-12 col-md-6 col-lg-5">
                <div className="left-prod-detail row g-0">
                  <div className="multi-img col-2"></div>
                  <div className="cover-img-load col-10">
                    <Skeleton.Node active></Skeleton.Node>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-7">
                <div className="right-prod-detail">
                  <div className="product-title">
                    <Skeleton active />
                  </div>
                  <div className="product-barcode"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        allDataCategory.map((item, index) => {
          const multipleImageObject = item.multipleImages.filter(
            (x) => x !== item.coverImage
          );

          // const multipleImageObject = item.multipleImages;
          return (
            <Fragment>
              <div key={index} id="product-main">
                <div className="row g-0">
                  <div className="col-12 col-md-6 col-lg-5">
                    <div className="left-prod-detail row g-0">
                      <div className="multi-img col-2">
                        {multipleImageObject.length !== 0 ? (
                          <>
                            <img
                              className={`${selectedImage === item.coverImage
                                ? "selectedImage"
                                : ""
                                }`}
                              onClick={() => setSelectedImage(item.coverImage)}
                              src={`${REACT_APP_SOCKET}/uploads/coverImages/${item.coverImage}`}
                              alt=""
                            />
                            {multipleImageObject.map((images, imageIndex) => {
                              return (
                                <img
                                  className={`${selectedImage === images
                                    ? "selectedImage"
                                    : ""
                                    }`}
                                  onClick={() => setSelectedImage(images)}
                                  key={imageIndex}
                                  src={`${REACT_APP_SOCKET}/uploads/multipleImages/${images}`}
                                  alt=""
                                />
                              );
                            })}
                          </>
                        ) : (
                          <img
                            className="selectedImage"
                            src={`${REACT_APP_SOCKET}/uploads/coverImages/${selectedImage}`}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="cover-img col-10">
                        {multipleImageObject?.includes(selectedImage) ? (
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "",
                                isFluidWidth: true,
                                src: `${REACT_APP_SOCKET}/uploads/multipleImages/${selectedImage}`,
                              },
                              largeImage: {
                                src: `${REACT_APP_SOCKET}/uploads/multipleImages/${selectedImage}`,
                                width: 700,
                                height: 750,
                              },
                            }}
                          />
                        ) : (
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "",
                                isFluidWidth: true,
                                src: `${REACT_APP_SOCKET}/uploads/coverImages/${selectedImage}`,
                              },
                              largeImage: {
                                src: `${REACT_APP_SOCKET}/uploads/coverImages/${selectedImage}`,
                                width: 700,
                                height: 750,
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-7">
                    <div className="right-prod-detail">
                      <div className="product-title">{item.productName}</div>
                      <div className="product-barcode">{item.barCode}</div>

                      {/* {
                          item.outOfStock === 0 ?
                            <div className={`stock green`}>
                              In Stock
                            </div>
                            :
                            <div className={`stock red`}>
                              Out of Stock
                            </div>
                        } */}

                      {item.shortDescription && (
                        <div>
                          <span className="product-barcode">
                            Short Description :{" "}
                          </span>
                          {item.shortDescription}
                        </div>
                      )}

                      {item.description && (
                        <div className="description-main">
                          <span className="title">About this item :-</span>
                          <div
                            className="description-main-data"
                            dangerouslySetInnerHTML={{
                              __html: item.description
                                .replace(
                                  /<table/g,
                                  '<table style="border-collapse: collapse; border: 1px solid #000;"'
                                )
                                .replace(
                                  /<td/g,
                                  '<td style="border: 1px solid #000;"'
                                ),
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Form
                  ref={formRef}
                  form={form}
                  className="product-main-bottom d-flex flex-wrap gap-2"
                  onValuesChange={onValuesChange}
                  onFinish={addProduct}
                >
                  <div className="form-groups ant-input-remove-marg">
                    <label htmlFor="Measurement" className="commonLabel">
                      Measurement<span className="required">*</span>
                    </label>
                    <Form.Item
                      htmlFor="Measurement"
                      name="Measurement"
                      className="height-set-Description"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "160px" }}
                        allowClear
                        placeholder="Measurement"
                        onChange={(e) => handleChangeMeasurment(e ?? "")}
                        value={selectedMeasurementId}
                      >
                        {measurementOption.map((item, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={item.measurementId}
                            >
                              {item.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  {indexWiseOption?.length === 1 ?
                    <Fragment>
                      {indexWiseOption?.map((itemData, indexData) => {
                        return (
                          <div
                            key={index}
                            className="form-groups ant-input-remove-marg"
                          >
                            <label
                              htmlFor={itemData}
                              className="commonLabel text-capitalize"
                            >
                              {itemData}
                              <span className="required">*</span>
                            </label>
                            <Form.Item
                              htmlFor={itemData}
                              name={itemData}
                              className="height-set-Description"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              {indexData === 0 ? (
                                <Select
                                  // maxCount={1}
                                  maxTagCount="responsive"
                                  mode="multiple"
                                  style={{ width: "160px" }}
                                  allowClear
                                  placeholder={itemData}
                                  onChange={(e) =>
                                    handleChangeVarient_0(
                                      [e] ?? [],
                                      indexData,
                                      itemData
                                    )
                                  }
                                  value={selectedVarient[itemData]}
                                >
                                  {varientOption[itemData]?.map(
                                    (itemOpt, indexOpt) => {
                                      return (
                                        <Select.Option
                                          key={indexOpt}
                                          value={itemOpt}
                                        >
                                          {itemOpt}
                                        </Select.Option>
                                      );
                                    }
                                  )}
                                </Select>
                              ) : (
                                <>
                                  <Select
                                    // maxCount={1}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    style={{
                                      width: 160,
                                    }}
                                    placeholder={itemData}
                                    onChange={(e) => {
                                      handleChangeVarient_0(
                                        [e] ?? [],
                                        indexData,
                                        itemData
                                      );
                                    }}
                                    options={
                                      Object.hasOwn(otherVarientOptions, itemData)
                                        ? otherVarientOptions[itemData]
                                        : []
                                    }
                                    value={selectedVarient[itemData]}
                                  />
                                </>
                              )}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </Fragment>
                    :
                    <Fragment>
                      {indexWiseOption?.map((itemData, indexData) => {
                        return (
                          <div
                            key={index}
                            className="form-groups ant-input-remove-marg"
                          >
                            <label
                              htmlFor={itemData}
                              className="commonLabel text-capitalize"
                            >
                              {itemData}
                              <span className="required">*</span>
                            </label>
                            <Form.Item
                              htmlFor={itemData}
                              name={itemData}
                              className="height-set-Description"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              {indexData === 0 ? (
                                <Select
                                  maxCount={1}
                                  maxTagCount="responsive"
                                  // mode="multiple"
                                  style={{ width: "160px" }}
                                  allowClear
                                  placeholder={itemData}
                                  onChange={(e) =>
                                    handleChangeVarient_0(
                                      [e] ?? [],
                                      indexData,
                                      itemData
                                    )
                                  }
                                  value={selectedVarient[itemData]}
                                >
                                  {varientOption[itemData]?.map(
                                    (itemOpt, indexOpt) => {
                                      return (
                                        <Select.Option
                                          key={indexOpt}
                                          value={itemOpt}
                                        >
                                          {itemOpt}
                                        </Select.Option>
                                      );
                                    }
                                  )}
                                </Select>
                              ) : (
                                <>
                                  <Select
                                    maxCount={1}
                                    allowClear
                                    // mode="multiple"
                                    maxTagCount="responsive"
                                    style={{
                                      width: 160,
                                    }}
                                    placeholder={itemData}
                                    onChange={(e) => {
                                      handleChangeVarient_0(
                                        [e] ?? [],
                                        indexData,
                                        itemData
                                      );
                                    }}
                                    options={
                                      Object.hasOwn(otherVarientOptions, itemData)
                                        ? otherVarientOptions[itemData]
                                        : []
                                    }
                                    value={selectedVarient[itemData]}
                                  />
                                </>
                              )}
                            </Form.Item>
                          </div>
                        );
                      })}
                    </Fragment>

                  }

                  <div className="form-groups ant-input-remove-marg">
                    <label htmlFor="Quantity" className="commonLabel">
                      Quantity <span className="required">*</span>
                    </label>
                    <Form.Item
                      style={{ width: "100px" }}
                      htmlFor="Quantity"
                      name="Quantity"
                      className="height-set-Description"
                    >
                      <Input
                        type="number"
                        name="Quantity"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        min={1}
                        defaultValue={1}
                      />
                    </Form.Item>
                  </div>

                  {/* Amount And Price */}
                  {price ? (
                    <div className="form-groups ant-input-remove-marg">
                      <label htmlFor="Price" className="commonLabel">
                        Price/Qty
                      </label>
                      <Form.Item
                        style={{ width: "100px" }}
                        htmlFor="Price"
                        name="Price"
                        className="height-set-Description"
                      >
                        <Button style={{ width: "100%" }} disabled>
                          {price}
                        </Button>
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <div className="form-groups ant-input-remove-marg">
                      <label htmlFor="Amount" className='commonLabel'>Total Amount</label>
                      <Form.Item
                        style={{ width: "100px" }}
                        htmlFor="Amount"
                        name="Amount"
                        className='height-set-Description'
                      >
                        <Button style={{ width: "100%" }} disabled>
                          {quantity * price}
                        </Button>
                      </Form.Item>
                    </div> */}

                  <div className="form-groups ant-input-remove-marg d-flex align-items-end single-prod-height">
                    <SubmitButtonUser
                      loadingSave={btnLoading}
                      form={form}
                      isFormValid={isFormValid}
                      type="primary"
                      disabled={
                        measureOptionLoading === false && priceLoading === false
                      }
                    >
                      Add to Cart
                    </SubmitButtonUser>
                  </div>
                </Form>
                {cartAllData.length !== 0 ? (
                  <>
                    <div className="order-text">Product in Cart</div>
                    <div className="my-pagination tableSetCss my-table-sckeleton">
                      <Table
                        size="small"
                        columns={columnCart}
                        dataSource={cartAllData}
                        scroll={{
                          x: null,
                          y: 500,
                        }}
                        pagination={false}
                        loading={cartDisplayLoading}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <Link to={"/cart"}>
                        <Button type="primary" danger>
                          Go To Cart
                        </Button>
                      </Link>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Fragment>
          );
        })
      )}
    </>
  );
};

export default SingleProduct;
