import axios from "axios";
import { USERSTATUSAPPROVED, USERSTATUSPENDING, USERSTATUSREJECTED, USERSTATUSALL, USERSTATUSCHANGEAPPROVED, USERSTATUSCHANGEREJECTED, USERSTATUSCHANGEPENDING, USERSTATUSCHANGEALL, USERTYPECHANGE } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const userStatusApproved = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_post_get', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSAPPROVED,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                setSearchLoading(false)
                setIsFetching(false)
            })
        }
        catch (error) {
            setLoading(false)
            setSearchLoading(false)
            setIsFetching(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusPending = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_post_get', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSPENDING,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                setSearchLoading(false)
                setIsFetching(false)
            })
        }
        catch (error) {
            setLoading(false)
            setSearchLoading(false)
            setIsFetching(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusRejected = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_post_get', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSREJECTED,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                setSearchLoading(false)
                setIsFetching(false)
            })
        }
        catch (error) {
            setLoading(false)
            setSearchLoading(false)
            setIsFetching(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusAll = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_post_get', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSALL,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                setSearchLoading(false)
                setIsFetching(false)
            })
        }
        catch (error) {
            setLoading(false)
            setSearchLoading(false)
            setIsFetching(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusChangeApproved = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSCHANGEAPPROVED,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.message);
                    setLoading(false)
                }
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusChangeRejected = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSCHANGEREJECTED,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.message);
                    setLoading(false)
                }
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusChangePending = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSCHANGEPENDING,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.message);
                    setLoading(false)
                }
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userStatusChangeAll = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERSTATUSCHANGEALL,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.message);
                    setLoading(false)
                }
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}

export const userTypeChange = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/user_type', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERTYPECHANGE,
                        payload: response?.data,
                    });
                    CustomMessage('success', response?.data?.message);
                    if (setLoading) {
                        setLoading(false)
                    }
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }

            CustomMessage('success', error.message)
        }
    }
}