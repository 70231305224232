import axios from "axios";
import { PRODUCT, POSTPRODUCT, DELETEPRODUCT, UPDATECATEGORY, UPDATEPRODUCT, PRODUCTSTATUS } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

//product

export const productGetAll = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/product/product_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    if (setLoading) {
                        setLoading(false)
                    }
                    if (setSearchLoading) {
                        setSearchLoading(false)
                    }
                    if (setIsFetching) {
                        setIsFetching(false)
                    }
                    dispatch({
                        type: PRODUCT,
                        payload: response?.data,
                    });
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const productCreate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/product/product_post', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: POSTPRODUCT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const productDelete = (id) => {
    return (dispatch) => {
        try {
            axios.delete(REACT_APP_SOCKET + '/product/product_delete/' + id).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DELETEPRODUCT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const productUpdate = (apiObj, setButonLoading) => {
    return (dispatch) => {
        try {
            if (setButonLoading) {
                setButonLoading(true)
            }
            axios.post(REACT_APP_SOCKET + '/product/product_update', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATEPRODUCT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)
                    if (setButonLoading) {
                        setButonLoading(false)
                    }
                } else {
                    CustomMessage('error', response.data.message)
                    if (setButonLoading) {
                        setButonLoading(false)
                    }
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
            if (setButonLoading) {
                setButonLoading(false)
            }
        }
    }
}

export const productStatus = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/product/product_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: PRODUCTSTATUS,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)
                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}