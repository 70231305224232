import * as AntIcons from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const CustomBreadcumb = (props) => {
    const location = useLocation()
    const breadCumpItem = useMemo(() => {
        const locationPath = location.pathname.split('/').filter(x => x)

        let url = `/${locationPath[0]}`

        let array = [
            {
                title: <>
                    <NavLink to={`/${locationPath[0]}`} style={{ textTransform: "capitalize" }}>
                        <AntIcons.EyeOutlined />
                        Home
                    </NavLink>
                </>
            },
            ...props.name.map((x, i) => {
                url += `/${locationPath.slice(1)[i]}`
                return {
                    title: <>
                        <NavLink to={url} style={{ textTransform: "capitalize", color: `${props.name.length - 1 === i ? '#3066fb' : '#00000073'}` }}>
                            {x}
                        </NavLink>
                    </>
                }
            })
        ]

        return array;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, props])
    return (
        <div className="breadcumb">
            <div className="row">
                <div className="col-12 left d-flex align-items-center">
                    <Breadcrumb
                        items={[...breadCumpItem]}
                    />
                </div>
                {props.children &&
                    props.children}
            </div>
        </div>
    )
}

export default CustomBreadcumb