import { BarcodeOutlined } from '@ant-design/icons';
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import CustomBreadcumb from './CustomBreadcumb';
import { Empty, Skeleton, Spin } from 'antd';
import homeScroll from '../js/homeScroll';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const SingleCategory = () => {
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const [allDataCategory, setAllDataCategory] = useState([])

  const [breadCumpCategory, setBreadCumpCategory] = useState([])


  const getSingleCategoryData = () => {
    const payload = {
      _id: params.id
    }
    axios.post(`${REACT_APP_SOCKET}/subCategory/subCategory_getById`, payload).then(res => {
      if (res.data.success) {
        setBreadCumpCategory([res.data.data?.at(0)?.category_data?.at(0).name, res.data.data?.at(0)?.name])
      } else {
        setBreadCumpCategory([])
      }
    })
  }

  useEffect(() => {
    getSingleCategoryData()
    getAllProductData()
  }, [params])



  const getAllProductData = () => {
    const payload = {
      page: 1,
      limit: 100000,
      search: '',
      status: '0',
      categoryId: params.category,
      subCategoryId: params.id
    }
    setLoading(true)
    axios.post(`${REACT_APP_SOCKET}/product/product_getData`, payload).then(res => {
      if (res.data.success) {
        setAllDataCategory(res.data.data.items);
        setLoading(false)
      } else {
        setAllDataCategory([]);
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    homeScroll()
  }, [allDataCategory])

  return (
    <Fragment>
      <CustomBreadcumb name={breadCumpCategory} />
      <div id="home-page">
        
        {!loading && allDataCategory.length === 0
          ?

          // Empty No Data 
          <div className='d-flex flex-column h-100 justify-content-center'>
            <Empty description="No Data " image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
          :

          //Main data Content
          <div className='category-body'>
            {
              loading ?
                Array.from({ length: 6 }).map((_, index) => (
                  <Link key={index} to={'#'} >
                    <div className='category-card bg-white'>
                      <div className='img-card'>
                        <Skeleton.Node active />
                      </div>
                      <div className='img-title mt-1'>
                        <Skeleton active title={false} />
                      </div>
                    </div>
                  </Link>
                ))
                :
                allDataCategory?.map((item, index) => {
                  return (
                    <Link key={index} to={item._id}>
                      <div className='category-card'>
                        <div className='img-card'>
                          {
                            item.coverImage ?
                              <img src={`${REACT_APP_SOCKET}/uploads/coverImages/${item.coverImage}`} alt="" />
                              :
                              <div className='bg-white h-100 w-100 d-flex flex-column justify-content-center rounded'>
                                <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              </div>
                          }
                        </div>
                        <div className='img-title text-capitalize justify-content-start'>
                          {item.productName}
                        </div>
                        <div className='img-title justify-content-start'>
                          <BarcodeOutlined /> {item.barCode}
                        </div>
                      </div>
                    </Link>
                  )
                })
            }
          </div>
        }
      </div>
    </Fragment>
  )
}

export default SingleCategory