import React, { Fragment, useEffect, useRef, useState } from 'react'
import './Cart.css'
import axios from 'axios'
import { Button, Empty, Skeleton, Popconfirm, Form, Input } from 'antd'
import * as AntIcons from '@ant-design/icons';
import BreadCumb from '../../App_Details_theme/components/BreadCumb'
import { tokenSetup } from '../../App_Details_theme/functions/tokenSetup';
import CustomMessage from '../../App_Details_theme/CustomMessage/CustomMessage'
import { Link, useNavigate } from 'react-router-dom'
import CartScroll from '../js/Cart'
import { GetAllCartData } from './CartAction';
import { useDispatch, useSelector } from "react-redux";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const Cart = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const formRef = useRef();
    const [cartDataLoading, setCartDataLoading] = useState(true)
    const [cartAllData, setCartAllData] = useState([])
    const [cartAllDataPrice, setCartAllDataPrice] = useState()
    const [orderId, setOrderId] = useState('')

    useEffect(() => {
        dispatch(GetAllCartData(setCartDataLoading, setCartAllData, setOrderId, setCartAllDataPrice))
    }, [])

    const [placeOrderLoading, setPlaceOrderLoading] = useState(false)
    const placeOrder = (values) => {
        setPlaceOrderLoading(true)
        const payload = {
            description: values ? values.description ?? '' : '',
            courierName: values ? values.courierName ?? '' : '',
            orderId: orderId
        }
        axios.post(`${REACT_APP_SOCKET}/order/placeOrder`, payload, tokenSetup()).then(res => {
            if (res.data.success) {
                CustomMessage('success', res.data.message)
                navigate('/orders')
            } else {
                CustomMessage('error', res.data.message)
            }
            setPlaceOrderLoading(false)
            form.resetFields()
            dispatch(GetAllCartData(setCartDataLoading, setCartAllData, setOrderId,setCartAllDataPrice))
        })
    }

    useEffect(() => {
        CartScroll()
    }, [cartAllData])


    const deleteProduct = (record) => {
        const payload = {
            orderId: record._id,
            productId: record.product_data.at(0)._id
        }
        axios.post(`${REACT_APP_SOCKET}/order/order_delete`, payload, tokenSetup()).then(res => {
            if (res.data.success) {
                CustomMessage('success', res.data.message)
            } else {
                CustomMessage('error', res.data.message)
            }
            dispatch(GetAllCartData(setCartDataLoading, setCartAllData, setOrderId,setCartAllDataPrice))
        })
    }

    const deleteSingleVarient = (record, productDetailId, length) => {
        if (length === 1) {
            const payload2 = {
                orderId: record._id,
                productId: record.product_data.at(0)._id
            }
            axios.post(`${REACT_APP_SOCKET}/order/order_delete`, payload2, tokenSetup()).then(res => {
                if (res.data.success) {
                    CustomMessage('success', res.data.message)
                } else {
                    CustomMessage('error', res.data.message)
                }
                dispatch(GetAllCartData(setCartDataLoading, setCartAllData, setOrderId, setCartAllDataPrice))
            })
        } else {
            const payload = {
                orderDetailId: record?.orderdetails?.at(0).orderDetailId,
                productDetailId: productDetailId
            }
            axios.post(`${REACT_APP_SOCKET}/order/orderVarient_delete`, payload).then(res => {
                if (res.data.success) {
                    CustomMessage('success', res.data.message)
                } else {
                    CustomMessage('error', res.data.message)
                }
                dispatch(GetAllCartData(setCartDataLoading, setCartAllData, setOrderId, setCartAllDataPrice))
            })
        }
    }

    return (
        <Fragment>
            <BreadCumb />
            <div id="cart-main">
                <div className='cart-main-title'>
                    Shopping Cart
                </div>

                <div className='cart-all-data'>
                    {
                        cartDataLoading ?
                            <>
                                {
                                    Array.from({ length: 2 }).map((_, index) => (
                                        <div key={index} className='cart-details row g-0'>
                                            <div className='col-4 col-sm-4 col-md-3 col-lg-3 col-xxl-2'>
                                                <div className='cover-image mx-auto'>
                                                    <Skeleton.Node active></Skeleton.Node>
                                                </div>
                                            </div>
                                            <div className='col-8 col-sm-8 col-md-9 col-lg-9 col-xxl-10 flex-fill d-flex justify-content-between flex-wrap'>
                                                <Skeleton active />
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                            :
                            cartAllData.length !== 0 ?
                                cartAllData.map((item, index) => {
                                    const orderDetails = item?.orderdetails?.at(0)?.productDetails ?? [];
                                    const measurement_data = item?.measurement_data;
                                    const product = item.product_data?.at(0);
                                    return <div key={index} className='cart-details row g-0'>
                                        <div className='col-12 col-sm-3 col-lg-3 col-xxl-2'>
                                            <div className='cover-image mx-auto'>
                                                <Link to={`/home/${product?.categoyId}/${product?.subCategoryId}/${product?._id}`}>
                                                    <img src={`${REACT_APP_SOCKET}/uploads/coverImages/${product?.coverImage}`} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-9 col-lg-9 col-xxl-10 flex-fill d-flex justify-content-between flex-wrap'>
                                            <div className='details flex-fill w-100'>
                                                <div className='d-flex align-items-center justify-content-between gap-2'>
                                                    <Link to={`/home/${product?.categoyId}/${product?.subCategoryId}/${product?._id}`} className='detail-title'>
                                                        {product?.productName}
                                                    </Link>
                                                    <div className='delete-btn pe-3'>
                                                        <Popconfirm
                                                            title="Remove Product From Cart"
                                                            description="Are you sure to delete this product?"
                                                            onConfirm={() => deleteProduct(item)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <AntIcons.DeleteOutlined style={{ color: "red" }} />
                                                        </Popconfirm>
                                                    </div>
                                                </div>

                                                <div className='label-small-text'>
                                                    <span className='light-txt'>{product?.barCode}</span>
                                                </div>
                                                <div className='list-product'>
                                                    List of Products
                                                </div>
                                                <div className='order-detail-data table-responsive'>
                                                    <table className="table custom-table-fs">
                                                        {/* <caption>List of Products</caption> */}
                                                        <thead>
                                                            <tr className='table-bg'>
                                                                <th scope="col" className='custom-width-index'>#</th>
                                                                <th scope="col">Measurement Type</th>
                                                                <>
                                                                    {orderDetails?.at(0)?.data.map((innerData, ind) => {
                                                                        return <th key={ind} className='text-center' scope="col">{Object.keys(innerData).at(0)}</th>
                                                                    })}
                                                                    <th className='text-center custom-width' scope="col">Quantity</th>
                                                                    <th className='text-center custom-width' scope="col">Price</th>
                                                                    <th className='text-center custom-width-total' scope="col">Total</th>
                                                                    <th className='text-center custom-width' scope="col">Action</th>
                                                                </>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                orderDetails?.map((detailedData, index) => {
                                                                    return <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <td>{measurement_data.find(x => x._id === detailedData.measurementId).label}</td>
                                                                        {
                                                                            detailedData.data.map((innerData, ind) => {
                                                                                return <td key={ind} className='text-center'>{Object.values(innerData).at(0).join(" ,")}</td>
                                                                            })
                                                                        }
                                                                        <td className='text-center'>{detailedData.qty}</td>
                                                                        <td className='text-center'>{detailedData.price}</td>
                                                                        <td className='text-center'>{detailedData.total}</td>
                                                                        <td className='text-center'>
                                                                            <AntIcons.DeleteOutlined onClick={() => deleteSingleVarient(item, detailedData._id, orderDetails.length)} style={{ color: "red" }} />
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                <div className='py-5'>
                                    <Empty
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                        imageStyle={{
                                            height: 60,
                                        }}
                                        description={
                                            <span>
                                                Your Cart is Empty
                                            </span>
                                        }
                                    >
                                        <Link to={'/home'}>Back To Shopping</Link>
                                    </Empty>
                                </div>
                    }
                </div>

                {cartAllData.length ?
                    <div className='cart-footer'>
                        <Form
                            form={form}
                            ref={formRef}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={placeOrder}
                            autoComplete="off"
                            className='form-custom row g-0'
                        >
                            <div className='col-12 col-sm-5 col-md-4'>
                                <div className='description'>
                                    <label htmlFor='description'>Description</label>
                                    <Form.Item
                                        name="description"
                                        className='height-set-Description'
                                    >
                                        <Input.TextArea placeholder="Description" id="description" name="description" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div>
                                    <label htmlFor='courierName'>Courier Name <span className="required">*</span></label>
                                    <Form.Item
                                        name="courierName"
                                        className='height-set-Description'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please Enter Courier Name!',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder="Courier Name" id="courierName" name="courierName" />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className='col-6 col-sm-3 col-md-5 cart-footer-right'>
                                <div>
                                    <span className='subtotal'>Items: </span>
                                    <span className='price'> {cartAllDataPrice?.totalQty}</span>
                                </div>
                                <div>
                                    <span className='subtotal'>Total: </span>
                                    <span className='price'> {cartAllDataPrice?.totalPrice}/-</span>
                                </div>

                                <div className='place-order-button'>
                                    <Button type='primary' htmlType="submit" danger loading={placeOrderLoading}>
                                        Place Order
                                    </Button>
                                </div>
                            </div>
                        </Form>

                    </div> : <></>}

            </div>
        </Fragment>
    )
}

export default Cart