import axios from "axios";
import { PENDINGORDERS, ACCEPTEDORDERS, REJECTEDORDERS, CANCELORDERS, DISPATCHEDORDERS,ORDERACCEPT,ORDERREJECT,ORDERDISPATCH } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const pendingOrders = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_getAll', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: PENDINGORDERS,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const acceptedOrders = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_getAll', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ACCEPTEDORDERS,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const rejectedOrders = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: REJECTEDORDERS,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const cancelOrders = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CANCELORDERS,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const dispatchOrders = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DISPATCHEDORDERS,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const orderAccept = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_accept', apiObj,tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERACCEPT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const orderReject = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_reject', apiObj,tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERREJECT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const orderDispatch = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_dispatch', apiObj,tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ORDERDISPATCH,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

