import { Tabs } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import PersonalInfo from './PersonalInfo';
import './manageProfile.css'
import MainContentComponent from '../App_Details_theme/components/MainContentComponent';

const ManageProfile = () => {
    const [activeKey, setActiveKey] = useState();

    useEffect(() => {
        const key = sessionStorage.getItem('tabKey') || "1";
        setActiveKey(key)
    }, [])

    const items = [
        {
            key: '1',
            label: <>Personal Information</>,
            children: '',
        }
    ];

    const changeTabFunction = (key) => {
        setActiveKey(key)
        sessionStorage.setItem('tabKey', key);
    }

    return (
        <Fragment>
            <MainContentComponent>
                <div className='project-records' id='Manage-Profile'>
                    <div className="header">
                        <Tabs
                            className='tab-mange-profile'
                            size='small'
                            activeKey={activeKey}
                            items={items}
                            onChange={changeTabFunction}
                        />
                    </div>
                    {
                        activeKey === "1" ?
                            <PersonalInfo /> : ""
                    }
                </div>
            </MainContentComponent>
        </Fragment>
    )
}

export default ManageProfile;
