import { USERSTATUSAPPROVED,USERSTATUSALL,USERSTATUSREJECTED,USERSTATUSPENDING,USERSTATUSCHANGEAPPROVED ,USERSTATUSCHANGEPENDING,USERSTATUSCHANGEREJECTED,USERSTATUSCHANGEALL, USERTYPECHANGE} from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const userStatusReducer = (state = defaultState, action) => {
    switch (action.type) {
      case USERSTATUSAPPROVED: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSAPPROVED
        };
      }
      case USERSTATUSREJECTED: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSREJECTED
        };
      }
      case USERSTATUSPENDING: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSPENDING
        };
      }
      case USERSTATUSALL: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSALL
        };
      }
      case USERSTATUSCHANGEAPPROVED: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSCHANGEAPPROVED
        };
      }
      case USERSTATUSCHANGEPENDING: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSCHANGEPENDING
        };
      }
      case USERSTATUSCHANGEREJECTED: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSCHANGEREJECTED
        };
      }
      case USERSTATUSCHANGEALL: {
        return {
          data: {
            ...action.payload
          },
          type: USERSTATUSCHANGEALL
        };
      }
      case USERTYPECHANGE: {
        return {
          data: {
            ...action.payload
          },
          type: USERTYPECHANGE
        };
      }
      default:
        return defaultState;
    }
  };
  