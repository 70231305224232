import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Input, Form } from 'antd';
import '../../../Components/App_Details_theme/css/register.css';
import '../../../Components/Auth/SignUpForm/SignUpForm.css'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { registerAction, registerOtpAction } from '../../Redux/Action/loginAction';
import { DUMMY, REGISTER, REGISTEROTP } from '../../Redux/ActionTypes/actionTypes';
// import { MainContext } from '../../../App';
// import { actionTypes } from '../../Redux/auth/action/actionTypes';
// import { useDispatch } from 'react-redux';
const SignUpForm = () => {
    const loginRedux = useSelector((state) => state.authReducer)
    const naviagate = useNavigate()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [signUpform, setSignUpform] = useState({
        name: '',
        email: '',
        password: '',
        phone: ''
    })
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpVerificationStep, setOtpVerificationStep] = useState(false);
    const [emailAllData, setEmailAllData] = useState()
    const [form] = Form.useForm()
    const onFinish = (value) => {
        if (!otpVerificationStep) {
            setEmailAllData(value)
            dispatch(registerAction(value, setLoading))
        }
        else {
            dispatch(registerOtpAction({ otp: value.otp, email: value.email }, setLoading))
        }
    };

    useEffect(() => {
        if (loginRedux.type === REGISTER && loginRedux.data.success === true) {
            setOtpVerificationStep(true)
        }
        if (loginRedux.type === REGISTEROTP && loginRedux.data.success) {
            setOtpVerificationStep(false)
            naviagate("/")
            dispatch({
                type: DUMMY,
                payload: {},
            });
        }
    }, [loginRedux])

    return (
        <>
            <Helmet>
                <title>SignUp Form</title>
            </Helmet>
            <div id="register-page">
                <main>
                    <div className="row gx-0">
                        <div className="col-lg-6 col-md-6 d-flex align-items-center">
                            <div className="container">
                                <div className="content w-100">
                                    <div className="logo d-flex justify-content-center">
                                        <img src={require('../../../Components/App_Details_theme/images/main/ntm-full-logo.png')} alt="Logo" className="img-fluid" />
                                    </div>
                                    <h2>Welcome to Narayan Nero Fab 👋</h2>
                                    <p>Sign Up & Create your account</p>
                                    <Form
                                        name="basic"
                                        initialValues={{
                                            remember: true,
                                        }}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                        form={form}
                                    >
                                        {!otpVerificationStep && (
                                            <>
                                                <label htmlFor="name" className='height-set-Description'>Name<span className="required">*</span></label>
                                                <Form.Item
                                                    name="name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Your Name!',
                                                        },
                                                    ]}
                                                    className='height-set-Description ant-input-remove-marg'
                                                >
                                                    <Input
                                                        type="name"
                                                        id="name"
                                                        placeholder="Enter Your Name"
                                                        value={signUpform?.name}
                                                        size="large"
                                                    />
                                                </Form.Item>
                                            </>
                                        )}


                                        <label htmlFor="email" className='height-set-Description'>Email<span className="required">*</span></label>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please Enter Your Email!',
                                                },
                                            ]}
                                            className='height-set-Description ant-input-remove-marg'
                                        >
                                            <Input
                                                type="email"
                                                id="email"
                                                placeholder="Enter Your Email"
                                                value={signUpform?.email}
                                                disabled={otpVerificationStep}
                                                size="large"
                                            />
                                        </Form.Item>

                                        {!otpVerificationStep ?
                                            <Fragment>
                                                <label htmlFor="phone" className='height-set-Description'>Phone Number<span className="required">*</span></label>
                                                <Form.Item
                                                    name="phone"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Your Phone Number!',
                                                        },
                                                        {
                                                            pattern: /^[0-9]{10}$/, // Match exactly 10 digits
                                                            message: 'Phone Number Must Be 10 Digits.',
                                                        },
                                                    ]}
                                                    className='height-set-Description ant-input-remove-marg'
                                                >
                                                    <Input
                                                        type="tel"
                                                        id="phone"
                                                        className='height-set-Description'
                                                        placeholder="Enter Your Phone"
                                                        value={signUpform?.phone}
                                                        size="large"
                                                        onKeyPress={(e) => {
                                                            const pattern = /^[0-9\b]+$/;
                                                            if (!pattern.test(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={10} // Set the maximum length to 10 characters
                                                    />
                                                </Form.Item>

                                                <label htmlFor="password" className='height-set-Description'>Password<span className="required">*</span></label>
                                                <Form.Item
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Your Password!',
                                                        },
                                                    ]}
                                                    className='height-set-Description ant-input-remove-marg'
                                                >
                                                    <Input.Password
                                                        placeholder="Enter Your Password"
                                                        id="password"
                                                        className='height-set-Description'
                                                        type='password'
                                                        size="large"
                                                        value={signUpform?.password}
                                                    />
                                                </Form.Item>

                                                <label htmlFor="password" className='height-set-Description'>Confirm password<span className="required">*</span></label>
                                                <Form.Item
                                                    name="confirmPassword"
                                                    dependencies={['password']}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Confirm Your Password!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('password') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('The Two Passwords Do Not Match!'));
                                                            },
                                                        }),
                                                    ]}
                                                    className='height-set-Description ant-input-remove-marg '
                                                >
                                                    <Input.Password
                                                        placeholder="Confirm Your Password"
                                                        id="confirmPassword"
                                                        className='height-set-Description'
                                                        type="password"
                                                        size="large"
                                                        value={confirmPassword}
                                                    />
                                                </Form.Item>

                                                <div className="form-group">
                                                    <Button type="primary" htmlType="submit" loading={loading} className="btn login-btn mt-4" disabled={otpVerificationStep}>
                                                        Create account
                                                    </Button>
                                                </div>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <div className="otp d-flex">
                                                    <div className="form-group ant-input-remove-marg">
                                                        <label htmlFor="otp">OTP<span className="required">*</span></label>
                                                        <Form.Item
                                                            name="otp"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Enter OTP!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input type="text" id="otp" name="otp" className="form-control" placeholder="Enter OTP" />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="form-group d-flex align-items-end ant-input-remove-marg height-otpbtn">
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            className="btn"
                                                            // onClick={() => {
                                                            //     const otp = document.getElementById('otp').value;
                                                            //     handleVerifyOTP(otp);
                                                            // }}
                                                            loading={loading}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }

                                        <div className="or">
                                            <p>Or Sign In</p>
                                        </div>
                                        {/* <div onClick={handleClick} className="google cursor-pointer">
                                            <img src={require('../../../Components/App_Details_theme/images/google.png')} alt="google" />
                                            Sign up with Google
                                        </div> */}
                                        <div className="not text-center">
                                            <p>already have an account ? <Link to="/">Sign In Now</Link></p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            {/* eslint-disable-next-line */}
                            <img src={require('../../../Components/App_Details_theme/images/login-img.png')} alt="Login-Image" className="img-fluid main-img" />
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default SignUpForm
