import { GETALLUSERCATEGORY } from "../ActionTypes/actionTypes";

let defaultState = {}
export const userCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case GETALLUSERCATEGORY: {
            return {
                data: action.payload,
                type: GETALLUSERCATEGORY
            };
        }
        default:
            return defaultState;
    }
}