import axios from "axios";
import { GETALLUSERCATEGORY } from "../ActionTypes/actionTypes";
import Cookies from "universal-cookie";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const categoryGetAll = (payload, setLoading, setSearchLoading) => {
  return dispatch => {
    if (setLoading) {
      setLoading(true);
    }
    let cookies = new Cookies();
    if (cookies.get("COOKIEUSER")) {
      axios
        .post(REACT_APP_SOCKET + "/category/category_getAll", payload)
        .then(response => {
          if (response.data.success) {
            dispatch({
              type: GETALLUSERCATEGORY,
              payload: response.data.data.items
            });
          } else {
            dispatch({
              type: GETALLUSERCATEGORY,
              payload: []
            });
          }

          if (setLoading) {
            setLoading(false);
          }

          if (setSearchLoading) {
            setSearchLoading(false);
          }
        });
    }
  };
};
