import {CATEGORY,CATEGORYSTATUS,DELETECATEGORY,DELETESUBCATEGORY,POSTCATEGORY,POSTSUBCATEGORY,SUBCATEGORY,SUBCATEGORYBYID,SUBCATEGORYSTATUS,UPDATECATEGORY, UPDATESUBCATEGORY} from "../ActionTypes/actionTypes";
let defaultState = {};

export const categoryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: CATEGORY
      };
    }
    case POSTCATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: POSTCATEGORY
      };
    }
    case UPDATECATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: UPDATECATEGORY
      };
    }
    case DELETECATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: DELETECATEGORY
      };
    }
    case CATEGORYSTATUS: {
      return {
        data: {
          ...action.payload
        },
        type: CATEGORYSTATUS
      };
    }


    case SUBCATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: SUBCATEGORY
      };
    }
    case POSTSUBCATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: POSTSUBCATEGORY
      };
    }
    case UPDATESUBCATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: UPDATESUBCATEGORY
      };
    }
    case DELETESUBCATEGORY: {
      return {
        data: {
          ...action.payload
        },
        type: DELETESUBCATEGORY
      };
    }
    case SUBCATEGORYSTATUS: {
      return {
        data: {
          ...action.payload
        },
        type: SUBCATEGORYSTATUS
      };
    }
    case SUBCATEGORYBYID: {
      return {
        data: {
          ...action.payload
        },
        type: SUBCATEGORYBYID
      };
    }
    
    default:
      return defaultState;
  }
};
