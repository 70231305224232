// import { useContext } from "react"
// import { AuthContext } from "../../../App"
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup"
import axios from "axios"
import { CARTCOUNT } from "../../Redux/ActionTypes/actionTypes";
import Cookies from "universal-cookie";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const GetAllCartData = (setCartDataLoading, setCartAllData, setOrderId,setCartAllDataPrice) => {
    // const context = useContext(AuthContext)
    let cookies=new Cookies();
    return (dispatch) => {
        setCartDataLoading && setCartDataLoading(true)
        const payload = {
            productId: ""
        }
        if(cookies.get("COOKIEUSER")){
            axios.post(`${REACT_APP_SOCKET}/order/addToCart_getAll`, payload, tokenSetup()).then(res => {
                if (res.data.success) {
                    setCartAllData && setCartAllData(res.data.data);
                    setCartAllDataPrice && setCartAllDataPrice(res.data);
                    if (res?.data?.data?.length) {
                        setOrderId && setOrderId(res.data.data.at(0)._id)
                    } else {
                        setOrderId && setOrderId('')
                    }
                    setCartDataLoading && setCartDataLoading(false)
    
                    //Cart Count
                    dispatch({
                        type: CARTCOUNT,
                        payload: res?.data?.data?.length
                    })
                } else {
                    setCartDataLoading && setCartDataLoading(false)
                    setCartAllData && setCartAllData([])
                    setOrderId && setOrderId('')
    
                    //Cart Count
                    dispatch({
                        type: CARTCOUNT,
                        payload: 0
                    })
                }
            })
        }
    }

}