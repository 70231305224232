import axios from "axios";
import { USERCANCELORDER, USERORDER,USERCTRACKORDER } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const usersOrder = (apiObj, setLoading, setSearchLoading, setIsFetching) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/userOrder', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERORDER,
                        payload: response?.data,
                    });
                }
                if (setLoading) {
                    setLoading(false)
                }
                if (setSearchLoading) {
                    setSearchLoading(false)
                }
                if (setIsFetching) {
                    setIsFetching(false)
                }
            })
        }
        catch (error) {
            if (setLoading) {
                setLoading(false)
            }
            if (setSearchLoading) {
                setSearchLoading(false)
            }
            if (setIsFetching) {
                setIsFetching(false)
            }
            CustomMessage('success', error.message)
        }
    }
}

export const userCancleOrder = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/cancleOrder', apiObj, tokenSetup()).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERCANCELORDER,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const userTrackOrder = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/order/order_track', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: USERCTRACKORDER,
                        payload: response?.data,
                    });

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}