import { PENDINGORDERS, ACCEPTEDORDERS, REJECTEDORDERS, CANCELORDERS, DISPATCHEDORDERS, ORDERACCEPT, ORDERREJECT, ORDERDISPATCH } from "../ActionTypes/actionTypes";
let defaultState = {};

export const ordersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PENDINGORDERS: {
      return {
        data: {
          ...action.payload
        },
        type: PENDINGORDERS
      };
    }
    case ACCEPTEDORDERS: {
      return {
        data: {
          ...action.payload
        },
        type: ACCEPTEDORDERS
      };
    }
    case REJECTEDORDERS: {
      return {
        data: {
          ...action.payload
        },
        type: REJECTEDORDERS
      };
    }
    case CANCELORDERS: {
      return {
        data: {
          ...action.payload
        },
        type: CANCELORDERS
      };
    }
    case DISPATCHEDORDERS: {
      return {
        data: {
          ...action.payload
        },
        type: DISPATCHEDORDERS
      };
    }
    case ORDERACCEPT: {
      return {
        data: {
          ...action.payload
        },
        type: ORDERACCEPT
      };
    }
    case ORDERREJECT: {
      return {
        data: {
          ...action.payload
        },
        type: ORDERREJECT
      };
    }
    case ORDERDISPATCH: {
      return {
        data: {
          ...action.payload
        },
        type: ORDERDISPATCH
      };
    }

    default:
      return defaultState;
  }
};
