import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./Components/Redux/Store/store";
import PublicAndPrivateRoutes from "./Components/Routes/PublicAndPrivateRoutes";
import { BrowserRouter, HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <HashRouter>
        <App>
          <PublicAndPrivateRoutes />
        </App>
      </HashRouter>
    </Provider>
  </PersistGate>
);

reportWebVitals();
