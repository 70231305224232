import jsPDF from "jspdf";
import dayjs from "dayjs";
import autoTable from "jspdf-autotable";

export const downloadSummary = (dataObject, type) => {
  const doc = new jsPDF("p", "mm", "a3");
  const margin = {
    left: 5,
    right: 5,
    top: 20,
    bottom: 20,
  };
  const tablesCount = 1;
  const sections = 2;
  const spacing = 5;
  // Calculate each section width
  const printWidth = doc.internal.pageSize.width - (margin.left + margin.right);
  const sectionWidth = (printWidth - (sections - 1) * spacing) / sections;
  doc.addPage();
  const logo = require("../../App_Details_theme/images/main/ntm-full-logo.png");
  doc.addImage(logo, "PNG", 15, 10, 40, 15);
  const addPageNumbers = () => {
    const totalPages = doc.internal.getNumberOfPages();
    const fontSize = 10; // Define the font size here
    for (let i = 1; i <= totalPages; i++) {
      // Go to page i
      doc.setPage(i);
      // Add page number with specified font size
      doc.setFontSize(fontSize);
      doc.text(
        `Page ${i} of ${totalPages}`,
        doc.internal.pageSize.width - 32,
        doc.internal.pageSize.height - 5
      );
    }
  };
  const addCompanyName = () => {
    const totalPages = doc.internal.getNumberOfPages();
    const fontSize = 10; // Define the font size here
    for (let i = 1; i <= totalPages; i++) {
      // Go to page i
      doc.setPage(i);
      // Add page number with specified font size
      doc.setFontSize(fontSize);
      doc.text(`( NTM Group )`, 15, doc.internal.pageSize.height - 5); // Adjust the x-coordinate here
    }
  };
  autoTable(doc, {
    body: [
      [
        {
          content:
            `Date: ${dayjs(dataObject.orderDetails.date).format(
              "DD-MM-YYYY HH:mm:ss A"
            )}` + `\nOrder Number: ${dataObject.orderNo}`,
          styles: {
            halign: "left",
            fontSize: 11,
            fontStyle: "bold",
          },
        },
      ],
    ],
    theme: "plain",
    margin: { left: 204 },
  });

  function capitalizeFirstLetter(string) {
    return string.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  autoTable(doc, {
    body: [
      [
        {
          content:
            "Bill," +
            `\nName: ${capitalizeFirstLetter(dataObject.userData.name)}` +
            `\nMobile No: ${dataObject.userData.phone}` +
            `\nEmail: ${dataObject.userData.email}` +
            `\nAddress: ${capitalizeFirstLetter(
              dataObject.userData.personalInfo.at(0).address
            )}` +
            `\nPin Code: ${dataObject.userData.personalInfo.at(0).zipCode}` +
            `\nCity: ${capitalizeFirstLetter(
              dataObject.userData.personalInfo.at(0).city
            )}` +
            `\nState: ${capitalizeFirstLetter(
              dataObject.userData.personalInfo.at(0).state
            )}` +
            `\nGST No: ${dataObject.userData.personalInfo
              .at(0)
              .GSTNumber.toUpperCase()}`,
          styles: {
            halign: "left",
            fontSize: 11,
          },
        },
        {
          content: "",
          styles: {
            halign: "center",
          },
        },
        {
          content:
            "From," +
            `\nName: ${capitalizeFirstLetter(dataObject.authorData.name)}` +
            `\nMobile No: ${dataObject.authorData.phone}` +
            `\nEmail: ${dataObject.authorData.email}` +
            `\nAddress: ${capitalizeFirstLetter(
              dataObject.authorData.personalInfo.at(0).address
            )}` +
            `\nPin Code: ${dataObject.authorData.personalInfo.at(0).zipCode}` +
            `\nCity: ${capitalizeFirstLetter(
              dataObject.authorData.personalInfo.at(0).city
            )}` +
            `\nState: ${capitalizeFirstLetter(
              dataObject.authorData.personalInfo.at(0).state
            )}` +
            `\nGST No: ${dataObject.authorData.personalInfo
              .at(0)
              .GSTNumber.toUpperCase()}`,
          styles: {
            halign: "left",
            fontSize: 11,
          },
        },
      ],
    ],
    theme: "plain",
    columnStyles: {
      0: {
        // First column
        cellWidth: 80, // Set the width of the first column
      },
      1: {
        // First column
        cellWidth: 110, // Set the width of the first column
      },
    },
  });
  autoTable(doc, {
    body: [
      [
        {
          content: "Product Order Summary",
          styles: {
            halign: "center",
            fontSize: 14,
          },
        },
      ],
    ],
    theme: "plain",
    startY: doc.autoTable.previous.finalY + 5,
  });
  let currentSection;
  let nextSection = 1;
  let startY = margin.top;

  function createRows(count) {
    const rows = [];
    for (let i = 1; i <= count; i++) {
      rows.push([i, `dfsdfsd ishan `, `Per Peices`, `Nazzme${i}`, `1366`, ``]);
    }
    return rows;
  }

  for (let i = 0; i < dataObject.body.length; i++) {
    for (let j = 0; j < dataObject.body[i].length; j++) {
      if (typeof dataObject.body[i][j] === "string") {
        dataObject.body[i][j] = capitalizeFirstLetter(dataObject.body[i][j]);
      }
    }
  }

  doc.autoTable({
    theme: "striped",
    head: dataObject.head,
    // body: createRows(120),
    body: dataObject.body,
    styles: { fontSize: 12 },
    margin: {
      left: margin.left + (nextSection - 1) * (sectionWidth + spacing),
      // top: margin.top,
      bottom: margin.bottom,
    },
    tableWidth: "wrap",
    foot: [
      [
        {
          content: `\nNote:`,
          colSpan: 5,
          styles: {
            halign: "left",
            fontStyle: "bold",
          },
        },
      ],
      [
        {
          content: `${dataObject.orderDetails.description}`,
          colSpan: 5,
          styles: {
            halign: "left",
            fontStyle: "normal",
          },
        },
      ],
    ],
    showFoot: "lastPage",
    footStyles: {
      fillColor: [255, 255, 255],
      textColor: [60, 64, 67],
      fontSize: 11,
    },
    columnStyles: {
      0: {
        columnWidth: 11,
      },
      1: {
        columnWidth: 40,
      },
      2: {
        columnWidth: 25,
      },
      3: {
        columnWidth: 35,
      },
      4: {
        columnWidth: 15,
      },
      5: {
        columnWidth: 12,
      },
    },
    startY: doc.autoTable.previous.finalY,
    rowPageBreak: "avoid",
    didDrawPage({ table, pageNumber, pageCount }) {
      let tablePosition = doc.autoTable.previous.finalY;
      if (pageNumber === 1) {
        table.settings.margin.top = tablePosition;
      } else {
        table.settings.margin.top = 10;
      }
      currentSection = nextSection;
      nextSection = (nextSection % sections) + 1;
      const shift = (nextSection - 1) * (sectionWidth + spacing);
      table.settings.margin.left = margin.left + shift;
      if (nextSection > 1) {
        doc.setPage(doc.internal.getNumberOfPages() - 1);
      }
    },
    didDrawCell: function (data) {
      if (
        data.column.index === dataObject.head.at(0).length - 1 &&
        data.row.section !== "head" &&
        data.row.section !== "foot"
      ) {
        var cellHeight = data.cell.height;
        doc.addImage(
          require("../../App_Details_theme/images/main/pngegg.png"),
          "PNG",
          data.cell.x,
          data.cell.y + 0.5,
          7,
          7
        );
      }
    },
    didParseCell: function (data) {
      if (data.column.index === 4) {
        // Check if the current cell is in the "Quantity" column
        data.cell.styles.halign = "center"; // Center-align the content horizontally
      }
    },
  });
  doc.setPage(doc.internal.getNumberOfPages());
  const remainingVSpace =
    doc.internal.pageSize.height - margin.bottom - doc.lastAutoTable.finalY;
  if (remainingVSpace > 100) {
    nextSection = currentSection;
    startY = doc.lastAutoTable.finalY + 10;
  } else {
    startY = margin.top;
    // if (nextSection === 1) doc
  }
  doc.deletePage(1);
  addPageNumbers();
  addCompanyName();
  if (type === "view") {
    window.open(doc.output("bloburl"), "_blank");
  } else if (type === "download") {
    doc.save(`${dataObject.orderNo}(${capitalizeFirstLetter(dataObject.userData.name)}).pdf`);
  }
};
