import * as AntIcons from '@ant-design/icons';
import { Popover, Switch } from 'antd'
import './navbar.scss'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { AuthContext } from '../../App'
import { AUTHDATA, MANAGEPROFILE } from '../Redux/ActionTypes/actionTypes'
import { useDispatch, useSelector } from 'react-redux'
import CustomMessage from "../App_Details_theme/CustomMessage/CustomMessage"
import axios from 'axios';
const REACT_APP_COOKIE = process.env.REACT_APP_COOKIE
const REACT_APP = process.env.REACT_APP_SOCKET
const REACT_APP_COOKIE_USER_DATA = process.env.REACT_APP_COOKIE_USER_DATA

const Navbar = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const logOuthandler = () => {
        // closePopOver()
        cookies.remove(REACT_APP_COOKIE)
        cookies.remove(REACT_APP_COOKIE_USER_DATA)
        // localStorage.clear();
        dispatch({
            type: AUTHDATA,
            payload: {},
        });
        // navigate('/')
    }
    let [manageProfile, setManageProfile] = useState("")

    const profileData = useSelector(state => state.manageReducer)
    let twoFa = useSelector((state) => state.pathReducer.data.twoFA)
    let id = useSelector((state) => state.pathReducer.data._id)
    const stateAuth2 = useSelector(state => state.pathReducer.data)
    const stateAuth = useSelector(state => state?.pathReducer)

    useEffect(() => {

        if (profileData.type === MANAGEPROFILE) {
            if (profileData.data.success && profileData.data.data?.length !== 0) {
                setManageProfile(profileData.data.data)
                dispatch({
                    type: AUTHDATA,
                    payload: {
                        ...stateAuth.data, profilePhoto: profileData?.data?.data?.profilePhoto,
                        name: profileData?.data?.data?.name
                    },
                });
            }
            else {
                setManageProfile('')
            }
        }
    }, [profileData])

    const handleToggleChange = (_id, newStatus) => {
        let statusmain = null
        if (newStatus === 0) {
            statusmain = 1;
        } else {
            statusmain = 0;
        }
        axios.post(`${REACT_APP}/register/register_twoFA`, { _id: id, twoFA: statusmain })
            .then((res) => {
                dispatch({
                    type: AUTHDATA,
                    payload: {
                        ...stateAuth2, twoFA: statusmain, _id: id
                    },
                });
                CustomMessage('success', res.data.message);
            })
    }

    const [open, setOpen] = useState(false);
    const closePopOver = () => {
        setOpen(false)
    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };


    const content = (
        <>
            <div className='title'>
                <Link to='/manage-profile' onClick={closePopOver}><AntIcons.UserOutlined /> My Account</Link>
            </div>
            <div className='title'>
                <div className='d-flex dynamic-link' onClick={closePopOver}>
                    <AntIcons.LockOutlined />
                    <div className='cursor-pointer d-flex flex-fill justify-content-between'>
                        <span>2FA</span>
                        <Switch
                            size='small'
                            checked={twoFa === 0 ? true : false}
                            onChange={(checked) => handleToggleChange(id, checked ? 1 : 0)}
                        />
                    </div>
                </div>
            </div>
            <div className='title'>
                <Link to='' onClick={logOuthandler}><AntIcons.LogoutOutlined /> Logout</Link>
            </div>
        </>
    );

    const mobileNavOpen = () => {
        document.querySelector('.bg-overlay').classList.toggle('open')
        document.querySelector('#project-header').classList.toggle('display-none')
    }
    useEffect(() => {
        document.querySelector('.bg-overlay').addEventListener('click', mobileNavOpen)
    }, [])


    return (
        <Fragment>
            <div id="navbar" className='top-header'>
                <div className="row">
                    <div className="col-6 left d-flex align-items-center justify-content-start navbar-left">
                        {/* <h3>Good morning !</h3> */}
                        {/* <Button onClick={logOuthandler}>Log Out</Button> */}
                    </div>
                    <div className="col-6 left d-flex justify-content-end align-items-center navbar-right">
                        <div className='mobile-nav-toggler'>
                            <i className="ri-menu-line menu-icon" onClick={mobileNavOpen}></i>
                        </div>
                        {/* <div className="profile">
                            <img src={require('../App_Details_theme/images/main/ntmlogo.png')} alt="" />
                        </div> */}

                        <Popover overlayClassName='details-menu-top-menu-popup' trigger={"click"} open={open} onOpenChange={handleOpenChange} className='details-menu-top-menu' placement="bottomRight" title={null} content={content}>
                            <span className='title text-capitalize'>
                                {stateAuth?.data?.name}
                            </span>

                            <div className='profile-img'>
                                {stateAuth?.data?.profilePhoto !== "" ?
                                    <img src={`${REACT_APP}/uploads/profilePhotos/${stateAuth?.data?.profilePhoto}`} />
                                    : <img src={require('../App_Details_theme/images/profile-img.png')} />
                                }
                            </div>

                        </Popover>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Navbar