import { MANAGEPROFILE,UPDATEPROFILE, UPDATEPROFILEIMAGE } from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const manageReducer = (state = defaultState, action) => {
    switch (action.type) {
      case MANAGEPROFILE: {
        return {
          data: {
            ...action.payload
          },
          type: MANAGEPROFILE
        };
      }
      case UPDATEPROFILE: {
        return {
          data: {
            ...action.payload
          },
          type: UPDATEPROFILE
        };
      }
      case UPDATEPROFILEIMAGE: {
        return {
          data: {
            ...action.payload
          },
          type: UPDATEPROFILEIMAGE
        };
      }
     
      default:
        return defaultState;
    }
  };
  