import { USERORDER,USERCANCELORDER,USERCTRACKORDER } from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const usersOrderReducer = (state = defaultState, action) => {
    switch (action.type) {
      case USERORDER: {
        return {
          data: {
            ...action.payload
          },
          type: USERORDER
        };
      }
      case USERCANCELORDER: {
        return {
          data: {
            ...action.payload
          },
          type: USERCANCELORDER
        };
      }
      case USERCTRACKORDER: {
        return {
          data: {
            ...action.payload
          },
          type: USERCTRACKORDER
        };
      }
     
      default:
        return defaultState;
    }
  };
  