import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Empty, Skeleton } from 'antd';
import homeScroll from '../js/homeScroll';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const HomeCategory = () => {
    const [loading, setLoading] = useState(true)

    const [allDataCategory, setallDataCategory] = useState([])

    const [pageLoading, setPageLoading] = useState(false)
    let [pageNumber, setPageNumber] = useState(1)
    const [lastPage, setLastPage] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const getAllCategory = () => {
        if (pageNumber === 1) {
            setLoading(true)
        } else {
            setPageLoading(true)
        }
        let payload = {
            page: pageNumber,
            limit: 13,
            search: '',
            status: '0'
        }
        axios.post(REACT_APP_SOCKET + '/category/category_getAll', payload).then((response) => {
            if (response.data.success) {
                if (pageNumber === 1) {
                    setallDataCategory(response.data.data.items)
                } else {
                    setallDataCategory([...allDataCategory, ...response.data.data.items])
                }
                setLastPage(response.data.data.lastPage)
                setTotalPages(response.data.data.totalPages)
            } else {
                if (pageNumber === 1) {
                    setallDataCategory([])
                    setTotalPages(0)
                } else {
                    setallDataCategory([...allDataCategory])
                    setTotalPages(totalPages)
                }
                setLastPage(true)
            }

            setPageLoading(false)
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllCategory()
    }, [pageNumber])

    useEffect(() => {
        homeScroll()
    }, [allDataCategory])

    const handleScroll = (element) => {
        if ((element?.scrollHeight - element?.clientHeight) !== element?.scrollTop && (element?.scrollHeight - element?.clientHeight - 10) > element?.scrollTop) {
        } else {
            if (lastPage === false && pageNumber < totalPages && pageLoading === false) {
                pageNumber = pageNumber + 1
                setPageNumber(pageNumber)
            }
        }
    }
    
    // const handleScroll = (element) => {
    //     if ((element?.scrollTop + element?.clientHeight) !== element?.scrollHeight) {
    //     } else {
    //         if (lastPage === false && pageNumber < totalPages && pageLoading === false) {
    //             pageNumber = pageNumber + 1
    //             setPageNumber(pageNumber)
    //         }
    //     }
    // }

    return (
        <div id="home-page" onScroll={(e) => handleScroll(e.target)} >

            {!loading && allDataCategory.length === 0
                ?

                // Empty No Data 
                <div className='d-flex flex-column h-100 justify-content-center'>
                    <Empty description="No Data " image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
                :

                //Main data Content
                <div className='category-body'>
                    {
                        loading ?
                            Array.from({ length: 6 }).map((_, index) => (
                                <Link key={index} to={'#'} >
                                    <div className='category-card bg-white'>
                                        <div className='img-card'>
                                            <Skeleton.Node active />
                                        </div>
                                        <div className='img-title mt-1'>
                                            <Skeleton active title={false} />
                                        </div>
                                    </div>
                                </Link>
                            ))
                            :
                            <>
                                {
                                    allDataCategory.map((item, index) => {
                                        return <Link key={index} to={item._id} >
                                            <div className='category-card'>
                                                <div className='img-card'>
                                                    <img src={`${REACT_APP_SOCKET}/uploads/category/${item.image}`} alt="" />
                                                </div>
                                                <div className='img-title'>
                                                    <span className='text-capitalize'>
                                                        {item.name}
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    })
                                }
                                {
                                    pageLoading && Array.from({ length: 2 }).map((_, index) => (
                                        <Link key={index} to={'#'} >
                                            <div className='category-card bg-white'>
                                                <div className='img-card'>
                                                    <Skeleton.Node active />
                                                </div>
                                                <div className='img-title mt-1'>
                                                    <Skeleton active title={false} />
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </>
                    }

                </div>
            }

        </div>
    )
}

export default HomeCategory