import { Image, Table } from 'antd';
import Refresh from '../../App_Details_theme/Common_Components/Refresh';
import React, { Fragment, useEffect, useState } from 'react'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'
import { Helmet } from 'react-helmet';
import User from '../../Users/User';
import { useDispatch, useSelector } from 'react-redux';
import { DISPATCHEDORDERS } from '../../Redux/ActionTypes/actionTypes';
import { dispatchOrders } from '../../Redux/Action/oredrsGetAll';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { downloadSummary } from '../../App_Details_theme/functions/DownloadSummary';

const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const DispatchOrders = () => {

  let dispatch = useDispatch()
  const [orderData, setOrderData] = useState([]);
  let order = useSelector((state) => state.ordersReducer)

  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState('')
  const [currentPage, setCurrentPage] = useState(1);

  const handleRefreshClick = () => {
    setIsFetching(true)
    setLoading(true)
    OrderGetAll()
  }

  const OrderGetAll = () => {

    let payload = {
      page: currentPage,
      limit: pageSize,
      "search": "",
      "status": 5
    }
    dispatch(dispatchOrders(payload, setLoading, setSearchLoading, setIsFetching))
  }

  useEffect(() => {
    OrderGetAll()
  }, [currentPage, pageSize])

  useEffect(() => {
    if (order.type === DISPATCHEDORDERS) {
      if (order.data.success && order.data.data?.length !== 0) {
        setOrderData(order.data.data.items)
        setTotalItems(order.data.data.totalItems)
      }
      else {
        setOrderData([])
      }
    }
  }, [order])

  const getAllSumeryData = (record, e) => {
    const payload = {
      userId: record.userId,
      orderId: record.orderId
    }
    axios.post(`${REACT_APP_SOCKET}/order/order_summery`, payload).then(res => {
      if (res.data.success) {
        const body = res.data.data?.at(0).data.flatMap(x => {
          return x.orderdetails.productDetails.flatMap(z => {
            return [
              [
                x.product_data.productName,
                x.orderdetails.measurement_data.find(w => w._id === z.measurementId).label,
                z.data.map(d => Object.values(d).at(0).at(0)).join(' '),
                z.qty,
                ''
              ]
            ];
          });
        });
        body.map((x, i) => x.unshift(i + 1))
          downloadSummary({
            orderDetails: res.data.data?.at(0).ordertrackings_data.at(0).trackingDetails.find(x => x.status === 1),
            orderNo: res.data.data.at(0).orderNo,
            authorData: res.data.authorData,
            userData: res.data.data.at(0).user_data.at(0),
            head: [['No.', 'Product', 'Measure', 'Color/Size', 'Qty', '\t\t']],
            body: body
          },e)
      } else {

      }
    })
  }
  
  const columns = [
    {
      title: (<div>Order No</div>),
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (orderNo, record) => {
        return (
          <>
            <span>{orderNo || "-"}</span>
          </>
        )
      },
      align: 'center'
    },

    {
      title: (<div>User Name</div>),
      dataIndex: 'user',
      key: 'user',
      render: (user, record) => {
        return (
          <>
            <span className='text-capitalize'>{user || "-"}</span>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<div>Courier Name</div>),
      dataIndex: 'courierName',
      key: 'courierName',
      render: (courierName, record) => {
        return (
          <>
            <span className='text-capitalize'>{courierName || "-"}</span>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<span>Image</span>),
      dataIndex: 'transformReceipt',
      width: "100px",
      key: 'transformReceipt',
      render: (transformReceipt) => (
        <span>
          <Image width={35} height={35} src={`${REACT_APP_SOCKET}/uploads/transformReceipt/${transformReceipt}`} className='image-pdf-logo' />
        </span>
      ),
      align: 'center'
    },
    {
      title: (<div>City</div>),
      dataIndex: 'city',
      key: 'city',
      render: (city, record) => {
        return (
          <>
            <span className='text-capitalize'>{city || "-"}</span>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<div>State</div>),
      dataIndex: 'state',
      key: 'state',
      render: (state, record) => {
        return (
          <>
            <span className='text-capitalize'>{state || "-"}</span>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<div>Country</div>),
      dataIndex: 'country',
      key: 'country',
      render: (country, record) => {
        return (
          <>
            <span className='text-capitalize'>{country || "-"}</span>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<div>Track</div>),
      dataIndex: 'track',
      key: 'track',
      render: (track, record) => {
        return (
          <>
            <Link
              to={{
                pathname: `${record.orderNo}`,
                search: `?${record.orderId}`,
              }}
            >
              Track
            </Link>
          </>
        )
      },
      align: 'center'
    },
    {
      title: (<div>Summary</div>),
      dataIndex: 'summary',
      key: 'summary',
      render: (summary, record) => {
        return (
          <div className='d-flex justify-content-center status-btn'>
          <div className='orange-user d-flex pointer' onClick={() => getAllSumeryData(record, "view")}>
            <div className='orange-text-user text-capitalize'>View</div>
          </div>
          <div className='green-user d-flex pointer' onClick={() => getAllSumeryData(record, "download")}>
            <div className='green-text-user text-capitalize'>Download</div>
          </div>
        </div>
        )
      },
      align: 'center'
    },
  ];

  const formFinish = () => {

  }

  useEffect(() => {
    User()
  }, [orderData])

  return (
    <Fragment>
      <Helmet>
        <title>Dispatch Orders</title>
      </Helmet>
      <MainContentComponent>
        <div className="project-records project-record-page user-page position-relative" id="Orders-Approvel">
          <div className="header">
            <div className="row">
              <div className="col-lg-6 col-md-4 left">
                <h2 className='commonTableTitle'>Dispatch Orders</h2>
              </div>
              <div className="col-lg-6 col-md-8 right d-lg-flex d-md-flex justify-content-end">
              </div>
            </div>
          </div>
          <div className="body">
            <div className='text-center mb-4'>
            </div>

            <div className='my-pagination tableSetCss my-table-sckeleton'>
              <Table
                size="small"
                dataSource={orderData}
                columns={columns}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalItems,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onChange: (page) => setCurrentPage(page),
                  onShowSizeChange: (current, size) => setPageSize(size),
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                // components={customComponents}
                scroll={{
                  x: null,
                  y: 250
                }}
              />
            </div>
          </div>
          <div className='refresh-button-users'>
            <Refresh
              isFetching={isFetching} handleRefreshClick={handleRefreshClick}
            />
          </div>
        </div>
      </MainContentComponent>
    </Fragment>
  )
}

export default DispatchOrders;