import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { categoryGetAll } from '../../Redux/Action/userCategoryGetAll';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const DetailMenuHover = ({ setDetailMenuDisplay }) => {
    const dispatch = useDispatch()
    const allDataCategory = useSelector(state => state.userCategoryReducer)
    const [dataCategory, setDataCategory] = useState([])
    const [selectedItem, setSelectedItem] = useState('')
    const handleClickItem = (item) => {
        setSelectedItem(item._id)
        const payload = {
            page: 1,
            limit: 100000,
            search: '',
            status: '0',
            categoryId: item._id
        }
        axios.post(`${REACT_APP_SOCKET}/subCategory/subCategory_getAll`, payload).then(res => {
            if (res.data.success) {
                setDataCategory(res.data.data.items)
            } else {
                setDataCategory([])
            }
        })
    }

    const getAllCategory = () => {
        let payload = {
            page: 1,
            limit: 100000,
            search: '',
            status: '0'
        }
        dispatch(categoryGetAll(payload))
    }

    useEffect(() => {
        getAllCategory()
    }, [])
    return (
        <div className={`details-menu-hover`} onMouseEnter={() => setDetailMenuDisplay(true)} onMouseLeave={() => setDetailMenuDisplay(false)}>
            <div className='details-menu-hover-left'>
                {
                    allDataCategory.data?.map((item, index) => {
                        // index === 0 ? handleClickItem(item) : null
                        return <div key={index} className={`items ${selectedItem === item._id ? 'active' : ''}`} onMouseEnter={() => handleClickItem(item)}>
                            <span className='text-capitalize'>
                                {item.name}
                            </span>
                            {dataCategory.length !== 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" className='svg-style' width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1522 10.0002L6.90806 4.75601C6.75626 4.59884 6.67227 4.38834 6.67417 4.16984C6.67607 3.95134 6.76371 3.74233 6.91821 3.58783C7.07272 3.43332 7.28173 3.34568 7.50023 3.34378C7.71872 3.34188 7.92923 3.42588 8.08639 3.57768L13.9197 9.41101C13.9972 9.48833 14.0586 9.58017 14.1006 9.68127C14.1425 9.78236 14.1641 9.89073 14.1641 10.0002C14.1641 10.1096 14.1425 10.218 14.1006 10.3191C14.0586 10.4202 13.9972 10.512 13.9197 10.5893L8.08639 16.4227C7.92923 16.5745 7.71872 16.6585 7.50023 16.6566C7.28173 16.6547 7.07272 16.567 6.91821 16.4125C6.76371 16.258 6.67607 16.049 6.67417 15.8305C6.67227 15.612 6.75626 15.4015 6.90806 15.2443L12.1522 10.0002Z" fill="#333" />
                                </svg> : <span> </span>
                            }
                        </div>
                    })
                }
            </div>

            <div className='details-menu-hover-right'>
                <div className='details'>
                    <div className='title text-capitalize'>
                        {dataCategory?.at(0)?.category_data[0].name ?? ''}
                    </div>
                    <div className='content'>
                        {
                            dataCategory.map((item, index) => {
                                return <div key={index} className='items text-capitalize'>
                                    <Link onClick={() => setDetailMenuDisplay(false)} to={`home/${item.categoryId}/${item._id}`}>
                                        {item.name}
                                    </Link>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailMenuHover