import './LoginHistory.css';
import React, { Fragment, useState } from 'react'
import { DatePicker, Select, Skeleton, Space, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Refresh from '../App_Details_theme/Common_Components/Refresh';
import { userStatusAll, userStatusChangeAll, userTypeChange } from '../Redux/Action/userStatus';
import { GETROLE, LOGINHISTORY, USERSTATUSALL, USERSTATUSCHANGEALL, USERTYPECHANGE } from '../Redux/ActionTypes/actionTypes';
import Swal from 'sweetalert2';
import { getRole } from '../Redux/Action/roleGetAll';
import MainContentComponent from '../App_Details_theme/components/MainContentComponent';
import User from '../Users/User';
import { loginHistoryGetAll } from '../Redux/Action/loginHistory';
import dayjs from 'dayjs';
import findSelectRangePresest from '../App_Details_theme/functions/findSelectRangePresest';
import FindSelectedRangeDate from '../App_Details_theme/functions/findSelectedRangeDate';
import moment from 'moment';
const { RangePicker } = DatePicker;

const LoginHistory = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [loginData, setLoginData] = useState([]);
    const [userData, setUsersData] = useState([]);
    const [totalItems, setTotalItems] = useState('');
    const [userId, setUserId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    let [searchValue, setSearchValue] = useState("")
    const [searchLoading, setSearchLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const dateFormatOne = 'YYYY-MM-DD';

    let History = useSelector((state) => state.loginHistoryReducer)
    let userStatus = useSelector((state) => state.userStatusReducer)
    let [apiObj, setApiObj] = useState({
        "startDate": moment().format(dateFormatOne), "endDate": moment().format(dateFormatOne)
    })

    const UserStatus = () => {
        let apiObj1 = {
            page: 1,
            limit: 10000000,
            search: "",
            type: "",
            status: ""
        }
        dispatch(userStatusAll(apiObj1, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        UserStatus()
    }, [])

    useEffect(() => {
        if (History.type === LOGINHISTORY) {
            if (History.data.success && History.data.data?.length !== 0) {
                setLoginData(History.data.data.items)
                setTotalItems(History.data.data.totalItems)
            }
            else {
                setLoginData([])
            }
        }
    }, [History])

    useEffect(() => {
        if (userStatus.type === USERSTATUSALL) {
            if (userStatus.data.success && userStatus.data.data?.length !== 0) {
                setUsersData(userStatus.data.data.items)
            }
            else {
                setUsersData([])
            }
        }
    }, [userStatus])

    useEffect(() => {
        HistoryGetAll()
    }, [currentPage, pageSize, searchValue, userId, apiObj])

    const HistoryGetAll = () => {
        let payload = {
            startDate: apiObj.startDate,
            endDate: apiObj.endDate,
            page: currentPage,
            limit: pageSize,
            search: searchValue,
            user: userId
        }
        dispatch(loginHistoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    const handleUserChange = (e) => {
        setUserId(e === undefined ? "" : e)
    }

    const [selectedPreset, setSelectedPreset] = useState(FindSelectedRangeDate([dayjs(apiObj.startDate), dayjs(apiObj.endDate)]));

    const onPopupChangeRangePicker = () => {
        const liList = document.querySelectorAll(`.range-picker-custom-realtime .ant-picker-presets>ul>li`);
        liList?.forEach((x) => {
            if (x.innerHTML === selectedPreset) {
                x.classList.add("selectedPreset")
            } else {
                x.classList.remove("selectedPreset")
            }
        })
    }
    const dateChange = (event) => {
        if (event !== null && event !== "null") {
            apiObj.startDate = moment(event[0].$d).format(dateFormatOne)
            apiObj.endDate = moment(event[1].$d).format(dateFormatOne)
        } else {
            apiObj.startDate = moment().format(dateFormatOne)
            apiObj.endDate = moment().format(dateFormatOne)
        }
        if (event) {
            FindSelectedRangeDate(event, setSelectedPreset)
        } else {
            setSelectedPreset('Today')
        }
        setApiObj({ ...apiObj })
    }


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setCurrentPage(currentPage);
        setPageSize(size);
    };


    const handleRefreshClick = () => {
        setIsFetching(true)
        // setLoading(true)
        HistoryGetAll()
    };

    const debouncedSearchFilter = debounce((e) => {
        setSearchValue(e)
        setCurrentPage(1)
        setSearchLoading(true)
    }, 800)
    const searchFilter = (e) => {
        debouncedSearchFilter(e);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name) => (
                <span className='text-capitalize'>
                    {name || '-'}
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email) => (
                <span>
                    {email || '-'}
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            render: (Date) => (
                <span>
                    {moment(Date).format('DD MMM YYYY h:mm:ss A')}
                </span>
            ),
        }
    ];

    const skeletonItems = Array.from({ length: pageSize }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };


    useEffect(() => {
        User()
    }, [loginData])

    return (
        <Fragment>
            <MainContentComponent>
                <div className="project-records project-record-page user-page position-relative" id="login-History">
                    <div className="header">
                        <div className="row">
                            <div className="col-lg-6 col-md-4 left">
                                <h2 className='commonTableTitle'>Login History</h2>
                            </div>
                            <div className="col-lg-6 col-md-8 right d-lg-flex d-md-flex justify-content-end">

                                <div className="form-group status">
                                    <label htmlFor="status" className='commonLabel'>Date</label>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        className="height-set-Description"
                                    >
                                        <RangePicker
                                            presets={findSelectRangePresest()}
                                            popupClassName="range-picker-custom-realtime"
                                            // defaultValue={[dayjs().add(-6, 'd'), dayjs(), dateFormat]}
                                            value={[dayjs(apiObj.startDate), dayjs(apiObj.endDate)]}
                                            className='my-range'
                                            format="DD-MM-YYYY"
                                            onOpenChange={onPopupChangeRangePicker}
                                            onChange={dateChange}
                                        />
                                    </Space>
                                </div>

                                <div className="form-group platform">
                                    <label htmlFor="status" className='commonLabel'>All User</label>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        className="height-set-Description"
                                    >
                                        <Select
                                            allowClear
                                            className="pending-select"
                                            value={userId}
                                            onChange={(e) => handleUserChange(e, "status")}
                                            style={{
                                                width: "100%",
                                            }}

                                        >
                                            <Select.Option value="">All</Select.Option>
                                            {userData?.map((obj, index) => {
                                                return (
                                                    <Select.Option key={index} value={obj._id} >
                                                        {obj.name}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Space>
                                </div>

                                <div className="form-group ">
                                    <label htmlFor="status" className="commonLabel">Search</label>
                                    <Search
                                        allowClear
                                        onSearch={(value, event, { source }) => {
                                            if (source === 'input') {
                                                searchFilter(value);
                                            }
                                        }}
                                        placeholder="Search"
                                        onChange={(e) => searchFilter(e.target.value)}
                                        className='my-search-input mySearch height-set-Description'
                                        loading={searchLoading}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <div className='text-center mb-4'>
                        </div>

                        <div className='my-pagination tableSetCss my-table-sckeleton'>
                            <Table
                                size="small"
                                dataSource={loginData}
                                columns={columns}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    onChange: handlePageChange,
                                    showSizeChanger: true,
                                    onShowSizeChange: handlePageSizeChange,
                                }}
                                components={customComponents}
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                    </div>
                    <div className='refresh-button-users'>
                        <Refresh
                            isFetching={isFetching} handleRefreshClick={handleRefreshClick}
                        />
                    </div>
                </div>
            </MainContentComponent>
        </Fragment >
    )
}

export default LoginHistory