import { Link, useNavigate } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import Cookies from 'universal-cookie'
import CryptoJS from 'crypto-js'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/es/form/Form'
import { loginAction, loginOtpAction } from '../../Redux/Action/loginAction'
import { DUMMY, LOGIN, LOGINOTP } from '../../Redux/ActionTypes/actionTypes'
import { AuthContext } from '../../../App'
import './LoginForm.css';

const REACT_APP_TOKENENCRYPT = process.env.REACT_APP_TOKENENCRYPT
const REACT_APP_COOKIE = process.env.REACT_APP_COOKIE
const LoginForm = () => {
	const context = useContext(AuthContext)
	const cookies = new Cookies();
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const myOtpRef = useRef(null)
	const [loginFormAll, setLoginFormAll] = useState({
		email: '',
		password: '',
		rememberMe: false,
	})
	const [loading, setLoading] = useState(false);
	const [otpVerificationStep, setOtpVerificationStep] = useState(false);
	const [emailAllData, setEmailAllData] = useState()
	const [form] = useForm();

	const loginRedux = useSelector((state) => state.authReducer)
	const login = useSelector((state) => state?.authReducer?.data?.data?.twoFA)
	const onFinish = (value) => {
		if (!otpVerificationStep) {
			setEmailAllData(value)
			dispatch(loginAction(value, setLoading, navigate))
		}
		else {
			dispatch(loginOtpAction({ otp: value.otp, email: value.email }, setLoading))
		}
	}

	useEffect(() => {
		if (loginRedux.type === LOGIN && loginRedux.data.success === true) {
			if (loginRedux.data.twoFA === 1) {
				afterLoginHandle(loginRedux.data)
				dispatch({
					type: DUMMY,
					payload: {},
				});
			}
			else {
				setOtpVerificationStep(true)
				dispatch({
					type: DUMMY,
					payload: {},
				});
			}
		}
		if (loginRedux.type === LOGINOTP && loginRedux.data.success) {
			setOtpVerificationStep(false)
			afterLoginHandle(loginRedux.data)
			dispatch({
				type: DUMMY,
				payload: {},
			});
		}
	}, [loginRedux])

	const afterLoginHandle = (data) => {
		let token = JSON.stringify(data.token);
		var ciphertext = CryptoJS.AES.encrypt(token, REACT_APP_TOKENENCRYPT).toString();
		cookies.set(REACT_APP_COOKIE, ciphertext);
		context.setIsAuthenticated(true)
		console.log(data, "dataSet3")
		navigate("/")
	}

	const handleChanges = () => {

	}
	const handleCheckboxChange = () => {

	}
	return (
		<div id="login-page">
			<Helmet>
				<title>Login Form</title>
			</Helmet>
			<main>
				<div className="row gx-0">
					<div className="col-lg-6 col-md-6 d-flex align-items-center">
						<div className="container">
							<div className="content w-100">
								<div className="logo d-flex justify-content-center">
									<img src={require('../../..//Components/App_Details_theme/images/main/ntm-full-logo.png')} alt="Logo" className="img-fluid" />
								</div>
								<h2>Welcome to Narayan Nero Fab 👋</h2>
								<p>Please Enter the login details</p>
								<Form
									name="basic"
									form={form}
									initialValues={{
										remember: true,
									}}
									onFinish={onFinish}
									autoComplete="off"
								>
									<label htmlFor="email" className='height-set-Description'>Email<span className="required">*</span></label>
									<Form.Item
										name="email"
										rules={[
											{
												required: true,
												message: 'Please Enter Your Email!',
											},
										]}
										className='height-set-Description ant-input-remove-marg'
									>
										<Input
											type="email"
											id="email"
											size="large"
											placeholder="Enter Your Email"
											value={loginFormAll?.email}
											onChange={handleChanges}
											disabled={otpVerificationStep}
										/>
									</Form.Item>
									{!otpVerificationStep ?
										<Fragment>
											<label htmlFor="password" className='height-set-Description'>Password<span className="required">*</span></label>
											<Form.Item
												name="password"
												rules={[
													{
														required: true,
														message: 'Please Enter Your Password!',
													},
												]}
												className='height-set-Description ant-input-remove-marg'
											>
												<Input.Password
													placeholder="Enter Your Password"
													id="password"
													type='password'
													size="large"
													value={loginFormAll?.password}
													onChange={handleChanges}
												/>
											</Form.Item>
											<div className="rpassword">
												<Link to="/forget-password" className="forgot">Forgot your password?</Link>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="checkbox"
													value={loginFormAll.rememberMe}
													id="flexCheckDefault"
													onChange={handleCheckboxChange}
												/>
												<label className="form-check-label" htmlFor="flexCheckDefault">
													Remember Information
												</label>
											</div>
											<Button type="primary" htmlType="submit" loading={loading} className="btn login-btn">Log in</Button>
										</Fragment>
										:
										<Fragment>
											<div className="otp d-flex">
												<div className="form-group ant-input-remove-marg">
													<label htmlFor="otp">OTP<span className="required">*</span></label>
													<Form.Item
														name="otp"
														rules={[
															{
																required: true,
																message: 'Enter OTP!',
															},
														]}
													// className='height-set'
													>
														<Input type="text" id="otp" name="otp" className="form-control" placeholder="Enter OTP" ref={myOtpRef} />
													</Form.Item>
												</div>
												<div className="form-group d-flex align-items-end height-otpbtn">
													<Button
														type="primary"
														htmlType="submit"
														className="btn"
														loading={loading}
													>
														Verify
													</Button>
												</div>
											</div>
										</Fragment>
									}
									<div className="or">
										<p>Or Sign Up</p>
									</div>

									{/* 
									<div onClick={handleClick} className="google cursor-pointer">
										<img src={require('../../..//Components/App_Details_theme/images/google.png')} alt="google" />
										Sign in with Google
									</div> */}

									<div className="not text-center">
										<p>Not a member? <Link to="/register">Signup Now</Link></p>
									</div>
								</Form>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6">
						{/* eslint-disable-next-line */}
						<img src={require('../../..//Components/App_Details_theme/images/login-img.png')} alt="Login-Image" className="img-fluid main-img" />
					</div>
				</div>
			</main >
		</div >
	)
}

export default LoginForm
