import axios from "axios";
import { DELETEMEASUREMENT, MEASUREMENT, MEASUREMENTSTATUS, POSTMEASUREMENT, UPDATEMEASUREMENT } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const getMeasurement = (apiObj, setLoading, setSearchLoading) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/measurement/measurement_getAll', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MEASUREMENT,
                        payload: response?.data,
                    });
                }
                if(setLoading){
                    setLoading(false)
                }
                if(setSearchLoading){
                    setSearchLoading(false)
                }
                
            })
        }
        catch (error) {
            if(setLoading){
                setLoading(false)
            }
            if(setSearchLoading){
                setSearchLoading(false)
            }
            CustomMessage('error', error.message)
        }
    }
}

export const measurementCreate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/measurement/measurement_post', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: POSTMEASUREMENT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const measurementDelete = (id) => {
    return (dispatch) => {
        try {
            axios.delete(REACT_APP_SOCKET + '/measurement/measurement_delete/' + id).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DELETEMEASUREMENT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const measurementUpdate = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/measurement/measurement_update', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: UPDATEMEASUREMENT,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)

                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const measurementStatus = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/measurement/measurement_status', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MEASUREMENTSTATUS,
                        payload: response?.data,
                    });
                    CustomMessage('success', response.data.message)
                } else {
                    CustomMessage('error', response.data.message)
                }

            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}
