import { DELETEMEASUREMENT, MEASUREMENT, MEASUREMENTSTATUS, POSTMEASUREMENT, UPDATEMEASUREMENT } from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const measurementReducer = (state = defaultState, action) => {
    switch (action.type) {
      case MEASUREMENT: {
        return {
          data: {
            ...action.payload
          },
          type: MEASUREMENT
        };
      }
      case POSTMEASUREMENT: {
        return {
          data: {
            ...action.payload
          },
          type: POSTMEASUREMENT
        };
      }
      case UPDATEMEASUREMENT: {
        return {
          data: {
            ...action.payload
          },
          type: UPDATEMEASUREMENT
        };
      }
      case DELETEMEASUREMENT: {
        return {
          data: {
            ...action.payload
          },
          type: DELETEMEASUREMENT
        };
      }
      case MEASUREMENTSTATUS: {
        return {
          data: {
            ...action.payload
          },
          type: MEASUREMENTSTATUS
        };
      }
     
      default:
        return defaultState;
    }
  };
  