import React, { Fragment, useRef, useState } from 'react'
import { Button, FloatButton, Form, Image, Input, Modal, Select, Skeleton, Switch, Table, Tooltip } from 'antd';
import * as AntIcons from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Refresh from '../../App_Details_theme/Common_Components/Refresh';
import '../categories.css';
import Submitbutton from '../../App_Details_theme/Common_Components/Submitbutton';
import { categoryCreate, categoryDelete, categoryGetAll, categoryStatus, categoryUpdate, subCategoryCreate, subCategoryDelete, subCategoryGetAll, subCategoryStatus, subCategoryUpdate } from '../../Redux/Action/categoryGetAll';
import { useEffect } from 'react';
import { CATEGORY, DELETESUBCATEGORY, MEASUREMENT, POSTSUBCATEGORY, SUBCATEGORY, SUBCATEGORYSTATUS, UPDATESUBCATEGORY } from '../../Redux/ActionTypes/actionTypes';
import ActionMenu from '../../App_Details_theme/Common_Components/ActionMenu';
import moment from 'moment';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import { getMeasurement } from '../../Redux/Action/measurementAction';
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent';
import Cate from '../Cate';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const SubCategory = () => {
    const formRef = useRef();
    const [form] = Form.useForm()
    let dispatch = useDispatch()
    const fileInputRef = useRef();

    const [defultValue, setDefultValue] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [categoryData, setCategoryData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])
    const [totalItems, setTotalItems] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [id, setId] = useState();
    const [uploadFiles, setUploadFiles] = useState([])
    const [image, setImage] = useState(null)
    const [searchDataAll, setSearchDataAll] = useState('');
    const [selectedStatusData, setSelectedStatusData] = useState('0');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [categoryId, setCategoryId] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");

    let category = useSelector((state) => state.categoryReducer)
    let measurement = useSelector((state) => state.measurementReducer)
    const [measurementData, setMeasurementData] = useState([])
    const [isModelOpen, setIsModelOpen] = useState(false)
    const [valued, setValue] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleRefreshClick = () => {
        if (!isFetching) {
            setIsFetching(true)
            setLoading(true)
            SubCategoryGetAll()
        }
    }

    const showModal = () => {
        setIsModelOpen(true);
    };

    const handleOk = () => {
        setIsModelOpen(false);
    }

    useEffect(() => {
        SubCategoryGetAll()
    }, [currentPage, pageSize, selectedStatusData, searchDataAll, categoryFilter])

    const SubCategoryGetAll = () => {
        let payload = {
            page: currentPage,
            limit: pageSize,
            search: searchDataAll,
            status: selectedStatusData,
            categoryId: categoryFilter
        }
        dispatch(subCategoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }


    const Measure = () => {
        let payload = {
            page: 1,
            limit: 1000000,
            search: "",
            status: 0
        }
        dispatch(getMeasurement(payload, setLoading, setSearchLoading, setIsFetching))
    }

    const CategoryGetAll = () => {
        let payload = {
            page: 1,
            limit: 1000000,
            search: "",
            status: 0
        }
        dispatch(categoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        CategoryGetAll()
        Measure()
    }, [])



    const handleCategoryChange = (e) => {
        setCategoryId(e)
    }

    useEffect(() => {
        if (category.type === SUBCATEGORY) {
            if (category.data.success && category.data.data?.length !== 0) {
                setSubCategoryData(category.data.data.items)
                setTotalItems(category.data.data.totalItems)
               
            }
            else {
                setSubCategoryData([])
            }
        }

        if (category.type === CATEGORY) {
            if (category.data.success && category.data.data?.length !== 0) {
                setCategoryData(category.data.data.items)
            }
            else {
                setCategoryData([])
            }
        }

        if (category.type === POSTSUBCATEGORY) {
            if (category.data.success) {
                SubCategoryGetAll()
                form.resetFields()
                setImage("")
                setImageUrl("")
                setSelectedRowKeys([])
                Measure()
                setValue([])
            }
        }
        if (category.type === UPDATESUBCATEGORY) {
            if (category.data.success) {
                SubCategoryGetAll()
                form.resetFields()
                setImage("")
                setImageUrl("")
                setDefultValue(false)
                setSelectedRowKeys([])
                Measure()
                setValue([])
            }
        }
        if (category.type === DELETESUBCATEGORY) {
            if (category.data.success) {
                SubCategoryGetAll()
                form.resetFields()
                setImage("")
                setImageUrl("")
                setSelectedRowKeys([])
                Measure()
                setDefultValue(false)
            }
        }
        if (category.type === SUBCATEGORYSTATUS) {
            if (category.data.success) {
                SubCategoryGetAll()
                form.resetFields()
            }
        }
    }, [category])


    useEffect(() => {
        if (measurement.type === MEASUREMENT) {
            if (measurement.data.success && measurement.data.data?.length !== 0) {
                let newArray = []
                newArray = measurement.data.data.items.map((key) => {
                    key.measurementType = 0
                    key.measurementStatus = 0
                    key.measuremenLabel = key.label
                    key.measurementdId = key._id

                    return {
                        measurementType: 0,
                        measurementStatus: 0,
                        measuremenLabel: key.label,
                        measurementId: key._id
                    };
                })
                setMeasurementData(newArray)
            }
            else {
                setMeasurementData([])
            }
        }
    }, [measurement])

    const handleOnChange = async (e) => {
        let url = await toBase64(e.target.files[0])
        setImageUrl(url);
        const files = e.target.files;
        setUploadFiles([...files]);
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const handleFinish = (value) => {

        let newArray = []
        valued.forEach((key) => {
            measurementData.forEach((newKey) => {
                if (newKey.measurementId === key.measurementId) {
                    newArray.push(newKey)
                }
            })
        })


        if (defultValue === false) {
            let bodyFormData = new FormData()

            if (uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    bodyFormData.append('image', file);
                });
            }
            let payloadData = new FormData();
            payloadData.append('image', bodyFormData.get('image'));
            payloadData.append('name', value.name);
            payloadData.append('description', value.description || "");
            payloadData.append('categoryId', value.categoryId);
            payloadData.append('measurementArray', JSON.stringify(newArray) || []);

            dispatch(subCategoryCreate(payloadData))

        } else {
            let newArray = []
            measurementData.forEach(newKey => {
                // Check if the measurementId is in the selectedRowKeys array
                if (selectedRowKeys.includes(newKey.measurementId)) {
                    newArray.push({
                        measurementType: newKey.measurementType,
                        status: newKey.measurementStatus,
                        measurementId: newKey.measurementId
                        // Add other properties if needed
                    });
                }
            });
            let payloadDataedit = new FormData();

            if (Array.isArray(uploadFiles) && uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    payloadDataedit.append('image', file);
                });
            }
            else {
                payloadDataedit.append('image', uploadFiles);
            }
            payloadDataedit.append('_id', id);
            payloadDataedit.append('name', value.name);
            payloadDataedit.append('description', value.description || "");
            payloadDataedit.append('categoryId', value.categoryId);
            payloadDataedit.append('measurementArray', JSON.stringify(newArray));

            dispatch(subCategoryUpdate(payloadDataedit))

        }
    }

    const handleUpdate = (row) => {
        if (document.querySelector(".common-component-set")) {
            document.querySelector(".common-component-set").scrollTo({ top: 0, behavior: 'smooth' });
        }
        
        setIsFormValid(true)

        // Edit na data aave che
        let newArray = []
        newArray = row.measurementArray.map((key) => {
            return {
                measurementType: key.measurementType,
                measurementStatus: key.status,
                measuremenLabel: key.measurement_data?.label,
                measurementId: key.measurementId
            };
        })
        setMeasurementData(newArray)

        // selected row mate
        let newArray2 = []
        newArray2 = row.measurementArray.map((key) => {
            return key.measurementId
        })
        setSelectedRowKeys(newArray2)

        // all data mate
        let newArray3 = []
        measurementData.forEach((newKey) => {
            if (newArray2.includes(newKey.measurementId)) {
                newArray3.push(newArray.filter((abc) => abc.measurementId === newKey.measurementId)[0])
            }
            else {
                newArray3.push(newKey)
            }
        })
        setMeasurementData(newArray3)

        form.setFieldValue("name", row.name)
        form.setFieldValue("description", row.description)
        form.setFieldValue("categoryId", row.categoryId)
        setUploadFiles(row.image);
        setImage(row.image);
        setDefultValue(true)
        setId(row._id)
    }

    const handleDelete = (row) => {
        dispatch(subCategoryDelete(row))
    }

    const onFinishFailed = (errorInfo) => {
        setIsFormValid(false);
    };

    const onValuesChange = (_, values) => {
        const allRequiredFieldsFilled = ['name', 'categoryId'].every((key) => {
            if ((key === "categoryId" && values[key]) || (key === "name" && values[key])) {
                return true
            }
            else {
                return false
            }
        }
        );
        setIsFormValid(allRequiredFieldsFilled);
    };

    const handleToggleChange = (_id, newStatus) => {
        let statusmain = null
        if (newStatus === 0) {
            statusmain = 1;
        } else {
            statusmain = 0;
        }
        let payload = {
            _id: _id, status: String(statusmain)
        }
        dispatch(subCategoryStatus(payload))

    }

    const handleChangesStatusAllData = (e) => {
        setCurrentPage(1)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchData = (e) => {
        clearTimeout(typingTimeout);
        setSearchLoading(true);
        setSearchDataAll(e);
        const timeout = setTimeout(async () => {
            setCurrentPage(1);
        }, 800);
        setTypingTimeout(timeout);
    };

    const columns = [
        {
            title: (<div>Category</div>),
            dataIndex: 'category',
            width: '90px',
            key: 'category',
            render: (text, record) => {
                return (
                    <>
                        <span className="groupname text-capitalize">{record?.category_data?.at(0).name ?? '-'}</span>
                    </>
                )
            }
        },
        {
            title: (<span>Name</span>),
            dataIndex: 'name',
            width: "80px",
            key: 'name',
            render: (name) => (
                <span className='text-capitalize'>
                    {name || '-'}
                </span>
            ),
        },
        {
            title: (<span>Image</span>),
            dataIndex: 'image',
            width: "100px",
            key: 'image',
            render: (image) => {
                return (
                    <>
                        {image === null ?
                            <Image width={35} height={35} src={require('../../App_Details_theme/images/Frame.png')} className='image-pdf-logo' />
                            :
                            <span>
                                <Image width={35} height={35} src={`${REACT_APP_SOCKET}/uploads/subCategory/${image}`} className='image-pdf-logo' />
                            </span>
                        }
                    </>
                )
            },
        },
        {
            title: (<span>Description</span>),
            dataIndex: 'description',
            width: "80px",
            key: 'description',
            render: (description) => (
                <span className='text-capitalize'>
                    {description || '-'}
                </span>
            ),
        },

        {
            title: (<span>Measurement</span>),
            width: "350px",
            dataIndex: 'measurement',
            render: (measurement, record) => {
                const { measurementArray } = record;
                const roleTypeData = measurementArray || [];
                const itemsPerLine = 6;
                const rows = [];

                for (let i = 0; i < roleTypeData.length; i += itemsPerLine) {
                    const rowItems = roleTypeData.slice(i, i + itemsPerLine);
                    const rowButtons = rowItems.map((element, index) => (
                        <div key={index} className='d-flex'>
                            {element.measurementType === 0 ?
                                <div className='blue-user d-flex'>
                                    <div className='blue-text-user text-capitalize'>{element.measurement_data.label}</div>
                                </div>
                                :
                                <div className='green-user d-flex'>
                                    <div className='green-text-user text-capitalize'>{element.measurement_data.label}</div>
                                </div>
                            }
                        </div>
                    ));
                    rows.push(<div className='d-flex measurementType mb-1'>{rowButtons}</div>);
                }
                return (
                    <div>{rows}</div>
                );
            },
        },
        {
            title: (<span>Status</span>),
            width: "80px",
            dataIndex: 'status',
            render: (status, record) => {
                return (
                    <Switch
                        size='small'
                        checked={status === 0 ? true : false}
                        onChange={(checked) => handleToggleChange(record._id, checked ? 1 : 0)}
                    />
                )

            },
        },
        {
            title: (<span>Updated Date</span>),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '150px',
            render: (text, record) => {
                const formattedDate = moment(record.updatedAt).format('DD MMM YYYY h:mm:ss A');
                const formattedDate2 = moment(record.registerdate).format('DD MMM YYYY h:mm:ss A');
                return <div className='d-flex justify-content-between align-items-center flex-wrap'><span>
                    <Tooltip title={
                        <>
                            <span><AntIcons.ClockCircleOutlined /> {formattedDate2}</span>
                        </>}>
                        {formattedDate}
                    </Tooltip>
                </span >
                    <ActionMenu data={record}
                        handleUpdate={handleUpdate} confirmDelete={handleDelete}
                    />
                </div>;
            },
        },
    ];

    const onChange = (id, data, index) => {
        let newArray = []
        newArray = measurementData.map((key) => {
            if (key.measurementId === id) {
                if (data === 1) {
                    key.measurementType = 0
                }
                else {
                    key.measurementType = 1
                }
            }
            return key
        })
        setMeasurementData(newArray)
    }

    const onChangeStatus = (id, data, index) => {
        let newArray = []
        newArray = measurementData.map((key) => {
            if (key.measurementId === id) {
                if (data === 1) {
                    key.measurementStatus = 0
                }
                else {
                    key.measurementStatus = 1
                }
            }
            return key
        })
        setMeasurementData(newArray)
    }

    let [columns2, setColumns2] = useState([])
    useEffect(() => {
        setColumns2([
            {
                title: (<div>Name</div>),
                dataIndex: 'measuremenLabel',
                width: "100px",
                render: (measuremenLabel, record) => {
                    return (
                        <div className='text-capitalize'>{measuremenLabel}</div>
                    )
                },
            },
            {
                title: (<div>Option/Measurement</div>),
                dataIndex: 'measurementType',
                width: "150px",
                render: (measurementType, record, e) => {
                    return (
                        <Fragment key={e}>
                            <Switch
                                key={record?.measurementId}
                                size="small"
                                checked={measurementType === 0 ? true : false}
                                onChange={(checked) => onChange(record?.measurementId, checked ? 1 : 0, e)} />
                        </Fragment>
                    )
                },
            },
            {
                title: (<div>Status</div>),
                dataIndex: 'measurementStatus',
                width: "100px",
                render: (measurementStatus, record, e) => {
                    return (
                        <Fragment key={e}>
                            <Switch
                                key={record?.measurementId}
                                size="small"
                                checked={measurementStatus === 0 ? true : false}
                                onChange={(checked) => onChangeStatus(record?.measurementId, checked ? 1 : 0, e)} />
                        </Fragment>
                    )
                },
            }
        ])
    }, [measurementData])

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            const specificData = selectedRows.map(row => {
                return {
                    measurementId: row.measurementId,
                    measurementType: row.measurementType,
                    status: row.measurementStatus,
                    measuremenLabel: row.measuremenLabel,
                }
            });
            setValue(specificData);
        },
        hideSelectAll: true,
    };
   

    const skeletonItems = Array.from({ length: 10 }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };

    useEffect(() => {
        Cate()
    }, [subCategoryData])

    return (
        <>
            <Helmet>
                <title>Sub-Category</title>
            </Helmet>
            <MainContentComponent>
                <div className='main-content-body overflow-y-scroll dynamic-scroll-all' id='Categories'>
                    <div className="project">
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handleFinish}
                            onFinishFailed={onFinishFailed}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            form={form}
                            ref={formRef}
                        >
                            <div className="right-header">
                                <div className="row gx-0">
                                    <div className="col-10 d-flex align-items-start">
                                        <h2 className='commonPageTitle'>{defultValue === true ? "Update Sub Category" : "Create Sub Category"}</h2>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                        <Refresh isFetching={isFetching} handleRefreshClick={handleRefreshClick} />
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="row">

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Category<span className="required">*</span></label>
                                        <Form.Item
                                            htmlFor="sname"
                                            name="categoryId"
                                            className='height-set-Description'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter a Category'
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Category"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                onChange={handleCategoryChange}
                                            >
                                                {
                                                    categoryData?.map((row, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                <Select.Option value={row?._id} className="text-capitalize">{row?.name}</Select.Option>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Name<span className="required">*</span></label>
                                        <Form.Item name="name" key="AppName" className='height-set-Description' htmlFor="sname" rules={[{ required: true, message: 'Enter a Name' }]}>
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups d-flex justify-content-center align-items-center col-12 col-sm-6 col-md-4 col-lg-2 appname custom-height cate-image ant-input-remove-marg">
                                        <div>
                                            <label htmlFor="sname" className='commonLabel'>Image</label>
                                            <Form.Item name="image" key="status">
                                                <Input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Click to Upload"
                                                    id="csvFileInput"
                                                    onChange={handleOnChange}
                                                    ref={fileInputRef}
                                                    accept="image/png, image/jpeg,image/jpg"
                                                />
                                            </Form.Item>
                                        </div>
                                        {
                                            imageUrl ? (
                                                <div className='align-self-end ms-2'>
                                                    <img src={imageUrl} alt="Uploaded Preview" className='app-detail-icon' />
                                                </div>

                                            ) : (
                                                <>
                                                    {image ? (
                                                        <div className='align-self-end ms-2'>
                                                            <img src={`${REACT_APP_SOCKET}/uploads/subCategory/${uploadFiles}`} alt="Google" className='app-detail-icon' />
                                                        </div>
                                                    ) : (
                                                        <div className='align-self-end ms-2'>
                                                            <img src={require('../../App_Details_theme/images/Frame.png')} className='app-detail-icon' alt="" />
                                                        </div>
                                                    )

                                                    }
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-1 user-search ant-input-remove-marg" >
                                        <label htmlFor="pname" className='commonLabel'>Measurement<span className="required">*</span></label>
                                        <div className='height-set-Description d-flex justify-content-center'>
                                            {/* <AntIcons.FullscreenOutlined  style={{ fontSize: '16px'}}onClick={showModal} /> */}
                                            <Button type='primary' onClick={showModal}>Select</Button>
                                        </div>
                                    </div>

                                    <Modal forceRender title="Measurement List" open={isModelOpen}
                                        okText="Apply"
                                        onOk={handleOk}
                                        cancelButtonProps={{ style: { display: 'none' } }}
                                        onCancel={() => setIsModelOpen(false)}>
                                        <Table
                                            rowSelection={{
                                                type: "checkbox",
                                                ...rowSelection,
                                            }}
                                            rowKey={(record) => record?.measurementId}
                                            columns={columns2}
                                            dataSource={measurementData}
                                            size="small"
                                            pagination={false}
                                            scroll={{
                                                x: null,
                                                y: 472
                                            }}
                                        />
                                    </Modal>

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 user-search ant-input-remove-marg" >
                                        <label htmlFor="pname" className='commonLabel'>Description</label>
                                        <Form.Item name="description" key="AppName" className='height-set-Description' htmlFor="sname">
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Description"
                                            />
                                        </Form.Item>
                                    </div>


                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-end button-color custom-height">
                                        <Submitbutton form={form}
                                            isFormValid={isFormValid} loadingUpdate={loadingUpdate} loadingSave={loadingSave} getAllDataPass={defultValue}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>

                    {/* <!-- Project Records --> */}
                    <div className="project-records project-record-page overflow-hidden">
                        <div className="header">
                            <Form>
                                <div className="row">
                                    <div className="col-lg-7 col-md-4 left">
                                        <h2 className='commonTableTitle'>Sub Category</h2>
                                    </div>
                                    <div className="col-lg-5 col-md-8 right d-lg-flex d-md-flex justify-content-end">
                                        <div className="form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Category</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Selected Value"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={categoryFilter}
                                                    onChange={(value) => {
                                                        setCategoryFilter(value)
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    {
                                                        categoryData?.map((row, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <Select.Option value={row?._id} className="text-capitalize">{row?.name}</Select.Option>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Status</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Selected Value"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={selectedStatusData}
                                                    onChange={(value) => {
                                                        setSelectedStatusData(value === undefined ? '0' : value);
                                                        handleChangesStatusAllData(value);
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    <Select.Option value="0">Active</Select.Option>
                                                    <Select.Option value="1">Inactive</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="form-groups ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status">Search</label>
                                            <Search
                                                allowClear
                                                onSearch={(value, event, { source }) => {
                                                    if (source === 'input') {
                                                        handleSearchData(value);
                                                    }
                                                }}
                                                onChange={(e) => handleSearchData(e.target.value)}
                                                placeholder="Search"
                                                value={searchDataAll}
                                                className='search-input-loader mySearch height-set-Description'
                                                loading={searchLoading} />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="body table-body lookUpTableDiv my-pagination tableSetCss my-table-sckeleton">
                            <Table
                                dataSource={subCategoryData}
                                columns={columns}
                                rowKey={(record) => record._id}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    onChange: (page) => setCurrentPage(page),
                                    onShowSizeChange: (current, size) => setPageSize(size),
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }}
                                components={customComponents}
                                className='body-row'
                                size="small"
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                    </div>
                </div>
            </MainContentComponent>
        </>
    )
}

export default SubCategory;
