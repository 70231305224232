import React, { Fragment, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import LoginForm from "../Auth/Login/LoginForm";
import SignUpForm from "../Auth/SignUpForm/SignUpForm";
import Page404 from "../Page404/Page404";
import Home from "../Pages/Home/Home";
import Category from "../Categories/Category/Category";
import AcceptOrders from "../Pages/Orders/AcceptOrders";
import DispatchOrders from "../Pages/Orders/DispatchOrders";
import RejectedOrders from "../Pages/Orders/RejectedOrders";
import PendingOrders from "../Pages/Orders/PendingOrders";
import CancelOrders from "../Pages/Orders/CancelOrders";
import AddProduct from "../Pages/Products/AddProduct";
import ViewProduct from "../Pages/Products/ViewProduct";
import DateToDateReport from "../Pages/Report/DateToDateReport";
import MonthlyReport from "../Pages/Report/MonthlyReport";
import TodayReport from "../Pages/Report/TodayReport";
import WeeklyReport from "../Pages/Report/WeeklyReport";
import YearlyReport from "../Pages/Report/YearlyReport";
import Slider from "../Pages/Slider/Slider";
import SubCategory from "../Categories/Sub-Category/SubCategory";
import ActiveUsers from "../Pages/Users/ActiveUsers";
import BlockedUsers from "../Pages/Users/BlockedUsers";
import UsersRequest from "../Pages/Users/UsersRequest";
import AllUsers from "../Users/AllUsers";
import PendingUsers from "../Users/PendingUsers";
import ApprovedUsers from "../Users/ApprovedUsers";
import RejectedUsers from "../Users/RejectedUsers";
import Measurement from "../Measurement/Measurement";
import ManageProfile from "../Manage-profile/ManageProfile";
import HomePage from "../Client/Home/HomePage";
import RoutingCreate from "./RoutingCrud/RoutingCreate";
import AllCategory from "../Client/Category/AllCategory";
import SingleCategory from "../Client/Category/SingleCategory";
import SingleProduct from "../Client/Category/SingleProduct";
import LoginHistory from "../LoginHistory/LoginHistory";
import Cart from "../Client/Cart/Cart";
import Role from "../Role/Role";
import { useSelector } from "react-redux";
import { AUTHDATA } from "../Redux/ActionTypes/actionTypes";
import { AuthContext } from "../../App";
import Cookies from "universal-cookie";
import Orders from "../Client/Orders/Orders";
import TrackOrder from "../Client/Orders/TrackOrder";
import LoadingBar from "react-top-loading-bar";
import ForgetPassword from "../Auth/ForgetPassword/ForgetPassword";
const REACT_APP_COOKIE = process.env.REACT_APP_COOKIE;
const componentMap = {
  Outlet,
  Home,
  Category,
  AcceptOrders,
  RejectedOrders,
  DispatchOrders,
  PendingOrders,
  CancelOrders,
  TrackOrder,
  AddProduct,
  ViewProduct,
  DateToDateReport,
  MonthlyReport,
  TodayReport,
  WeeklyReport,
  YearlyReport,
  Slider,
  SubCategory,
  ActiveUsers,
  BlockedUsers,
  UsersRequest,
  AllUsers,
  PendingUsers,
  ForgetPassword,
  ApprovedUsers,
  RejectedUsers,
  Measurement,
  ManageProfile,
  HomePage,
  RoutingCreate,
  AllCategory,
  SingleCategory,
  SingleProduct,
  LoginHistory,
  Role,
  Cart,
  Orders,
  TrackOrder,
};

const PublicAndPrivateRoutes = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const pathData = useSelector((state) => state.pathReducer);
  const context = useContext(AuthContext);
  const [array, setArray] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(30);
    if (pathData.type === AUTHDATA) {
      setProgress(60);
      if (pathData.data.routeData && cookies.get(REACT_APP_COOKIE)) {
        setArray(pathData.data.routeData);
        setData(pathData.data.type);
        context.setIsAuthenticated(true);
        setLoading(true);
        setProgress(100);
      } else {
        context.setIsAuthenticated(false);
        setArray([]);
        // navigate("/login");
        setLoading(true);
        setProgress(100);
      }
    }
  }, [pathData]);

  const MakeSingleRoute = (index, MainMenuDataOne) => {
    if (MainMenuDataOne) {
      const Component = componentMap[MainMenuDataOne.Component];
      if (Component) {
        return (
          <Route
            key={index}
            path={MainMenuDataOne.route}
            element={<Component />}
          />
        );
      }
    } else {
      return null;
    }
  };

  const RecursionRoute = (nestedRoutes) => {
    return nestedRoutes.map((nestedData, index) => {
      const Component = componentMap[nestedData.Component];
      if (nestedData.mainMenu && nestedData.mainMenu.length > 0) {
        return (
          <Route key={index} path={nestedData.route} element={<Component />}>
            {RecursionRoute(nestedData.mainMenu)}
          </Route>
        );
      } else {
        return MakeSingleRoute(index, nestedData);
      }
    });
  };

  const RouteHandler = (mainPath) => {
    return (
      <Fragment>
        {mainPath.map((menuArray, mainIndex) => {
          if (!menuArray.mainMenu) {
            return MakeSingleRoute(mainIndex, menuArray);
          } else if (menuArray.mainMenu && menuArray.role === undefined) {
            return RecursionRoute(menuArray.mainMenu);
          } else {
            let NewComponent = componentMap[menuArray.Component];
            return (
              <Route path={menuArray.route} element={<NewComponent />}>
                {RecursionRoute(menuArray.mainMenu)}
              </Route>
            );
          }
        })}
      </Fragment>
    );
  };

  return (
    <div>
      {loading ? (
        <Routes>
          {array.length > 0 ? (
            <Fragment>
              {data === 0 ? (
                <Route path="/" element={<Navigate to={"/add-product"} />} />
              ) : (
                <Route path="/" element={<Navigate to={"/home"} />} />
              )}
              <Route path="/manage-profile" element={<ManageProfile />} />
              <Route path="*" element={<Page404 />} />
              {RouteHandler(array)}
            </Fragment>
          ) : (
            <Fragment>
              <Route path="/" element={<Navigate to={"/login"} />} >
                <Route path=":id" element={<Navigate to={"/register"} />} />
              </Route>
              <Route path="/login" element={<LoginForm />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/register" element={<SignUpForm />} />
              <Route path="*" element={<Page404 />} />
            </Fragment>
          )}
        </Routes>
      ) : (
        <></>
      )}
      <LoadingBar
        color="#3066fb"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
    </div>
  );
};

export default PublicAndPrivateRoutes;
