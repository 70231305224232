import React, { Fragment } from 'react'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'

const TodayReport = () => {
  return (
    <Fragment>
    <MainContentComponent>
      <div>Content</div>
    </MainContentComponent>
  </Fragment>
  )
}

export default TodayReport