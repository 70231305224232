import React, { Fragment, useState } from 'react'
import { Select, Skeleton, Space, Table } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Refresh from '../App_Details_theme/Common_Components/Refresh';
import { userStatusApproved, userStatusChangeApproved, userTypeChange } from '../Redux/Action/userStatus';
import { GETROLE, USERSTATUSAPPROVED, USERSTATUSCHANGEAPPROVED, USERTYPECHANGE } from '../Redux/ActionTypes/actionTypes';
import { getRole } from '../Redux/Action/roleGetAll';
import Swal from 'sweetalert2';
import './userstatus.css'
import MainContentComponent from '../App_Details_theme/components/MainContentComponent';
import User from './User';

const ApprovedUsers = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    const [userData, setUsersData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [totalItems, setTotalItems] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    let [searchValue, setSearchValue] = useState("")
    const [searchLoading, setSearchLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    let [typeValue, setTypeValue] = useState("")

    let userStatus = useSelector((state) => state.userStatusReducer)
    let role = useSelector((state) => state.roleReducer)

    const UserStatus = () => {
        let apiObj = {
            page: currentPage,
            limit: pageSize,
            search: searchValue,
            type: typeValue,
            status: 1
        }
        dispatch(userStatusApproved(apiObj, setLoading, setSearchLoading, setIsFetching))
    }

    const RoleTypeGet = () => {
        let apiObj = {
            page: 1,
            limit: 100000,
            search: "",
            type: "",
            status: ""
        }
        dispatch(getRole(apiObj, setLoading, setSearchLoading))
    }

    useEffect(() => {
        RoleTypeGet()
    }, [])

    useEffect(() => {
        UserStatus()
    }, [currentPage, pageSize, searchValue, typeValue])

    useEffect(() => {
        if (userStatus.type === USERSTATUSAPPROVED) {
            if (userStatus.data.success && userStatus.data.data?.length !== 0) {
                setUsersData(userStatus.data.data.items)
                setTotalItems(userStatus.data.data.totalItems)
            }
            else {
                setUsersData([])
            }
        }
        if (userStatus.type === USERSTATUSCHANGEAPPROVED) {
            if (userStatus.data.success) {
                UserStatus()
            }
        }

        if (userStatus.type === USERTYPECHANGE) {
            if (userStatus.data.success) {
                UserStatus()
            }
        }
    }, [userStatus])

    useEffect(() => {
        if (role.type === GETROLE) {
            if (role.data.success && role.data.data?.length !== 0) {
                setRoleData(role.data.data.items)
            }
            else {
                setRoleData([])
            }
        }
    }, [role])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (current, size) => {
        setCurrentPage(currentPage); // Calculate the new current page
        setPageSize(size);
    };

    const handleRefreshClick = () => {
        setIsFetching(true)
        UserStatus()
    };

    const debouncedSearchFilter = debounce((e) => {
        setSearchValue(e)
        setSearchLoading(true)
    }, 800)
    const searchFilter = (e) => {
        debouncedSearchFilter(e);
    };

    const userAction = (action, data) => {
        let approveObj = {
            _id: data._id,
            status: 1
        }
        if (action === "approve") {
            Swal.fire({
                title: 'Are you sure you want to approve?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Approve it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(userStatusChangeApproved(approveObj, setLoading))
                    // UserStatus()
                }
            })
        }
        else {
            Swal.fire({
                title: 'Are you sure you want to reject?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Reject it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload = { _id: data._id, status: 2 }
                    dispatch(userStatusChangeApproved(payload, setLoading))
                    // UserStatus()
                }
            })
        }
    }

    const handleSizeChange = (e, a) => {
        setTypeValue(e)
        setCurrentPage(1)
    }

    const typeChange = (e, data) => {
        const payload = { _id: data._id, type: String(e) }
        dispatch(userTypeChange(payload))
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name) => (
                <span className='text-capitalize'>
                    {name || '-'}
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email) => (
                <span>
                    {email || '-'}
                </span>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (name) => (
                <span>
                    {name || '-'}
                </span>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render:
                (value, record, index) => {
                    return (
                        <Fragment>
                            <Space
                                direction="vertical"
                                style={{
                                    width: '100%',
                                }}
                            >

                                <Select
                                    value={record?.role_data[0]?.name}
                                    onChange={(e) => { typeChange(e, record) }}
                                    style={{
                                        width: "100%",
                                    }}>
                                    {
                                        roleData?.map((rowData, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <Select.Option value={rowData?.type}>{rowData?.name}</Select.Option>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </Select>

                            </Space>
                        </Fragment>
                    )
                },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (

                <Fragment>
                    <span onClick={() => { userAction("reject", record) }} className='userIcon'>
                        <span className='userIcon cursor-pointer' role="img" aria-label="close-circle" width="17" height="17">
                            <svg width="17" height="17" fillRule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close-circle" fill="currentColor" aria-hidden="true"><path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm128.01 198.83c.03 0 .05.01.09.06l45.02 45.01a.2.2 0 01.05.09.12.12 0 010 .07c0 .02-.01.04-.05.08L557.25 512l127.87 127.86a.27.27 0 01.05.06v.02a.12.12 0 010 .07c0 .03-.01.05-.05.09l-45.02 45.02a.2.2 0 01-.09.05.12.12 0 01-.07 0c-.02 0-.04-.01-.08-.05L512 557.25 384.14 685.12c-.04.04-.06.05-.08.05a.12.12 0 01-.07 0c-.03 0-.05-.01-.09-.05l-45.02-45.02a.2.2 0 01-.05-.09.12.12 0 010-.07c0-.02.01-.04.06-.08L466.75 512 338.88 384.14a.27.27 0 01-.05-.06l-.01-.02a.12.12 0 010-.07c0-.03.01-.05.05-.09l45.02-45.02a.2.2 0 01.09-.05.12.12 0 01.07 0c.02 0 .04.01.08.06L512 466.75l127.86-127.86c.04-.05.06-.06.08-.06a.12.12 0 01.07 0z"></path>
                            </svg>
                        </span>
                    </span>
                </Fragment>
            ),
        },
    ];

    const skeletonItems = Array.from({ length: pageSize }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };

    useEffect(() => {
        User()
    }, [userData])

    return (
        <Fragment>
            <Helmet>
                <title>Approved Users</title>
            </Helmet>

            <MainContentComponent>
                <div className="project-records project-record-page user-page position-relative" id="dashboard-page">
                    <div className="header">
                        <div className="row">
                            <div className="col-lg-8 col-md-4 left">
                                <h2 className='commonTableTitle'>Approved Users</h2>
                            </div>
                            <div className="col-lg-4 col-md-8 right d-lg-flex d-md-flex justify-content-end">
                                <div className="form-group status">
                                    <label htmlFor="status" className='commonLabel'>Role</label>
                                    <Space
                                        direction="vertical"
                                        style={{
                                            width: '100%',
                                        }}
                                        className="height-set-Description"
                                    >
                                        <Select
                                            className="pending-select"
                                            allowClear
                                            onChange={(e) => handleSizeChange(e ?? '')}
                                            style={{
                                                width: "100%",
                                            }}
                                            value={typeValue}
                                        >
                                            <Select.Option key='All' value="">
                                                <Fragment>
                                                    All
                                                </Fragment>
                                            </Select.Option>
                                            {roleData?.map((obj, index) => {
                                                return (
                                                    <Select.Option key={index} value={obj.type} >
                                                        {obj.name}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Space>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="status" className="commonLabel">Search</label>
                                    <Search
                                        allowClear
                                        onSearch={(value, event, { source }) => {
                                            if (source === 'input') {
                                                searchFilter(value);
                                            }
                                        }}
                                        placeholder="Search"
                                        onChange={(e) => searchFilter(e.target.value)}
                                        className='my-search-input mySearch height-set-Description'
                                        loading={searchLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <div className='text-center mb-4'>
                        </div>

                        <div className='my-pagination tableSetCss my-table-sckeleton'>
                            <Table
                                size="small"
                                dataSource={userData}
                                columns={columns}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                    onChange: handlePageChange,
                                    showSizeChanger: true,
                                    onShowSizeChange: handlePageSizeChange,
                                }}
                                components={customComponents}
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                    </div>
                    <div className='refresh-button-users'>
                        <Refresh
                            isFetching={isFetching} handleRefreshClick={handleRefreshClick}
                        />
                    </div>
                </div>
            </MainContentComponent>

        </Fragment>
    )
}

export default ApprovedUsers;
