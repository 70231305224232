import $ from "jquery";

const CartScroll = () => {
    var bodyHeight = $(".pages").height();
    var windowtop = $("#header-user").outerHeight();
    var breadcumbheight = $(".breadcumb").outerHeight();

    var projectBottomDivpadding = $('.pages #cart-main').css('padding-top');
    var tableHeaderHeight = $(".pages #cart-main .cart-main-title").outerHeight(true);
    var tableFooter = $(".pages #cart-main .cart-footer").outerHeight(true);
    var maindashboardsize = bodyHeight - windowtop - breadcumbheight - 10;

    var innerDivHeight = maindashboardsize - (parseFloat(projectBottomDivpadding) * 2) - tableHeaderHeight - (tableFooter ?? 0)

    $(".pages #cart-main").css("height", maindashboardsize).css('overflow-y', 'auto');
    $(".pages #cart-main .cart-all-data").css("height", innerDivHeight).css('overflow-y', 'auto');

    $(window).resize(function () {
        var bodyHeight = $(".pages").height();
        var windowtop = $("#header-user").outerHeight();
        var breadcumbheight = $(".breadcumb").outerHeight();

        var projectBottomDivpadding = $('.pages #cart-main').css('padding-top');
        var tableHeaderHeight = $(".pages #cart-main .cart-main-title").outerHeight(true);
        var tableFooter = $(".pages #cart-main .cart-footer").outerHeight(true);
        var maindashboardsize = bodyHeight - windowtop - breadcumbheight - 10;

        var innerDivHeight = maindashboardsize - (parseFloat(projectBottomDivpadding) * 2) - tableHeaderHeight - (tableFooter ?? 0)

        $(".pages #cart-main").css("height", maindashboardsize).css('overflow-y', 'auto');
        $(".pages #cart-main .cart-all-data").css("height", innerDivHeight).css('overflow-y', 'auto');
    })
}

export default CartScroll