import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
const TOKENENCRYPT = process.env.REACT_APP_TOKENENCRYPT;
const REACT_APP_COOKIE = process.env.REACT_APP_COOKIE;
export const tokenSetup = () => {
  const cookies = new Cookies();
  if (cookies.get(REACT_APP_COOKIE) !== undefined && cookies.get(REACT_APP_COOKIE) !== "undefined") {
    let mainToken = JSON.parse(
      CryptoJS.AES
        .decrypt(cookies.get(REACT_APP_COOKIE), TOKENENCRYPT)
        .toString(CryptoJS.enc.Utf8)
    );
    return {
      headers: {
        Authorization: `Bearer ${mainToken}`
      }
    };
  } else {
    return null;
  }
};
