import dayjs from "dayjs"

const findSelectRangePresest = () => {
    return (
        [
            {
                label: "Last 30 Minutes",
                value: [dayjs().subtract(30, "minutes"), dayjs()]
            },
            {
                label: "Today",
                value: [dayjs().startOf("day"), dayjs()]
            },
            {
                label: "Yesterday",
                value: [dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")]
            },
            {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs()]
            },
            {
                label: "Last 7 Days",
                value: [dayjs().add(-6, "d").startOf("day"), dayjs()]
            },
            {
                label: "Pervious Month",
                value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month")
                ]
            },
            {
                label: "Last 3 Months",
                value: [dayjs().subtract(3, "month").startOf("month"), dayjs()]
            },
            {
                label: "Last 6 Months",
                value: [dayjs().subtract(5, "month").startOf("month"), dayjs()]
            },
            {
                label: "This Year",
                value: [dayjs().startOf("year"), dayjs()]
            }
        ]
    )
}

export default findSelectRangePresest