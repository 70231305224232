import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import { rootReducer } from "../RootReducer.jsx/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
  key: "MainRootUser",
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "authReducer",
    "userStatusReducer",
    "roleReducer",
    "categoryReducer",
    "manageReducer",
    "measurementReducer",
    "usersOrderReducer"
  ]
};

const PersistRoot = persistReducer(persistConfig, rootReducer);

const store = createStore(PersistRoot, applyMiddleware(thunk));

export default store;
export const persistor = persistStore(store);

// import { applyMiddleware, createStore } from "redux";
// import { thunk } from "redux-thunk";
// import { rootReducer } from "../RootReducer.jsx/rootReducer";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import {
//   createStateSyncMiddleware,
//   initMessageListener,
//   withReduxStateSync
// } from "redux-state-sync";
// import { compose } from "redux";

// const persistConfig = {
//   key: "MainRootUser",
//   storage: storage,
//   stateReconciler: autoMergeLevel2,
//   blacklist: [
//     "authReducer",
//     "userStatusReducer",
//     "roleReducer",
//     "categoryReducer",
//     "manageReducer",
//     "measurementReducer",
//     "usersOrderReducer"
//   ] // Add the reducers you want to exclude
// };

// const PersistRoot = persistReducer(persistConfig, rootReducer);

// const customPredicate = action => {
//   if (
//     action.type === "persist/PERSIST" ||
//     action.type === "persist/REHYDRATE"
//   ) {
//     return false;
//   }
//   return true;
// };

// // Additional Configuration for State Sync Middleware
// const stateSyncConfig = {
//   predicate: customPredicate,
//   broadcastChannelOption: {
//     key: "MainRootUser",
//     name: "my_broadcast_channel_two", // Custom channel name
//     options: { type: "custom" } // Custom options for BroadcastChannel
//   },
//   blacklist: [
//     "authReducer",
//     "userStatusReducer",
//     "roleReducer",
//     "categoryReducer",
//     "manageReducer",
//     "measurementReducer",
//     "usersOrderReducer"
//   ] // Additional actions to ignore
// };

// const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

// const middlewares = [thunk, stateSyncMiddleware];
// const enhancers = [applyMiddleware(...middlewares)];
// const store = createStore(withReduxStateSync(PersistRoot), compose(...enhancers));
// initMessageListener(store, {
//   channel: "my_broadcast_channel_two"
// });
// export default store;
// export const persistor = persistStore(store);
