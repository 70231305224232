import $ from "jquery";
const Add = () => {
    var bodyHeight = $("main").height();
    var windowtop = $(".pages .top-header").outerHeight();
    var breadcumbheight = $(".breadcumb").outerHeight();

    var projectMargin = $('.project-record-page').css('margin-top');

    var projectpadding = $('.project-record-page').css('padding');
    var tableBodyMargin = $('.project-record-page .body').css('margin-top');


    var tableHeaderHeight = $(".ant-table-header").outerHeight();
    var paginationHeight = $(".ant-pagination").outerHeight(true);
    var bottomDivLabel = $(".project-record-page .header").outerHeight(true);

    var maindashboardsize = bodyHeight - windowtop - breadcumbheight;
    var tableInternalHeight = maindashboardsize - bottomDivLabel - (parseFloat(projectpadding) * 2) - tableHeaderHeight - (paginationHeight ?? 0) - (parseFloat(tableBodyMargin) * 2);
    $(".project-record-page").css("height", maindashboardsize);
    $('.project-record-page .ant-table-body').css('max-height', tableInternalHeight);
    $('.project-record-page .ant-table-body').css('height', tableInternalHeight);
    $('.project-record-page .ant-table-tbody .ant-table-placeholder').css('height', tableInternalHeight);

    $(window).resize(function () {
        var bodyHeight = $("main").height();
        var windowtop = $(".pages .top-header").outerHeight();
        var breadcumbheight = $(".breadcumb").outerHeight();

        var projectMargin = $('.project-record-page').css('margin-top');

        var projectpadding = $('.project-record-page').css('padding');
        var tableBodyMargin = $('.project-record-page .body').css('margin-top');


        var tableHeaderHeight = $(".ant-table-header").outerHeight();
        var paginationHeight = $(".ant-pagination").outerHeight(true);
        var bottomDivLabel = $(".project-record-page .header").outerHeight(true);

        var maindashboardsize = bodyHeight - windowtop - breadcumbheight;
        var tableInternalHeight = maindashboardsize - bottomDivLabel - (parseFloat(projectpadding) * 2) - tableHeaderHeight - (paginationHeight ?? 0) - (parseFloat(tableBodyMargin) * 2);
        $(".project-record-page").css("height", maindashboardsize);
        $('.project-record-page .ant-table-body').css('max-height', tableInternalHeight);
        $('.project-record-page .ant-table-body').css('height', tableInternalHeight);
        $('.project-record-page .ant-table-tbody .ant-table-placeholder').css('height', tableInternalHeight);



    })

}
export default Add;