import React, { Fragment } from 'react'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'
import './homeCategory.css'
import HomeCategory from '../Category/HomeCategory';

const HomePage = () => {


    return (
        <Fragment>
            <MainContentComponent>

                <HomeCategory />

            </MainContentComponent>
        </Fragment>
    )
}

export default HomePage