import * as AntIcons from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import './breadcrumb.scss'
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
const BreadCumb = (props) => {
    const capitalizeFirstLetter = (str) => {
        return str.split('-').join(" ")
    }
    const location = useLocation()

    const breadCumpItem = useMemo(() => {
        const locationPath = location.pathname.split('/').filter(x => x)

        let url = ''
        let array = locationPath.map((x, index) => {
            url += `/${x}`
            return {
                title: <>
                    <NavLink to={url} style={{ textTransform: "capitalize" }}>
                        {index ? <></> : <AntIcons.EyeOutlined />}
                        {capitalizeFirstLetter(x)}
                    </NavLink>
                </>
            }
        })

        return array;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    return (
        <div className="breadcumb">
            <div className="row">
                <div className="col-lg-8 col-sm-6 col-12 left d-flex align-items-center">
                    <Breadcrumb
                        items={[...breadCumpItem]}
                    />
                </div>
                {props.children &&
                    props.children}
            </div>
        </div>
    )
}
export default BreadCumb
