import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'
import { Link, useLocation, useParams } from 'react-router-dom'
import CustomBreadcumb from './CustomBreadcumb';
import { Empty, Skeleton, Spin } from 'antd';
import homeScroll from '../js/homeScroll';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;


const AllCategory = () => {
    const params = useParams()
    const [loading, setLoading] = useState(true)
    const [allDataCategory, setAllDataCategory] = useState([])

    const [breadCumpCategory, setBreadCumpCategory] = useState([])
    const urlLocationData = () => {
        const payload = {
            _id: params.category
        }
        axios.post(`${REACT_APP_SOCKET}/category/category_getById`, payload).then(res => {
            if (res.data.success) {
                setBreadCumpCategory([res.data.data.name])
            } else {
                setBreadCumpCategory([])
            }
        })
    }

    useEffect(() => {
        getCategoryData()
        urlLocationData()
    }, [params])

    const getCategoryData = () => {
        setLoading(true)
        const payload = {
            page: 1,
            limit: 100000,
            search: '',
            status: '0',
            categoryId: params.category
        }
        axios.post(`${REACT_APP_SOCKET}/subCategory/subCategory_getAll`, payload).then(res => {
            if (res.data.success) {
                setAllDataCategory(res.data.data.items)
                setLoading(false)
            } else {
                setAllDataCategory([])
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        homeScroll()
    }, [allDataCategory])

    return (
        <Fragment>
            <CustomBreadcumb name={breadCumpCategory} />
            <div id="home-page">
                {!loading && allDataCategory.length === 0
                    ?

                    // Empty No Data 
                    <div className='d-flex flex-column h-100 justify-content-center'>
                        <Empty description="No Data " image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                    :

                    //Main data Content
                    <div className='category-body'>
                        {
                            loading ?
                                Array.from({ length: 6 }).map((_, index) => (
                                    <Link key={index} to={'#'} >
                                        <div className='category-card bg-white'>
                                            <div className='img-card'>
                                                <Skeleton.Node active />
                                            </div>
                                            <div className='img-title mt-1'>
                                                <Skeleton active title={false} />
                                            </div>
                                        </div>
                                    </Link>
                                ))
                                :
                                allDataCategory.map((item, index) => {
                                    return (
                                        <Link key={index} to={item._id}>
                                            <div className='category-card'>
                                                <div className='img-card'>
                                                    {
                                                        item.image ?
                                                            <img src={`${REACT_APP_SOCKET}/uploads/subCategory/${item.image}`} alt="" />
                                                            :
                                                            <div className='bg-white h-100 w-100 d-flex flex-column justify-content-center rounded'>
                                                                <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                            </div>
                                                    }
                                                </div>
                                                <div className='img-title text-capitalize'>
                                                    {item.name}
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                        }
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default AllCategory