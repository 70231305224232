import { combineReducers } from "redux";
import { authReducer } from "../Reducers/authReducer";
import { categoryReducer } from "../Reducers/categoryReducer";
import { manageReducer } from "../Reducers/manageReducer";
import { measurementReducer } from "../Reducers/measurementReducer";
import { roleReducer } from "../Reducers/roleReducer";
import { userStatusReducer } from "../Reducers/userStatusReducer";
import { pathReducer } from "../Reducers/pathReducer";
import { productReducer } from "../Reducers/productReducer";
import { userCategoryReducer } from "../Reducers/userCategoryReducer";
import { loginHistoryReducer } from "../Reducers/loginHistoryReducer";
import { ordersReducer } from "../Reducers/ordersReducer";
import { usersOrderReducer } from "../Reducers/usersOrderReducer";
import { cartCount } from "../Reducers/cartCount";
import { searchSelectReducer } from "../Reducers/searchSelectReducer";

export const rootReducer = combineReducers({
  authReducer: authReducer,
  userStatusReducer: userStatusReducer,
  roleReducer: roleReducer,
  categoryReducer: categoryReducer,
  manageReducer: manageReducer,
  measurementReducer: measurementReducer,
  pathReducer: pathReducer,
  productReducer: productReducer,
  userCategoryReducer: userCategoryReducer,
  loginHistoryReducer: loginHistoryReducer,
  ordersReducer: ordersReducer,
  usersOrderReducer: usersOrderReducer,
  cartCount: cartCount,
  searchSelectReducer:searchSelectReducer
});
