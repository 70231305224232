import { SEARCHSELECT } from "../ActionTypes/actionTypes";

let defaultState = {
    data:false,
    type: SEARCHSELECT
};

export const searchSelectReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SEARCHSELECT: {
            return {
                data: action.payload,
                type: SEARCHSELECT
            };
        }
        default:
            return state;
    }
}
