import axios from "axios";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
import { tokenSetup } from "../../App_Details_theme/functions/tokenSetup";
import { SEARCHSELECT } from "../ActionTypes/actionTypes";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const serchSelect = (state) => {
    return (dispatch) => {
        dispatch({
            type: SEARCHSELECT,
            payload: state,
        });

    }
}

