import dayjs from 'dayjs';

const FindSelectedRangeDate = (event, setSelectedPreset) => {
    const dateObj = event?.map(x => ({ "date": dayjs(x.$d).format("DD-MM-YYYY"), "time": dayjs(x.$d).format("HH:mm") }));
    const dateString1 = dateObj[0].date;
    const dateString2 = dateObj[1].date;
    if (dateString2 === dayjs().format("DD-MM-YYYY")) {
        if (dateString1 === dayjs().startOf("year").format("DD-MM-YYYY")) {
            setSelectedPreset && setSelectedPreset("This Year");
            return "This Year"
        }
        else if (dateString1 === dayjs().subtract(5, "month").startOf("month").format("DD-MM-YYYY")) {
            setSelectedPreset && setSelectedPreset("Last 6 Months");
            return "Last 6 Months"
        }
        else if (dateString1 === dayjs().subtract(3, "month").startOf("month").format("DD-MM-YYYY")) {
            setSelectedPreset && setSelectedPreset("Last 3 Months");
            return "Last 3 Months"
        }
        else if (dateString1 === dayjs().add(-6, "d").startOf("day").format("DD-MM-YYYY")) {
            setSelectedPreset && setSelectedPreset("Last 7 Days");
            return "Last 7 Days"
        }
        else if (dateString1 === dayjs().startOf("month").format("DD-MM-YYYY")) {
            setSelectedPreset && setSelectedPreset("This Month");
            return "This Month"
        }
        else if (dateString1 === dayjs().startOf("day").format("DD-MM-YYYY")) {
            if (dateObj[0].time === dayjs().subtract(30, "minutes").format("HH:mm")) {
                setSelectedPreset && setSelectedPreset("Last 30 Minutes");
                return "Last 30 Minutes"
            } else {
                setSelectedPreset && setSelectedPreset("Today");
                return "Today"
            }
            // if (dateObj[0].time === dayjs().startOf("day").format("HH:mm")) {
            //     setSelectedPreset && setSelectedPreset("Today");
            //     return "Today"
            // } else {
            //     setSelectedPreset && setSelectedPreset("Last 30 Minutes");
            //     return "Last 30 Minutes"
            // }
        }
    }
    else if (dateString1 === dayjs().subtract(1, "day").startOf("day").format("DD-MM-YYYY") && dateString2 === dayjs().subtract(1, "day").endOf("day").format("DD-MM-YYYY")) {
        setSelectedPreset && setSelectedPreset("Yesterday");
        return "Yesterday"
    }
    else if (dateString1 === dayjs().subtract(1, "month").startOf("month").format("DD-MM-YYYY") && dateString2 === dayjs().subtract(1, "month").endOf("month").format("DD-MM-YYYY")) {
        setSelectedPreset && setSelectedPreset("Pervious Month");
        return "Pervious Month"
    } else {
        setSelectedPreset && setSelectedPreset(null);
    }
}

export default FindSelectedRangeDate