import React from "react";
import { Form, Button } from "antd";

const SubmitButtonUser = ({
  form,
  isFormValid,
  disabled,
  getAllDataPass,
  loadingSave,
  loadingUpdate,
}) => {
  const [submittable, setSubmittable] = React.useState(false);
  const [updateSubmittable, setUpdateSubmittable] = React.useState(true);
  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setUpdateSubmittable(true);
          setSubmittable(true);
        },
        () => {
          setUpdateSubmittable(false);
          setSubmittable(false);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <>
      {getAllDataPass ? (
        <Button
          disabled={!disabled}
          type="primary"
          htmlType="submit"
          colortextdisabled="rgba(0, 0, 0, 0.25)"
          // style={{ backgroundColor: 'red' }}
          loading={getAllDataPass ? loadingUpdate : loadingSave}
        >
          {"Update Cart"}
        </Button>
      ) : (
        <Button
          disabled={!disabled}
          type="primary"
          htmlType="submit"
          colortextdisabled="rgba(0, 0, 0, 0.25)"
          loading={getAllDataPass ? loadingUpdate : loadingSave}
        >
          {"Add To Cart"}
        </Button>
      )}
    </>
  );
};

export default SubmitButtonUser;
