import { AUTHDATA, GETROLE } from "../ActionTypes/actionTypes";
let defaultState = {
  data: {},
  type: AUTHDATA
};

export const pathReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTHDATA: {
      return {
        data: {
          ...action.payload
        },
        type: AUTHDATA
      };
    }
    default:
      return state;
  }
};