import axios from "axios";
import { AUTHDATA, FORGETPASS, FORGETPASSOTPVERIFY, LOGIN, LOGINOTP, REGISTER, REGISTEROTP } from "../ActionTypes/actionTypes";
import CustomMessage from "../../App_Details_theme/CustomMessage/CustomMessage";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

export const registerAction = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            setLoading(true)
            axios.post(REACT_APP_SOCKET + '/register/register_post', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: REGISTER,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                CustomMessage('success', response.data.message)
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}
export const registerOtpAction = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            setLoading(true)
            axios.post(REACT_APP_SOCKET + '/register/otp_verify_registration', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: REGISTEROTP,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                CustomMessage('success', response.data.message)
            })
        }
        catch (error) {
            CustomMessage('success', error.message)
        }
    }
}

export const loginAction = (apiObj,naviagate) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/login_post', {...apiObj,type:'user'}).then((response) => {
                if (response.data.success) {
                    if(response.data.twoFA===1){
                        dispatch({
                            type: AUTHDATA,
                            payload: response?.data,
                        });
                        dispatch({
                            type: LOGIN,
                            payload: response?.data,
                        });
                    }
                    else
                    {
                        dispatch({
                            type: LOGIN,
                            payload: response?.data,
                        });
                    }
                }
                else {
                    CustomMessage('error', response.data.message)
                }
            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}
export const loginOtpAction = (apiObj) => {
    return (dispatch) => {
        try {
            axios.post(REACT_APP_SOCKET + '/register/otp_verify', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: AUTHDATA,
                        payload: response?.data,
                    });
                    dispatch({
                        type: LOGINOTP,
                        payload: response?.data,
                    });
                }
                else {
                    CustomMessage('error', response.data.message)
                }
            })
        }
        catch (error) {
            CustomMessage('error', error.message)
        }
    }
}

export const forgetAction = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            setLoading(true)
            axios.post(REACT_APP_SOCKET + '/register/forgetPassword', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: FORGETPASS,
                        payload: response?.data,
                    });
                }
                setLoading(false)
                CustomMessage('success', response.data.message)
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}
export const forgetOtpAction = (apiObj, setLoading) => {
    return (dispatch) => {
        try {
            setLoading(true)
            axios.post(REACT_APP_SOCKET + '/register/otp_verify_forgetPassword', apiObj).then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: FORGETPASSOTPVERIFY,
                        payload: response?.data,
                    });
                    setLoading(false)
                    CustomMessage('success', response.data.message)
                }else{
                    setLoading(false)
                    CustomMessage('error', response.data.message)

                }
            })
        }
        catch (error) {
            setLoading(false)
            CustomMessage('success', error.message)
        }
    }
}