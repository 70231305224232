import { Modal, Table, DatePicker, Form, Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Refresh from "../../App_Details_theme/Common_Components/Refresh";
import MainContentComponent from "../../App_Details_theme/components/MainContentComponent";
import { downloadSummary } from "../../App_Details_theme/functions/DownloadSummary";
import {
  orderAccept,
  orderReject,
  pendingOrders,
} from "../../Redux/Action/oredrsGetAll";
import {
  ORDERACCEPT,
  ORDERREJECT,
  PENDINGORDERS,
} from "../../Redux/ActionTypes/actionTypes";
import User from "../../Users/User";
import "./orders.css";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const PendingOrders = () => {
  let dispatch = useDispatch();
  const [orderData, setOrderData] = useState([]);
  let order = useSelector((state) => state.ordersReducer);

  const [userId, setUserId] = useState("");
  const [orderId, setIOrderId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleRefreshClick = () => {
    setIsFetching(true);
    setLoading(true);
    OrderGetAll();
  };

  const showModal = (record) => {
    setUserId(record?.userId);
    setIOrderId(record?.orderId);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal2 = (record) => {
    setUserId(record?.userId);
    setIOrderId(record?.orderId);
    setIsModalOpen2(true);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const disabledDateFun = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledDateTime = (current) => {
    if (moment().isSame(current, "day")) {
      return {
        disabledHours: () => [...Array(moment().hour()).keys()],
        disabledMinutes: () => [...Array(moment().minute()).keys()],
        disabledSeconds: () => [...Array(moment().second()).keys()],
      };
    }
    return {};
  };

  const OrderGetAll = () => {
    let payload = {
      page: currentPage,
      limit: pageSize,
      search: "",
      status: 1,
    };
    dispatch(
      pendingOrders(payload, setLoading, setSearchLoading, setIsFetching)
    );
  };

  useEffect(() => {
    OrderGetAll();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (order.type === PENDINGORDERS) {
      if (order.data.success && order.data.data?.length !== 0) {
        setOrderData(order.data.data.items);
        setTotalItems(order.data.data.totalItems);
      } else {
        setOrderData([]);
      }
    }
    if (order.type === ORDERACCEPT) {
      if (order.data.success) {
        OrderGetAll();
        form.resetFields();
        setIsModalOpen(false);
      }
    }
    if (order.type === ORDERREJECT) {
      if (order.data.success) {
        OrderGetAll();
        form2.resetFields();
        setIsModalOpen2(false);
      }
    }
  }, [order]);

  const formFinish = (e) => {
    const date = dayjs(e?.estimatedDeliveryDate?.$d).format("YYYY-MM-DD");
    let payload = {
      userId: userId,
      orderId: orderId,
      estimateDeliveryDate: date,
    };
    dispatch(orderAccept(payload));
  };

  const formFinish2 = (e) => {
    let payload = {
      userId: userId,
      orderId: orderId,
      description: e?.reasonForRejectOrder,
    };
    dispatch(orderReject(payload));
  };

  const getAllSumeryData = (record, e) => {
    const payload = {
      userId: record.userId,
      orderId: record.orderId,
    };
    axios
      .post(`${REACT_APP_SOCKET}/order/order_summery`, payload)
      .then((res) => {
        if (res.data.success) {
          const body = res.data.data?.at(0).data.flatMap((x) => {
            return x.orderdetails.productDetails.flatMap((z) => {
              return [
                [
                  x.product_data.productName,
                  x.orderdetails.measurement_data.find(
                    (w) => w._id === z.measurementId
                  ).label,
                  z.data.map((d) => Object.values(d).at(0).at(0)).join(" "),
                  z.qty,
                  "",
                ],
              ];
            });
          });
          body.map((x, i) => x.unshift(i + 1));
          downloadSummary(
            {
              orderDetails: res.data.data
                ?.at(0)
                .ordertrackings_data.at(0)
                .trackingDetails.find((x) => x.status === 1),
              orderNo: res.data.data.at(0).orderNo,
              authorData: res.data.authorData,
              userData: res.data.data.at(0).user_data.at(0),
              head: [
                ["No.", "Product", "Measure", "Color/Size", "Qty", "\t\t"],
              ],
              body: body,
            },
            e
          );
        } else {
        }
      });
  };

  const columns = [
    {
      title: <div>Order No</div>,
      dataIndex: "orderNo",
      key: "orderNo",
      render: (orderNo, record) => {
        return (
          <>
            <span>{orderNo || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>User Name</div>,
      dataIndex: "user",
      key: "user",
      render: (user, record) => {
        return (
          <>
            <span className="text-capitalize">{user || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>Courier Name</div>,
      dataIndex: "courierName",
      key: "courierName",
      render: (courierName, record) => {
        return (
          <>
            <span className="text-capitalize">{courierName || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>City</div>,
      dataIndex: "city",
      key: "city",
      render: (city, record) => {
        return (
          <>
            <span className="text-capitalize">{city || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>State</div>,
      dataIndex: "state",
      key: "state",
      render: (state, record) => {
        return (
          <>
            <span className="text-capitalize">{state || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>Country</div>,
      dataIndex: "country",
      key: "country",
      render: (country, record) => {
        return (
          <>
            <span className="text-capitalize">{country || "-"}</span>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>Status</div>,
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return (
          <Fragment key={index}>
            <div className="d-flex justify-content-center status-btn">
              <div
                className="green-user d-flex"
                onClick={() => showModal(record)}
              >
                <div className="green-text-user text-capitalize">Accept</div>
              </div>
              <div
                className="red-user d-flex"
                onClick={() => showModal2(record)}
              >
                <div className="red-text-user text-capitalize">Reject</div>
              </div>
            </div>
          </Fragment>
        );
      },
      align: "center",
    },
    {
      title: <div>Track</div>,
      dataIndex: "track",
      key: "track",
      render: (track, record) => {
        return (
          <>
            <Link
              to={{
                pathname: `${record.orderNo}`,
                search: `?${record.orderId}`,
              }}
            >
              Track
            </Link>
          </>
        );
      },
      align: "center",
    },
    {
      title: <div>Summary</div>,
      dataIndex: "summary",
      key: "summary",
      render: (summary, record) => {
        return (
          <div className="d-flex justify-content-center status-btn">
            <div
              className="orange-user d-flex pointer"
              onClick={() => getAllSumeryData(record, "view")}
            >
              <div className="orange-text-user text-capitalize">View</div>
            </div>
            <div
              className="green-user d-flex pointer"
              onClick={() => getAllSumeryData(record, "download")}
            >
              <div className="green-text-user text-capitalize">Download</div>
            </div>
          </div>
        );
      },
      align: "center",
    },
  ];

  useEffect(() => {
    User();
  }, [orderData]);

  return (
    <Fragment>
      <Helmet>
        <title>Pending Orders</title>
      </Helmet>

      <Modal
        wrapClassName="pending-order"
        title="Accept"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={formFinish} layout="vertical">
          <div className="form-group date-mod">
            <label className="commonLabel" htmlFor="Delay">
              Estimated Delivery Date<span className="required">*</span>
            </label>
            <Form.Item
              name="estimatedDeliveryDate"
              className="height-set-Description"
              rules={[
                { required: true, message: "Estimated Delivery Date Required" },
              ]}
            >
              <DatePicker
                name="estimatedDeliveryDate"
                placeholder="Estimated Delivery Date"
                disabledDate={disabledDateFun}
                disabledTime={disabledDateTime}
              />
            </Form.Item>
          </div>
          <div className="footer d-flex justify-content-end">
            <Form.Item>
              <Button
                name="buttonClickedSave"
                className="ms-1"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        wrapClassName="pending-order-reject"
        title="Reject"
        open={isModalOpen2}
        footer={null}
        onCancel={handleCancel2}
      >
        <Form form={form2} onFinish={formFinish2} layout="vertical">
          <div className="form-group date-mod">
            <label className="commonLabel" htmlFor="Delay">
              Reason For Reject Order<span className="required">*</span>
            </label>
            <Form.Item
              name="reasonForRejectOrder"
              className="height-set-Description"
              rules={[
                { required: true, message: "Reason For Reject Order Required" },
              ]}
            >
              <TextArea
                rows={4}
                name="reasonForRejectOrder"
                placeholder="Reason For Reject Order"
              />
            </Form.Item>
          </div>
          <div className="footer d-flex justify-content-end">
            <Form.Item>
              <Button
                name="buttonClickedSave"
                className="ms-1"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <MainContentComponent>
        <div
          className="project-records project-record-page user-page position-relative"
          id="Orders-Approvel"
        >
          <div className="header">
            <div className="row">
              <div className="col-lg-6 col-md-4 left">
                <h2 className="commonTableTitle">Pending Orders</h2>
              </div>
              <div className="col-lg-6 col-md-8 right d-lg-flex d-md-flex justify-content-end"></div>
            </div>
          </div>
          <div className="body">
            <div className="text-center mb-4"></div>

            <div className="my-pagination tableSetCss my-table-sckeleton">
              <Table
                size="small"
                dataSource={orderData}
                columns={columns}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalItems,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onChange: (page) => setCurrentPage(page),
                  onShowSizeChange: (current, size) => setPageSize(size),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                // components={customComponents}
                scroll={{
                  x: null,
                  y: 250,
                }}
              />
            </div>
          </div>
          <div className="refresh-button-users">
            <Refresh
              isFetching={isFetching}
              handleRefreshClick={handleRefreshClick}
            />
          </div>
        </div>
      </MainContentComponent>
    </Fragment>
  );
};

export default PendingOrders;
