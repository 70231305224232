import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Page404 = () => {
    return (
        <Fragment>
            Page Not Found
            <div>
                <Link to={"/"}>Back To Root</Link>
            </div>
        </Fragment>
    )
}

export default Page404