import { Button, Form, Input, Select, Upload, Table, Collapse, theme, Image, Switch, Skeleton } from 'antd'
import React, { Fragment, useRef, useState } from 'react'
import Refresh from '../../App_Details_theme/Common_Components/Refresh'
import MainContentComponent from '../../App_Details_theme/components/MainContentComponent'
import * as AntIcons from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './addProduct.css'
import { useEffect } from 'react';
import { CATEGORY, DELETEPRODUCT, POSTPRODUCT, PRODUCT, PRODUCTSTATUS, SUBCATEGORY, SUBCATEGORYBYID, UPDATEPRODUCT } from '../../Redux/ActionTypes/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { categoryGetAll, subCategoryGetAll, subCategoryGetById } from '../../Redux/Action/categoryGetAll';
import { productCreate, productDelete, productGetAll, productStatus, productUpdate } from '../../Redux/Action/productGetAll';
import ActionMenu from '../../App_Details_theme/Common_Components/ActionMenu';
import Search from 'antd/es/input/Search';
import Add from './Add';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const { Panel } = Collapse;

const AddProduct = () => {

    const formRef = useRef();
    const formRef2 = useRef();
    const fileInputRef = useRef();

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [data, setData] = useState('');
    const [editData, seteditData] = useState('');
    const [id, setId] = useState('');
    const [defultValue, setDefultValue] = useState(false);
    const [editValue, setEditValue] = useState(false);
    const [selectedStatusData, setSelectedStatusData] = useState('0');
    const [fileList, setFileList] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [dynamicData, setDynamicData] = useState([]);
    const [subCategoryData, setSubCategoryData] = useState([]);
    const [dataId, setDataId] = useState([]);
    const [newData, setNewData] = useState([]);
    const [printTableData, setPrintTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [array, setArray] = useState([]);

    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [butonLoading, setButonLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [image, setImage] = useState(null)

    const [totalItems, setTotalItems] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    let dispatch = useDispatch()
    const [categoryFilter, setCategoryFilter] = useState("");
    const [subCategoryFilter, setSubCategoryFilter] = useState("");
    const [searchDataAll, setSearchDataAll] = useState('');

    const [uploadFiles, setUploadFiles] = useState([])
    let [uploadFilesMultiple, setUploadFilesMultiple] = useState([])
    const [collapseActiveKey, setCollapseActiveKey] = useState(['']);

    let category = useSelector((state) => state.categoryReducer)
    let product = useSelector((state) => state.productReducer)

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <AntIcons.PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        let newArray = newFileList.map((item) => {
            if (item.originFileObj) {
                return item.originFileObj
            } else {
                return item.name
            }
        });
        setUploadFilesMultiple(newArray)

    };

    const handleChangeEditor = (event, editor) => {
        const content = editor?.getData();
        setData(content);
    };

    const handleRefreshClick = () => {
        setIsFetching(true)
        setLoading(true)
        ProductGetAll()
    }
    const handleOnChange = async (e) => {

        let url = await toBase64(e.target.files[0])
        setImageUrl(url);

        const files = e.target.files;
        setUploadFiles([...files]);
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });


    useEffect(() => {
        CategoryGetAll()
        ProductGetAll()
    }, [])

    useEffect(() => {
        ProductGetAll()
    }, [currentPage, pageSize, categoryFilter, selectedStatusData, subCategoryFilter, searchDataAll])

    const CategoryGetAll = () => {
        let payload = {
            page: 1,
            limit: 10000,
            search: "",
            status: 0
        }
        dispatch(categoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    const ProductGetAll = () => {
        let payload = {
            page: currentPage,
            limit: pageSize,
            search: searchDataAll,
            status: selectedStatusData,
            categoryId: categoryFilter,
            subCategoryId: subCategoryFilter
        }
        dispatch(productGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        if (category.type === CATEGORY) {
            if (category.data.success && category.data.data?.length !== 0) {
                setCategoryData(category.data.data.items)
            }
            else {
                setCategoryData([])
            }
        }
        if (category.type === SUBCATEGORY) {
            if (category.data.success && category.data.data?.length !== 0) {
                setSubCategoryData(category.data.data.items)
                if (defultValue === true) {
                    form.setFieldValue("subCategoryId", editData)
                }
            }
            else {
                setSubCategoryData([])
            }
        }
        if (category.type === SUBCATEGORYBYID) {
            if (category.data.success && category.data.data?.length !== 0) {
                setDataId(category.data.data)
            }
            else {
                setDataId([])
            }
        }
    }, [category])

    useEffect(() => {
        if (product.type === PRODUCT) {
            if (product.data.success && product.data.data?.length !== 0) {
                setProductData(product.data.data.items)
                setTotalItems(product.data.data.totalItems)
            }
            else {
                setProductData([])
            }
        }
        if (product.type === POSTPRODUCT) {
            if (product.data.success) {
                ProductGetAll()
                form.resetFields()
                form2.resetFields()
                setData('')
                setPrintTableData([])
                setUploadFilesMultiple([])
                setFileList([])
                setCollapseActiveKey([])
                setImage("")
                setImageUrl("")
                setArray([])
                setUploadFiles([])
            }
        }
        if (product.type === DELETEPRODUCT) {
            if (product.data.success) {
                ProductGetAll()
                form.resetFields()
                form2.resetFields()
                setDefultValue(false)
                setImage("")
                setImageUrl("")
                setArray([])
            }
        }
        if (product.type === UPDATEPRODUCT) {
            if (product.data.success) {
                form.resetFields()
                setDefultValue(false)
                ProductGetAll()
                form2.resetFields()
                setData('')
                setPrintTableData([])
                setUploadFilesMultiple([])
                setFileList([])
                setId('')
                setCollapseActiveKey([])
                setImage("")
                setImageUrl("")
                setArray([])
            }
        }
        if (product.type === PRODUCTSTATUS) {
            if (product.data.success) {
                ProductGetAll()
                form.resetFields()
            }
        }
    }, [product])

    const handleCategoryChange = (e) => {
        let payload = {
            page: 1,
            limit: 10000,
            search: "",
            status: 0,
            categoryId: e
        }
        dispatch(subCategoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    const handleSubCategoryChange = (e) => {
        let payload = {
            _id: e
        }
        dispatch(subCategoryGetById(payload, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        if (dataId.length !== 0) {

            let newArray = []
            let newMeasurementArray = [];
            newMeasurementArray = dataId[0]?.measurementArray.filter((abc) => {
                if (abc.measurementType === 0) {
                    return abc
                }
            })
            let obj = {
                ...dataId[0],
                measurementArray: newMeasurementArray
            }
            newArray = [obj]
            setNewData(newArray)

            let newArray2 = []

            newArray2 = dataId.map((x) => (
                x.measurementArray.filter((a) => {
                    if (a.measurementType === 1) {
                        return a;
                    }
                })
            ))
            setTableData(newArray2)
        }
    }, [dataId])

    const handleDataChange = (e, data) => {

        const arr = data.filter((x) => {
            if (e.includes(x.measurementId)) {
                return x;
            }
        })
        setDynamicData(arr)
    };

    const handleFinish = () => {
        if (editValue === true) {
            form2
                .validateFields()
                .then(value => {
                    let newArray = [];

                    let newFilterKey = Object.keys(value).map((key) => {
                        if (key.includes('uniqueKey')) {
                            let keySetup = key.replace('uniqueKey', '')
                            return {
                                [keySetup]: value[key]
                            }
                        }
                    }).filter((data) => data)

                    let abc = newFilterKey.map((x) => {
                        return x;
                    });

                    newArray = value?.measurementId?.map((idSet) => {
                        let rate
                        if (Object.keys(value).includes(idSet + "rate")) {
                            rate = value[idSet + "rate"]
                        }
                        let obj = {
                            measurementId: idSet,
                            data: abc,
                            quantity: 1,
                            price: `${rate}`,
                            status: 0
                        }
                        return {
                            ...obj
                        }
                    })

                    let tableArray = [...array, ...newArray]
                    setArray([...array, ...newArray])
                    form2.resetFields()

                    //table printdata
                    let newprint = []
                    newprint = tableArray.map((main) => {
                        if (main.data) {
                            let newDataOne = main;
                            main.data.forEach((key) => {
                                newDataOne[Object.keys(key)[0]] = key[Object.keys(key)[0]]
                            })
                            newData[0]?.measurementArray.map((e) => {
                                if (e.measurementId == main.measurementId) {
                                    newDataOne['label'] = e.measurement_data.label
                                }
                            })
                            return newDataOne
                        }
                        else {
                            return main
                        }
                    })
                    setPrintTableData(newprint)
                    setDynamicData([])
                    setEditValue(false)
                })
                .catch(errorInfo => {
                    console.log('Validation failed:', errorInfo);
                });
        }
        else {
            form2
                .validateFields()
                .then(value => {
                    console.log(value, "valuevalue")
                    // let value = form2.getFieldsValue();
                    let newArray = [];

                    let newFilterKey = Object.keys(value).map((key) => {
                        if (key.includes('uniqueKey')) {
                            let keySetup = key.replace('uniqueKey', '')
                            return {
                                [keySetup]: value[key]
                            }
                        }
                    }).filter((data) => data)

                    let abc = newFilterKey.map((x) => {
                        return x;
                    });

                    newArray = value?.measurementId.map((idSet) => {
                        let quantitys
                        let rate
                        if (Object.keys(value).includes(idSet + "quantity")) {
                            quantitys = value[idSet + "quantity"]
                        }
                        if (Object.keys(value).includes(idSet + "rate")) {
                            rate = value[idSet + "rate"]
                        }
                        let obj = {
                            measurementId: idSet,
                            data: abc,
                            quantity: 1,
                            price: `${rate}`,
                            status: 0
                        }
                        return {
                            ...obj
                        }
                    })

                    let tableArray = [...array, ...newArray]
                    setArray([...array, ...newArray])
                    form2.resetFields()

                    setDynamicData([])
                    formRef2.current.setFieldsValue({ "measurementId": [] });
                    const fields = formRef2.current.getFieldsValue();
                    for (const fieldName in fields) {

                        if (fieldName.includes("uniqueKey")) {
                            formRef2.current.resetFields([fieldName]);

                        }
                    }

                    //table printdata
                    let newprint = []
                    newprint = tableArray.map((main) => {
                        if (main.data) {
                            let newDatas = main;
                            main.data.forEach((key) => {
                                newDatas[Object.keys(key)[0]] = key[Object.keys(key)[0]]
                            })
                            console.log(newData, "newwwwww");
                            newData.forEach((item) => {
                                item.measurementArray.forEach((newLabel) => {
                                    if (main.measurementId === newLabel.measurement_data._id) {
                                        newDatas['label'] = newLabel.measurement_data.label
                                    }
                                })

                                console.log(item, "item");

                            })
                            // dynamicData.map((e) => {
                            //     if (e.measurementId == main.measurementId) {
                            //         newDatas['label'] = e.measurement_data.label
                            //         // console.log(newDatas['label'] , e.measurement_data.label,"newDatas['label'] = e.measurement_data.label");
                            //     }
                            // })
                            return newDatas
                        }
                        else {
                            return main
                        }
                    })
                    console.log(newprint, "newprint")

                    setPrintTableData(newprint)
                })
                .catch(errorInfo => {

                    console.log('Validation failed:', errorInfo);
                });
        }


    }

    // static
    const edit = (e, index) => {
        setEditValue(true)
        // setDefultValue(true)
        let uniqueKeyArray = Object.keys(form2.getFieldsValue()).map((data) => data.replace('uniqueKey', ''))
        Object.keys(e).forEach((editKey) => {
            if (uniqueKeyArray.includes(editKey)) {
                let keySet = editKey + 'uniqueKey'
                form2.setFieldsValue({ [keySet]: e[editKey] })
            }
        })

        form2.setFieldValue("measurementId", [e.measurementId])

        let newArray = [{
            measurementId: e.measurementId,
            measurement_data: {
                label: e.label
            }
        }]
        setDynamicData(newArray)

        const updatedPrintTableData = [...printTableData];
        updatedPrintTableData.splice(index, 1);
        setArray([...updatedPrintTableData])
        setPrintTableData(updatedPrintTableData);
        let abd = `${e.measurementId}rate`
        console.log(e)
        // if(e.price.includes("/-")){
            form2.setFieldValue(abd, e.price)
        // }
    }

    // static
    const handleArrayDelete = (e, row, index) => {
        const updatedPrintTableData = [...printTableData];
        updatedPrintTableData.splice(index, 1);
        setPrintTableData(updatedPrintTableData);
        setArray(updatedPrintTableData)
    };


    const columns = tableData[0]?.length > 0 ? tableData[0]?.map((key) => ({
        title: (<span className='text-capitalize'>{key.measurement_data.label}</span>),
        dataIndex: key.measurement_data.label,
        width: "350px",
        key: key.measurementId,
        render: (text, record, index) => {
            return (
                <div className='d-flex flex-wrap'>
                    {text?.map((item, index) => (
                        <div key={index} className="me-2 mb-2 text-design text-capitalize">{item}</div>
                    ))}
                </div>
            )
        }
    })) : [];

    columns.push(
        {
            title: 'Measurement',
            key: 'measurement',
            width: "120px",
            render: (measurement, record, index) => {
                // console.log(record);
                return (

                    <div className='text-capitalize'>{record.label}</div>
                )
            }
        },
        {
            title: 'Rate',
            key: 'rate',
            width: "100px",
            render: (rate, record, index) => {
                return (
                    <div className='d-flex justify-content-between'>
                        <div>{record.price}/-</div>
                        <ActionMenu data={record}
                            handleUpdate={() => edit(rate, index)}
                            confirmDelete={() => handleArrayDelete(rate, record, index)}
                        />
                    </div>
                )
            },
        }
    );

    const formFinishMain = (value) => {
        if (defultValue === false) {

            let bodyFormData = new FormData()

            if (uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    bodyFormData.append('coverImage', file);
                });
            }

            let payloadData = new FormData();
            if (uploadFilesMultiple?.length > 0) {
                uploadFilesMultiple.forEach((file) => {
                    payloadData.append('multipleImages', file);
                });
            }
            payloadData.append('coverImage', bodyFormData.get('coverImage'));
            payloadData.append('categoryId', (value.categoryId.toLowerCase()));
            payloadData.append('subCategoryId', value.subCategoryId || "");
            payloadData.append('productName', value.productName);
            payloadData.append('barCode', value.barCode || "");
            payloadData.append('description', data || "");
            payloadData.append('shortDescription', value.shortDescription || "");
            payloadData.append('varients', JSON.stringify(array));

            dispatch(productCreate(payloadData))
        } else {
            let payloadEditData = new FormData();

            if (Array.isArray(uploadFiles) && uploadFiles?.length > 0) {
                Array.from(uploadFiles).forEach((file) => {
                    payloadEditData.append('coverImage', file);
                });
            }
            else {
                payloadEditData.append('coverImage', uploadFiles);
            }

            if (Array.isArray(uploadFilesMultiple) && uploadFilesMultiple?.length > 0) {

                Array.from(uploadFilesMultiple).forEach((file) => {
                    if (file.name) {
                        payloadEditData.append('multipleImages', file);
                    } else {
                        payloadEditData.append('alreadyImages', file);

                    }


                });
            } else {
                payloadEditData.append('multipleImages', uploadFilesMultiple);
            }

            payloadEditData.append('_id', id);
            payloadEditData.append('categoryId', value.categoryId);
            payloadEditData.append('subCategoryId', value.subCategoryId || "");
            payloadEditData.append('productName', value.productName);
            payloadEditData.append('barCode', value.barCode || "");
            payloadEditData.append('description', data || "");
            payloadEditData.append('shortDescription', value.shortDescription || "");
            payloadEditData.append('varients', JSON.stringify(array));

            dispatch(productUpdate(payloadEditData, setButonLoading))

        }

    }

    //main
    const handleUpdate = (row) => {
        if (document.querySelector(".common-component-set")) {
            document.querySelector(".common-component-set").scrollTo({ top: 0, behavior: 'smooth' });
        }

        let multiple = row.multipleImages.map((filename, index) => {
            return {
                uid: index,
                name: filename,
                url: `${process.env.REACT_APP_SOCKET}/uploads/multipleImages/${filename}`
            }
        });

        setFileList(multiple)

        setCollapseActiveKey(['1']);
        setId(row._id)
        let newArray = [];
        let newObj = {}

        newArray = row.varients.map((dataSet) => {
            dataSet.data.forEach((set) => {
                let newKey = Object.keys(set)[0]
                newObj[newKey] = set[newKey]
            })
            return {
                data: dataSet.data,
                measurementId: dataSet.measurementId,
                price: dataSet.price,
                quantity: dataSet.quantity,
                label: dataSet.measurement_data.label,
                ...newObj,
            }
        })
        setPrintTableData(newArray)

        let narray = []
        narray = row.varients.map((dataSet) => {
            return {
                data: dataSet.data,
                measurementId: dataSet.measurementId,
                price: dataSet.price,
                quantity: dataSet.quantity
            }
        })

        seteditData(row.subCategoryId)
        setDefultValue(true)
        let payload = {
            page: 1,
            limit: 10000,
            search: "",
            status: 0,
            categoryId: row.categoryId
        }
        dispatch(subCategoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))

        let payloads = {
            _id: row.subCategoryId
        }
        dispatch(subCategoryGetById(payloads, setLoading, setSearchLoading, setIsFetching))
        setImage(row.coverImage);
        setData(row.description)
        form.setFieldValue("barCode", row.barCode)
        form.setFieldValue("categoryId", row.categoryId)
        form.setFieldValue("shortDescription", row.shortDescription)
        form.setFieldValue("productName", row.productName)
        setUploadFiles(row.coverImage)
        setUploadFilesMultiple(row.multipleImages)
        setArray(narray)
    }

    const handleDelete = (id) => {
        dispatch(productDelete(id))
    }

    const handleToggleChange = (_id, newStatus) => {
        let statusmain = null
        if (newStatus === 0) {
            statusmain = 1;
        } else {
            statusmain = 0;
        }
        let payload = {
            _id: _id, status: String(statusmain)
        }
        dispatch(productStatus(payload))

    }

    const handleChangesStatusAllData = (e) => {
        setCurrentPage(1)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchData = (e) => {
        clearTimeout(typingTimeout);
        setSearchLoading(true);
        setSearchDataAll(e);
        const timeout = setTimeout(async () => {
            setCurrentPage(1);
        }, 800);
        setTypingTimeout(timeout);
    };

    const columnsAll = [
        {
            title: (<span>Cover Image</span>),
            dataIndex: 'coverImage',
            width: "150px",
            key: 'coverImage',
            render: (coverImage) => (
                <span className='text-capitalize'>
                    {coverImage === "" ? <Image width={35} height={35} src={require("../../App_Details_theme/images/Frame.png")} /> :
                        <Image width={35} height={35} src={`${REACT_APP_SOCKET}/uploads/coverImages/${coverImage}`} />
                    }
                </span>
            ),
        },
        {
            title: (<span>Product Name</span>),
            dataIndex: 'productName',
            width: "150px",
            key: 'productName',
            render: (productName) => (
                <span className='text-capitalize'>
                    {productName || '-'}
                </span>
            ),
        },
        {
            title: (<span>Bar Code</span>),
            dataIndex: 'barCode',
            width: "150px",
            key: 'barCode',
            render: (barCode, record) => (
                <span className='text-capitalize'>
                    {barCode || '-'}
                </span>
            ),
        },
        {
            title: (<span>Category</span>),
            dataIndex: 'category',
            width: "150px",
            key: 'category',
            render: (category, record) => (
                <span className='text-capitalize'>
                    {record?.category_data[0].name || '-'}
                </span>
            ),
        },
        {
            title: (<span>Sub Category</span>),
            dataIndex: 'SubCategory',
            width: "150px",
            key: 'SubCategory',
            render: (SubCategory, record) => (
                <span className='text-capitalize'>
                    {record?.subcategory_data[0].name || '-'}
                </span>
            ),
        },
        {
            title: (<span>Status</span>),
            width: "80px",
            dataIndex: 'status',
            render: (status, record) => {
                return (
                    <div className='d-flex justify-content-between'>
                        <Switch
                            size='small'
                            checked={status === 0 ? true : false}
                            onChange={(checked) => handleToggleChange(record._id, checked ? 1 : 0)}
                        />
                        <ActionMenu data={record}
                            handleUpdate={handleUpdate}
                            confirmDelete={handleDelete}
                        />
                    </div>
                )

            },
        },
    ]

    const handleCategoryFilter = (e) => {
        let payload = {
            page: 1,
            limit: 10000,
            search: "",
            status: 0,
            categoryId: e
        }
        dispatch(subCategoryGetAll(payload, setLoading, setSearchLoading, setIsFetching))
    }

    const text =
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={formFinishMain}
            // onFinishFailed={onFinishFailed}
            // onValuesChange={onValuesChange}
            autoComplete="off"
            form={form}
            ref={formRef}
        >
            <div className="right-header">
                <div className="row gx-0">
                    <div className="col-10 d-flex align-items-start">
                        <h2 className='commonPageTitle'>Basic Details</h2>
                    </div>

                </div>
            </div>

            <div className="body">
                <div className="row g-2">
                    <div className='col-lg-12'>
                        <div className="row g-0">
                            <div className="col-lg-8 col-12">

                                <div className='row g-3'>
                                    <div className="form-groups col-12 col-sm-6 col-md-3 col-lg-3 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Category<span className="required">*</span></label>
                                        <Form.Item
                                            htmlFor="sname"
                                            name="categoryId"
                                            className='height-set-Description'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter a Category'
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Category"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                onChange={(e) => {
                                                    form.setFieldsValue({ subCategoryId: undefined });
                                                    handleCategoryChange(e);
                                                }}
                                            >
                                                {
                                                    categoryData?.map((row, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                <Select.Option className="text-capitalize" value={row?._id}>{row?.name}</Select.Option>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-3 col-lg-3 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Sub Category<span className="required">*</span></label>
                                        <Form.Item
                                            htmlFor="sname"
                                            name="subCategoryId"
                                            className='height-set-Description'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter a Sub-Category'
                                                },
                                            ]}>
                                            <Select
                                                placeholder="Sub Category"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                onChange={handleSubCategoryChange}
                                            >
                                                {
                                                    subCategoryData?.map((row, i) => {
                                                        return (
                                                            <Fragment key={i}>
                                                                <Select.Option className="text-capitalize" value={row?._id}>{row?.name}</Select.Option>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-3 col-lg-3 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Product Name<span className="required">*</span></label>
                                        <Form.Item name="productName" key="AppName" className='height-set-Description' htmlFor="sname" rules={[{ required: true, message: 'Enter a Product Name' }]}>
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Product Name"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-3 col-lg-3 user-search ant-input-remove-marg" >
                                        <label htmlFor="pname" className='commonLabel'>Barcode<span className="required">*</span></label>
                                        <Form.Item name="barCode" key="AppName" className='height-set-Description' htmlFor="sname">
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Barcode"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='col-lg-8 col-12'>
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={handleFinish}
                            autoComplete="off"
                            form={form2}
                            ref={formRef2}
                        >
                            <div className="row g-3">
                                {dataId.length !== 0 ?
                                    <div className="right-header">
                                        <div className="row gx-0">
                                            <div className="col-10 d-flex align-items-start">
                                                <h2 className='commonPageTitle'>Measurement Details</h2>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {dataId.map((x) => (
                                    x.measurementArray.map((a) => (
                                        a.measurementType === 1 ? (
                                            <>
                                                <div className="form-groups col-12 col-sm-6 col-md-6 col-lg-3 user-search ant-input-remove-marg select-custom" key={a.measurementId}>
                                                    <label htmlFor="pname" className='commonLabel text-capitalize'>{a.measurement_data.label}<span className="required">*</span></label>
                                                    <Form.Item
                                                        name={a.measurement_data.label + "uniqueKey"} // Ensure name attribute is correctly set
                                                        className='height-set-Description'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Enter a Category'
                                                            },
                                                        ]}>
                                                        <Select mode="tags" className='text-capitalize' placeholder={a.measurement_data.label} />
                                                    </Form.Item>
                                                </div>
                                            </>
                                        ) : null
                                    ))
                                ))}

                                {dataId.length !== 0 && (
                                    <>
                                        <div className="form-groups col-12 col-sm-6 col-md-6 col-lg-4 user-search ant-input-remove-marg select-custom" >
                                            <label htmlFor="pname" className='commonLabel'>Measurement<span className="required">*</span></label>
                                            <Form.Item
                                                name="measurementId" // Ensure name attribute is correctly set
                                                className='height-set-Description'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Enter a Measurement'
                                                    },
                                                ]}>
                                                <Select mode="multiple" placeholder="Measurement" onChange={(e) => handleDataChange(e, newData[0].measurementArray)}>
                                                    {newData[0]?.measurementArray?.map((a) => (
                                                        <Select.Option className="text-capitalize" key={a.measurementId} value={a.measurementId}>
                                                            {a.measurement_data.label}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className="form-groups col-9 col-sm-4 col-md-4 col-lg-3 user-search ant-input-remove-marg" >
                                            {/* {dynamicData.length !== 0 ?
                                                <label htmlFor="pname" className='commonLabel d-flex justify-content-center rate'>Rate<span className="required">*</span></label> : null
                                            } */}
                                            {dynamicData?.map((e) => (
                                                <div style={{ width:"max-content"}}>
                                                    <label htmlFor="pname" className='commonLabel d-flex justify-content-center rate'>Rate<span className="required">*</span></label>
                                                    <div className='d-flex dynamic-data' key={e.measurementId}>
                                                        <label htmlFor="pname" className='commonLabel text-capitalize label'>{e.measurement_data.label}:</label>
                                                        <div>
                                                            <Form.Item name={e.measurementId + "rate"} htmlFor="sname" rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Enter a ${e.measurement_data.label} Rate`
                                                                },
                                                            ]}>
                                                                <Input
                                                                    type="text"
                                                                    id="sname"
                                                                    placeholder="Rate"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="form-groups col-3 col-sm-2 col-md-2 col-lg-2 d-flex align-items-end justify-content-end button-color custom-height">
                                            <Button type='primary' onClick={handleFinish}>{editValue === true ? "Edit" : "Add"}</Button>
                                        </div>
                                    </>
                                )}
                                {
                                    printTableData.length !== 0 ?
                                        <div className="form-groups col-12 user-search ant-input-remove-marg" >
                                            <Table
                                                columns={columns}
                                                size="small"
                                                dataSource={printTableData}
                                                pagination={false}
                                                scroll={{
                                                    x: null,
                                                    y: 250
                                                }}
                                            />
                                        </div> : null
                                }
                                <div className="form-groups order-3 order-sm-2 order-lg-3 col-12 col-xxl-12 appname d-flex custom-height cate-image ant-input-remove-marg">
                                    <div>
                                        <label htmlFor="sname" className='commonLabel'>Cover Image<span className="required">*</span></label>
                                        <Form.Item name="coverImage" key="status">
                                            <Input
                                                type="file"
                                                className="form-control"
                                                placeholder="Click to Upload"
                                                id="csvFileInput"
                                                onChange={handleOnChange}
                                                ref={fileInputRef}
                                                accept="image/png, image/jpeg,image/jpg"
                                            />
                                        </Form.Item>
                                    </div>
                                    {
                                        imageUrl ? (
                                            <div className='align-self-end ms-2'>
                                                <img src={imageUrl} alt="Uploaded Preview" className='app-detail-icon' />
                                            </div>

                                        ) : (
                                            <>
                                                {image ? (
                                                    <div className='align-self-end ms-2'>
                                                        <img src={`${REACT_APP_SOCKET}/uploads/coverImages/${uploadFiles}`} alt="Google" className='app-detail-icon' />
                                                    </div>
                                                ) : (
                                                    <div className='align-self-end ms-2'>
                                                        <img src={require('../../App_Details_theme/images/Frame.png')} className='app-detail-icon' alt="" />
                                                    </div>
                                                )

                                                }
                                            </>
                                        )
                                    }
                                </div>

                                <div className="form-groups order-4 order-sm-4 col-12 col-xxl-6 user-search ant-input-remove-marg" >
                                    <label htmlFor="pname" className='commonLabel'>Multiple Image</label>
                                    <Form.Item name="multipleImage" className='height-set-Description' key="status">
                                        <Upload
                                            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                            listType="picture-card"
                                            fileList={fileList}
                                            // onPreview={handlePreview}
                                            onChange={handleChange}
                                            multiple
                                            column={2}
                                            grid={{ gutter: 2, column: 2 }}
                                        // onError={onError}
                                        >

                                            {fileList.length >= 8 ? null : uploadButton}
                                        </Upload>
                                    </Form.Item>
                                </div>


                            </div>
                        </Form>
                    </div>

                    <div className='col-lg-4 col-12'>
                        <div className='row g-3'>

                            <div className="form-groups order-1 col-12 col-sm-6 col-lg-12 user-search ant-input-remove-marg" >
                                <label htmlFor="pname" className='commonLabel'>Short Description</label>
                                <Form.Item name="shortDescription" key="AppName" className='height-set-Description' htmlFor="sname">
                                    <Input
                                        type="text" id="sname"
                                        placeholder="Short Description"
                                    />
                                </Form.Item>
                            </div>

                            <div className="form-groups order-2 order-sm-3 order-lg-2 col-12 col-sm-6 col-lg-12 user-search ant-input-remove-marg" >
                                <label htmlFor="pname" className='commonLabel'>Description</label>
                                <div className='height-set-Description'>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={data}
                                        onChange={handleChangeEditor}
                                        config={{

                                            fontSize: {
                                                options: [10, 12, 14, 'default', 18, 20, 22],
                                                supportAllValues: true
                                            },
                                            fontFamily: {
                                                options: [
                                                    'default',
                                                    'Ubuntu, Arial, sans-serif',
                                                    'Ubuntu Mono, Courier New, Courier, monospace'
                                                ]
                                            },
                                            toolbar: [
                                                'selectAll', 'bold', 'italic', 'heading', 'undo', 'redo', 'link', 'insertImage',
                                                '|', 'mediaEmbed', 'insertTable', 'blockQuote', 'bulletedList', 'numberedList', 'outdent', 'indent', 'fontSize',
                                            ],
                                            // Image upload configuration
                                            ckfinder: {
                                                tokenUrl: 'https://104362.cke-cs.com/token/dev/GGfzWVJzJOHBZ9mxe3uoAwoglklncY4rdhc7?limit=10',
                                            },
                                            cloudServices: {
                                                // //     // Be careful - do not use the development token endpoint on production systems!
                                                // //     tokenUrl: 'https://104362.cke-cs.com/token/dev/GGfzWVJzJOHBZ9mxe3uoAwoglklncY4rdhc7?limit=10',
                                                uploadUrl: 'http://localhost:3001/upload',

                                                // //     // webSocketUrl: 'wss://104362.cke-cs.com/ws',
                                                //     uploadUrl: 'https://104362.cke-cs.com/easyimage/upload/'
                                            },
                                            table: {
                                                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
                                            },
                                            styles: [
                                                'alignLeft', 'alignCenter', 'alignRight', 'alignJustify',
                                                'bold', 'italic', 'underline', 'strikethrough',
                                                'border', 'clear', 'table', 'tableCell'
                                            ],
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-groups ms-auto order-last col-12 col-sm-6 col-md-4 col-lg-12 d-flex justify-content-end button-color">
                                <Button loading={butonLoading} type='primary' htmlType="submit">{defultValue === true ? "Update" : "Submit"}</Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >

        </Form >;


    const { token } = theme.useToken();
    const items = [
        {
            key: '1',
            label: (<span>{defultValue === true ? "Edit Product" : "Add Product"}</span>),
            children: <p>{text}</p>,
            style: {
                borderRadius: token.borderRadiusLG,
                border: 'none',
            }
        }
    ]

    const handlePanelChange = (key) => {
        if (collapseActiveKey.includes("1")) {
            setCollapseActiveKey([]); // Close the collapse if it's already open
        } else {
            setCollapseActiveKey(['1']); // Open the collapse
        }
    };

    const skeletonItems = Array.from({ length: 10 }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };

    useEffect(() => {
        Add()
    }, [productData])

    return (
        <Fragment>

            <MainContentComponent>
                <div className='main-content-body overflow-y-scroll dynamic-scroll-all' id='Add-Products'>
                    <div className="project">
                        <Collapse activeKey={collapseActiveKey} accordion bordered={false} onChange={handlePanelChange}>
                            {items.map(item => (
                                <Panel header={item.label} key={item.key}>
                                    {item.children}
                                </Panel>
                            ))}
                        </Collapse>
                    </div>

                    <div className="project-records project-record-page overflow-hidden position-relative">
                        <div className="header">
                            <div className="row g-0">
                                <div className="col-12 col-lg-3 left">
                                    <h2 className='commonTableTitle'>All Products</h2>
                                </div>
                                <div className="col-12 col-lg-9 ">

                                    <div className="row g-2 justify-content-end">
                                        <div className="col-12 col-sm-6 col-md-3 col-xl-2 form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Category</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Category"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={categoryFilter}
                                                    onChange={(value) => {
                                                        setCategoryFilter(value)
                                                        handleCategoryFilter(value)
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    {
                                                        categoryData?.map((row, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <Select.Option value={row?._id} className="text-capitalize">{row?.name}</Select.Option>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-3 col-xl-2 form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Sub Category</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Sub Category"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={subCategoryFilter}
                                                    onChange={(value) => {
                                                        setSubCategoryFilter(value)
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    {
                                                        subCategoryData?.map((row, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <Select.Option value={row?._id} className="text-capitalize">{row?.name}</Select.Option>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-3 col-xl-2 form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Status</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Selected Value"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={selectedStatusData}
                                                    onChange={(value) => {
                                                        setSelectedStatusData(value === undefined ? '0' : value);
                                                        handleChangesStatusAllData(value);
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    <Select.Option value="0">Active</Select.Option>
                                                    <Select.Option value="1">Inactive</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-3 col-xl-2 form-groups ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status">Search</label>
                                            <Search
                                                allowClear
                                                onSearch={(value, event, { source }) => {
                                                    if (source === 'input') {
                                                        handleSearchData(value);
                                                    }
                                                }}
                                                onChange={(e) => handleSearchData(e.target.value)}
                                                placeholder="Search"
                                                value={searchDataAll}
                                                className='search-input-loader mySearch height-set-Description'
                                            // loading={searchLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="body table-body lookUpTableDiv my-pagination tableSetCss my-table-sckeleton">
                            <Table
                                columns={columnsAll}
                                rowKey={(record) => record._id}
                                dataSource={productData}
                                size="small"
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    onChange: (page) => setCurrentPage(page),
                                    onShowSizeChange: (current, size) => setPageSize(size),
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }}
                                components={customComponents}
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                        <div className='refresh-button-users'>
                            <Refresh
                                isFetching={isFetching} handleRefreshClick={handleRefreshClick}
                            />
                        </div>
                    </div>
                </div>
            </MainContentComponent >

        </Fragment >
    )
}

export default AddProduct