import $ from 'jquery'
export const mainDivScroll=()=>{
    if ($(window).width() > 767) {
        var bodyHeight = $("main").height();
        var windowtop = $(".pages .top-header").outerHeight();
        var breadcumbheight = $(".breadcumb").outerHeight();
        var paddingMainComponent = $('.common-component-set').css('padding');
        var mainDivHeight = bodyHeight - windowtop - breadcumbheight - (parseFloat(paddingMainComponent) *2);
        $(".pages .common-component-set").css("height", mainDivHeight).css('overflow', 'auto');
      }
      $(window).resize(function () {
        if ($(window).width() > 767) {
            var bodyHeight = $("main").height();
            var windowtop = $(".pages .top-header").outerHeight();
            var breadcumbheight = $(".breadcumb").outerHeight();
            var paddingMainComponent = $('.common-component-set').css('padding');
            var mainDivHeight = bodyHeight - windowtop - breadcumbheight - (parseFloat(paddingMainComponent) *2);
            $(".pages .common-component-set").css("height", mainDivHeight).css('overflow', 'auto');
        }
      })
      if ($(window).width() <= 767) {
        if ($(window).width() <= 767) {
            var bodyHeight = $("main").height();
            var windowtop = $(".pages .top-header").outerHeight();
            var breadcumbheight = $(".breadcumb").outerHeight();
            var paddingMainComponent = $('.common-component-set').css('padding');
            var mainDivHeight = bodyHeight - windowtop - breadcumbheight - (parseFloat(paddingMainComponent) *2);
            $(".pages .common-component-set").css("height", mainDivHeight).css('overflow', 'auto');
        }
      }
      /* for mobile width */
      $(window).resize(function () {
        if ($(window).width() <= 767) {
            var bodyHeight = $("main").height();
            var windowtop = $(".pages .top-header").outerHeight();
            var breadcumbheight = $(".breadcumb").outerHeight();
            var paddingMainComponent = $('.common-component-set').css('padding');
            var mainDivHeight = bodyHeight - windowtop - breadcumbheight - (parseFloat(paddingMainComponent) *2);
            $(".pages .common-component-set").css("height", mainDivHeight).css('overflow', 'auto');
        }
      })
    
}