export const LOGIN = 'LOGIN'
export const LOGINOTP = 'LOGINOTP'
export const REGISTER = 'REGISTER'
export const REGISTEROTP = 'REGISTEROTP'
export const DUMMY = 'DUMMY'  /* to blank and set dummy data */

// userStatus 
export const USERSTATUSAPPROVED = 'USERSTATUSAPPROVED'
export const USERSTATUSREJECTED = 'USERSTATUSREJECTED'
export const USERSTATUSPENDING = 'USERSTATUSPENDING'
export const USERSTATUSALL = 'USERSTATUSALL'
export const USERSTATUSCHANGEAPPROVED = 'USERSTATUSCHANGEAPPROVED'
export const USERSTATUSCHANGEREJECTED = 'USERSTATUSCHANGEREJECTED'
export const USERSTATUSCHANGEPENDING = 'USERSTATUSCHANGEPENDING'
export const USERSTATUSCHANGEALL = 'USERSTATUSCHANGEALL'
export const USERTYPECHANGE = 'USERTYPECHANGE'

//role
export const GETROLE = 'GETROLE'
export const AUTHDATA = 'AUTHDATA'

// Category 
export const CATEGORY = 'CATEGORY'
export const POSTCATEGORY = 'POSTCATEGORY'
export const UPDATECATEGORY = 'UPDATECATEGORY'
export const DELETECATEGORY = 'DELETECATEGORY'
export const CATEGORYSTATUS = 'CATEGORYSTATUS'

// Sub-Category 
export const SUBCATEGORY = 'SUBCATEGORY'
export const POSTSUBCATEGORY = 'POSTSUBCATEGORY'
export const UPDATESUBCATEGORY = 'UPDATESUBCATEGORY'
export const DELETESUBCATEGORY = 'DELETESUBCATEGORY'
export const SUBCATEGORYSTATUS = 'SUBCATEGORYSTATUS'
export const SUBCATEGORYBYID = 'SUBCATEGORYBYID'

//manage-profile
export const MANAGEPROFILE = 'MANAGEPROFILE'
export const UPDATEPROFILE = 'UPDATEPROFILE'
export const UPDATEPROFILEIMAGE = 'UPDATEPROFILEIMAGE'

// measurement
export const MEASUREMENT = 'MEASUREMENT'
export const POSTMEASUREMENT = 'POSTMEASUREMENT'
export const UPDATEMEASUREMENT = 'UPDATEMEASUREMENT'
export const DELETEMEASUREMENT = 'DELETEMEASUREMENT'
export const MEASUREMENTSTATUS = 'MEASUREMENTSTATUS'

//product
export const PRODUCT = 'PRODUCT'
export const POSTPRODUCT = 'POSTPRODUCT'
export const UPDATEPRODUCT = 'UPDATEPRODUCT'
export const DELETEPRODUCT = 'DELETEPRODUCT'
export const PRODUCTSTATUS = 'PRODUCTSTATUS'

//User-Header-Category
export const GETALLUSERCATEGORY = 'GETALLUSERCATEGORY'

//Login-History
export const LOGINHISTORY = 'LOGINHISTORY'

//product
export const PENDINGORDERS = 'PENDINGORDERS'
export const ACCEPTEDORDERS = 'ACCEPTEDORDERS'
export const REJECTEDORDERS = 'REJECTEDORDERS'
export const CANCELORDERS = 'CANCELORDERS'
export const DISPATCHEDORDERS = 'DISPATCHEDORDERS'
export const ORDERACCEPT = 'ORDERACCEPT'
export const ORDERREJECT = 'ORDERREJECT'
export const ORDERDISPATCH = 'ORDERDISPATCH'


//user-order
export const USERORDER = 'USERORDER'
export const USERCANCELORDER = 'USERCANCELORDER'
export const USERCTRACKORDER = 'USERCTRACKORDER'


//Cart COunt
export const CARTCOUNT = 'CARTCOUNT'
export const SEARCHSELECT = 'SEARCHSELECT'

export const FORGETPASS = 'FORGETPASS'
export const FORGETPASSOTPVERIFY = 'FORGETPASSOTPVERIFY'
