import { message } from 'antd';
import React from 'react';

const CustomMessage = (type, content) => {
  const getMessageClass = (messageType) => {
    return messageType === 'success' ? 'success-message' : 'error-message';
  };

  message[type]({
    content: (
      <span className={`${getMessageClass(type)} text-capitalize`}>
        {content}
      </span>
    ),
  });
};

export default CustomMessage;
