import { GETROLE } from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const roleReducer = (state = defaultState, action) => {
    switch (action.type) {
      case GETROLE: {
        return {
          data: {
            ...action.payload
          },
          type: GETROLE
        };
      }
     
      default:
        return defaultState;
    }
  };
  