import { PRODUCT,POSTPRODUCT,UPDATEPRODUCT,DELETEPRODUCT, PRODUCTSTATUS } from "../ActionTypes/actionTypes";
  let defaultState = {};
  
  export const productReducer = (state = defaultState, action) => {
    switch (action.type) {
      case PRODUCT: {
        return {
          data: {
            ...action.payload
          },
          type: PRODUCT
        };
      }
      case POSTPRODUCT: {
        return {
          data: {
            ...action.payload
          },
          type: POSTPRODUCT
        };
      }
      case UPDATEPRODUCT: {
        return {
          data: {
            ...action.payload
          },
          type: UPDATEPRODUCT
        };
      }
      case DELETEPRODUCT: {
        return {
          data: {
            ...action.payload
          },
          type: DELETEPRODUCT
        };
      }
      case PRODUCTSTATUS: {
        return {
          data: {
            ...action.payload
          },
          type: PRODUCTSTATUS
        };
      }
     
      default:
        return defaultState;
    }
  };
  