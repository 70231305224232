import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./Components/App_Details_theme/css/common.css";
import "./Components/App_Details_theme/css/login.css";
import "./Components/App_Details_theme/css/common.css";
import "./Components/App_Details_theme/css/register.css";
import "./Components/App_Details_theme/css/navbar.css";
import CryptoJS from "crypto-js";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from "react-router-dom";
import LoginForm from "./Components/Auth/Login/LoginForm";
import SignUpForm from "./Components/Auth/SignUpForm/SignUpForm";
import { Fragment, createContext, useEffect, useState } from "react";
import Dashboard from "./Components/Dashboard/Dashboard";
import Cookies from "universal-cookie";
import Page404 from "./Components/Page404/Page404";
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import RoutingCreate from "./Components/Routes/RoutingCrud/RoutingCreate";
import axios from "axios";
import { AUTHDATA } from "./Components/Redux/ActionTypes/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import HeaderUser from "./Components/Client/Header/HeaderUser";
import BackendFilter from "./BackendFilter";
export const AuthContext = createContext("AuthContext");
const REACT_APP_COOKIE = process.env.REACT_APP_COOKIE;
function App({ children }) {
  const pathData = useSelector(state => state.pathReducer.data);
  const [isAuthenticate, setIsAuthenticated] = useState(false);
  const cookies = new Cookies();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chartId = queryParams.get("userId");
  const [cookieChange, setCookieChange] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    cookies.addChangeListener(e => {
      console.log(chartId, e, "chartIdchartIdchartId")
      if (!chartId) {
        if (e.name === process.env.REACT_APP_COOKIE) {
          if (e.value) {
            console.log(e.value, "e.value1")
            navigate("/");
          } else {
            console.log(e.value, "e.value2")
            setIsAuthenticated(false);
            dispatch({
              type: AUTHDATA,
              payload: {}
            });
            navigate("/login");
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (chartId) {
      let dataSet = cookies.get(process.env.REACT_APP_COOKIE_USER_DATA);
      console.log(dataSet, "dataSet1")
      if (dataSet) {
        setIsAuthenticated(true);
        console.log(dataSet, "dataSet2")
        navigate("/");
        dispatch({
          type: AUTHDATA,
          payload: dataSet
        });
      }
    }
  }, [chartId]);

  return (
    <div className="App">
      {/* <BackendFilter/> */}
      <AuthContext.Provider
        value={{ setIsAuthenticated, cookieChange, setCookieChange }}
      >
        {isAuthenticate && pathData.type !== undefined
          ? pathData.type === 0
            ? <Fragment>
              <section id="main-section" className="pages">
                <main className="h-100 main-div">
                  <div className="bg-overlay" />
                  <div className="content-div row">
                    <Header />
                    <div
                      className="left-col header-custom-col default-fixed-content"
                      id="right-section"
                    >
                      <Navbar />
                      {children}
                    </div>
                  </div>
                </main>
              </section>
            </Fragment>
            : <Fragment>
              <section id="main-section-user" className="pages">
                <HeaderUser />
                {/* <div className="header-padding"></div> */}
                <div className="container-lg">
                  {children}
                </div>
              </section>
            </Fragment>
          : <Fragment>
            {children}
          </Fragment>}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
