import { CARTCOUNT } from "../ActionTypes/actionTypes";
let defaultState = {
    data: 0,
    type: CARTCOUNT
};

export const cartCount = (state = defaultState, action) => {
    switch (action.type) {
        case CARTCOUNT: {
            return {
                data: action.payload,
                type: CARTCOUNT
            };
        }
        default:
            return state;
    }
}
