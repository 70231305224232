import React, { Fragment } from 'react'
import './Role.css'
import MainContentComponent from '../App_Details_theme/components/MainContentComponent'

const Role = () => {
    return (
        <Fragment>
            <MainContentComponent>
                <div>Role</div>
            </MainContentComponent>
        </Fragment>
    )
}

export default Role