import React, { useRef, useState } from 'react'
import { Form, Image, Input, Select, Skeleton, Switch, Table, Tooltip } from 'antd';
import * as AntIcons from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import './measurement.css'
import moment from 'moment';
import { debounce } from 'lodash';
import Search from 'antd/es/input/Search';
import ActionMenu from '../App_Details_theme/Common_Components/ActionMenu';
import Refresh from '../App_Details_theme/Common_Components/Refresh';
import Submitbutton from '../App_Details_theme/Common_Components/Submitbutton';
import { getMeasurement, measurementCreate, measurementDelete, measurementStatus, measurementUpdate } from '../Redux/Action/measurementAction';
import { DELETEMEASUREMENT, MEASUREMENT, MEASUREMENTSTATUS, POSTMEASUREMENT, UPDATEMEASUREMENT } from '../Redux/ActionTypes/actionTypes';
import MainContentComponent from '../App_Details_theme/components/MainContentComponent';
import Cate from '../Categories/Cate';
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const Measurement = () => {

    const formRef = useRef();
    const [form] = Form.useForm()
    let dispatch = useDispatch()
    const fileInputRef = useRef();

    const [defultValue, setDefultValue] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [measurementData, setMeasurementData] = useState([])
    const [totalItems, setTotalItems] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState();
    const [pageSize, setPageSize] = useState(20);
    const [searchDataAll, setSearchDataAll] = useState('');
    const [selectedStatusData, setSelectedStatusData] = useState('0');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    let measurement = useSelector((state) => state.measurementReducer)

    const handleRefreshClick = () => {
        setIsFetching(true)
        setLoading(true)
        MeasurementGetAll()
    }
    const MeasurementGetAll = () => {
        let payload = {
            page: currentPage,
            limit: pageSize,
            search: searchDataAll,
            status: selectedStatusData
        }
        dispatch(getMeasurement(payload, setLoading, setSearchLoading, setIsFetching))
    }

    useEffect(() => {
        MeasurementGetAll()
    }, [currentPage, pageSize, selectedStatusData, searchDataAll])

    useEffect(() => {
        if (measurement.type === MEASUREMENT) {
            if (measurement.data.success && measurement.data.data?.length !== 0) {
                setMeasurementData(measurement.data.data.items)
                setTotalItems(measurement.data.data.totalItems)
            }
            else {
                setMeasurementData([])
            }
        }
        if (measurement.type === POSTMEASUREMENT) {
            if (measurement.data.success) {
                MeasurementGetAll()
                form.resetFields()
            }
        }

        if (measurement.type === UPDATEMEASUREMENT) {
            if (measurement.data.success) {
                MeasurementGetAll()
                form.resetFields()
                setDefultValue(false)
            }
        }
        if (measurement.type === DELETEMEASUREMENT) {
            if (measurement.data.success) {
                MeasurementGetAll()
                form.resetFields()
                setDefultValue(false)
            }
        }
        if (measurement.type === MEASUREMENTSTATUS) {
            if (measurement.data.success) {
                MeasurementGetAll()
                form.resetFields()
            }
        }
    }, [measurement])

    const handleFinish = (value) => {
        if (defultValue === false) {
            let payload = {
                label: value.label,
                key: value.key
            }
            dispatch(measurementCreate(payload))
        } else {
            let payload = {
                _id: id,
                label: value.label,
                key: value.key
            }
            dispatch(measurementUpdate(payload))

        }
    }

    const handleUpdate = (row) => {
        if (document.querySelector(".common-component-set")) {
            document.querySelector(".common-component-set").scrollTo({ top: 0, behavior: 'smooth' });
        }
        
        setIsFormValid(true)
        form.setFieldValue("label", row.label)
        form.setFieldValue("key", row.key)
        setDefultValue(true)
        setId(row._id)
    }

    const handleDelete = (row) => {
        dispatch(measurementDelete(row))
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setIsFormValid(false);
    };

    const onValuesChange = (_, values) => {
        const allRequiredFieldsFilled = ['label', 'key'].every((key) => {
            if ((key === "label" && values[key]) || (key === "key" && values[key])) {
                return true
            }
            else {
                return false
            }
        }
        );
        setIsFormValid(allRequiredFieldsFilled);
    };

    const handleToggleChange = (_id, newStatus) => {
        let statusmain = null
        if (newStatus === 0) {
            statusmain = 1;
        } else {
            statusmain = 0;
        }
        let payload = {
            _id: _id, status: String(statusmain)
        }
        dispatch(measurementStatus(payload))

    }

    const handleChangesStatusAllData = (e) => {
        setCurrentPage(1)
    }

    const [typingTimeout, setTypingTimeout] = useState(0);
    const handleSearchData = (e) => {
        clearTimeout(typingTimeout);
        setSearchLoading(true);
        setSearchDataAll(e);
        const timeout = setTimeout(async () => {
            setCurrentPage(1);
        }, 800);
        setTypingTimeout(timeout);
    };
    const columns = [
        {
            title: (<span>Label</span>),
            dataIndex: 'label',
            width: "150px",
            key: 'label',
            render: (label) => (
                <span className='text-capitalize'>
                    {label || '-'}
                </span>
            ),
        },
        {
            title: (<span>Key</span>),
            dataIndex: 'key',
            width: "100px",
            key: 'key',
            render: (key) => (
                <span>
                    {key || '-'}
                </span>
            ),
        },
        {
            title: (<span>Status</span>),
            width: "80px",
            dataIndex: 'status',
            render: (status, record) => {
                return (
                    <Switch
                        size='small'
                        checked={status === 0 ? true : false}
                        onChange={(checked) => handleToggleChange(record._id, checked ? 1 : 0)}
                    />
                )

            },
        },
        {
            title: (<span>Updated Date</span>),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '250px',
            render: (text, record) => {
                const formattedDate = moment(record.updatedAt).format('DD MMM YYYY h:mm:ss A');
                const formattedDate2 = moment(record.registerdate).format('DD MMM YYYY h:mm:ss A');
                return (
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                        <span>
                            <Tooltip title={
                                <>
                                    <span><AntIcons.ClockCircleOutlined /> {formattedDate2}</span>
                                </>}>
                                {formattedDate}
                            </Tooltip>
                        </span >
                        <ActionMenu data={record} handleUpdate={handleUpdate} confirmDelete={handleDelete} />
                    </div>);
            },
        },
    ];

    const skeletonItems = Array.from({ length: pageSize }, (_, index) => index);
    const SkeletonRow = () => {
        return (
            <>
                {skeletonItems?.map((item) => (
                    <tr key={item}>
                        <td colSpan="8" className="skeleton-loader">
                            <Skeleton loading={loading} round={true}
                                active
                                avatar
                                title={
                                    {
                                        width: "95%",
                                    }
                                }
                                paragraph={{
                                    rows: 1,
                                    width: ["50%"]
                                }} className='loading-avatar' />
                        </td>
                    </tr>
                ))}
            </>
        );
    };

    const customComponents = {
        body: {
            row: loading ? SkeletonRow : undefined, // Use SkeletonRow component when loading is true
        },
    };

    useEffect(() => {
        Cate()
    }, [measurementData])
    return (
        <>
            <Helmet>
                <title>Measurement</title>
            </Helmet>
            <MainContentComponent>
                <div className='main-content-body overflow-y-scroll dynamic-scroll-all' id='Categories'>
                    <div className="project">
                        <Form
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={handleFinish}
                            onFinishFailed={onFinishFailed}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            form={form}
                            ref={formRef}
                        >
                            <div className="right-header">
                                <div className="row gx-0">
                                    <div className="col-10 d-flex align-items-start">
                                        <h2 className='commonPageTitle'>{defultValue === true ? "Update Measurement" : "Create Measurement"}</h2>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-end">
                                        <Refresh isFetching={isFetching} handleRefreshClick={handleRefreshClick} />
                                    </div>
                                </div>
                            </div>

                            <div className="body">
                                <div className="row">

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 appname ant-input-remove-marg">
                                        <label htmlFor="sname" className='commonLabel'>Label<span className="required">*</span></label>
                                        <Form.Item name="label" key="AppName" className='height-set-Description' htmlFor="sname" rules={[{ required: true, message: 'Enter a Lable' }]}>
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Label"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-2 user-search ant-input-remove-marg" >
                                        <label htmlFor="pname" className='commonLabel'>Key<span className="required">*</span></label>
                                        <Form.Item name="key" key="AppName" className='height-set-Description' htmlFor="sname" rules={[{ required: true, message: 'Enter a Key' }]}>
                                            <Input
                                                type="text" id="sname"
                                                placeholder="Key"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-groups col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-end button-color custom-height">
                                        <Submitbutton form={form}
                                            isFormValid={isFormValid} loadingUpdate={loadingUpdate} loadingSave={loadingSave} getAllDataPass={defultValue}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>

                    {/* <!-- Project Records --> */}
                    <div className="project-records project-record-page overflow-hidden">
                        <div className="header">
                            <Form>
                                <div className="row">
                                    <div className="col-lg-8 col-md-4 left">
                                        <h2 className='commonTableTitle'>Measurement</h2>
                                    </div>
                                    <div className="col-lg-4 col-md-8 right d-lg-flex d-md-flex justify-content-end">
                                        <div className="form-groups status ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status" >Status</label>
                                            <Form.Item className='height-set-Description'>
                                                <Select
                                                    id="platform"
                                                    placeholder="Selected Value"
                                                    showSearch
                                                    allowClear
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    optionFilterProp="children"
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                                                    }
                                                    value={selectedStatusData}
                                                    onChange={(value) => {
                                                        setSelectedStatusData(value === undefined ? '0' : value);
                                                        handleChangesStatusAllData(value);
                                                    }}
                                                >
                                                    <Select.Option value="">All</Select.Option>
                                                    <Select.Option value="0">Active</Select.Option>
                                                    <Select.Option value="1">Inactive</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="form-groups ant-input-remove-marg">
                                            <label className="commonLabel" htmlFor="status">Search</label>
                                            <Search
                                                allowClear
                                                onSearch={(value, event, { source }) => {
                                                    if (source === 'input') {
                                                        handleSearchData(value);
                                                    }
                                                }}
                                                onChange={(e) => handleSearchData(e.target.value)}
                                                placeholder="Search"
                                                value={searchDataAll}
                                                className='search-input-loader mySearch height-set-Description'
                                                loading={searchLoading} />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="body table-body lookUpTableDiv my-pagination tableSetCss my-table-sckeleton">

                            <Table
                                columns={columns}
                                rowKey={(record) => record._id}
                                dataSource={measurementData}
                                pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    total: totalItems,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    onChange: (page) => setCurrentPage(page),
                                    onShowSizeChange: (current, size) => setPageSize(size),
                                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                }}
                                components={customComponents}
                                className='body-row'
                                size="small"
                                scroll={{
                                    x: null,
                                    y: 250
                                }}
                            />
                        </div>
                    </div>
                </div>
            </MainContentComponent>
        </>
    )
}

export default Measurement;
